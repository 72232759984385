import {
  AppBar,
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import React, { useContext } from "react";
import { Close as CloseIcon } from "@material-ui/icons";
import DetailsModalProvider from "../../services/DetailsModalProvider";
import { PageContext } from "../../services/PageProvider";
import ProcessDetailsModalAcquire from "./ProcessDetailsModalAcquire";
import ProcessDetailsModalEsp from "./ProcessDetailsModalEsp";
import ProcessDetailsModalAsset from './ProcessDetailsModalAsset';
import PropTypes from "prop-types";
import _ from "lodash";
import { steel, neutral } from '../templates/palette.json';
import { PopOutIcon } from "../atoms/Icons";
import { NONAME } from "dns";
import { infoIcon } from "../../utils/table-utils";
import { RenderIconWithToolTip } from '../../utils/string-icon-utils';
import CustomTooltip from '../organisms/CustomTooltip';
import TooltipContainer from "./TooltipContainer";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: neutral[0],
    boxShadow: theme.shadows[5],
  },
  title: {
    flexGrow: 1,
    fontSize: "16px",
    fontWeight: 400,
    color: theme.palette.type === 'dark' ? neutral[0] : steel[600],
    fontFamily: `"Roboto", "Noto", sans-serif`
  },
  container: props => ({
    display: "flex",
    padding: theme.spacing(2),
    backgroundColor: theme.background,
    height: props.fullScreen ? "calc(100vh - 50px)" : "75vh",
    maxHeight: props.fullScreen ? "none" : 650,
    maxWidth: props.fullScreen ? "100vw" : "800"
  }),
  generalInformationBox: {
    flex: 1,
    overflow: "auto",
    width: "100%",
    background: theme.palette.type === 'dark' ? steel[900] : neutral[0]
  },
  generalInformationRoot: {
    overflowX: "unset",
  },
  generalInformationTableRow: {
    background: theme.palette.type === 'dark' ? steel[900] : neutral[0],
    fontSize: "12px",
    borderBottom: "none",
    fontFamily: `"Roboto", "Noto", sans-serif`,
    color: theme.palette.type === 'dark' ? steel[100] : steel[600]
  },
  generalInformationContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    maxWidth: "36%",
    height: "100%",
    fontSize: "12px",
    fontFamily: `"Roboto", "Noto", sans-serif`,
    color: steel[600]
  },
  generalInformationHeader: {
    background: theme.palette.type === 'dark' ? steel[800] : neutral[100],
    color: theme.palette.type === 'dark' ? steel[100] : steel[600],
    borderBottom: theme.palette.type === 'dark' ? '1px solid #445A6F' : "1px solid #E5E5E5"
  },
  processContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    maxWidth: "70%",
  },
  processHeader: {
    background: theme.palette.type === 'dark' ? steel[800] : neutral[100],
    fontFamily: `"Roboto", "Noto", sans-serif`,
    fontSize: "14px",
    color: theme.palette.type === 'dark' ? steel[100] : steel[600],
    borderBottom: theme.palette.type === 'dark' ? `1px solid ${steel[600]}` : `1px solid ${steel[200]}`
  },
  generalInformationKey: {
    overflowWrap: "anywhere",
    wordBreak: "break-word",
    whiteSpace: "pre-line",
    color: theme.palette.type === 'dark' ? steel[100] : steel[600],
    padding: "6px",
    fontSize: "12px",
    fontFamily: `"Roboto", "Noto", sans-serif`,
    borderBottom: `1px solid ${theme.palette.type === 'dark' ? steel[900] : neutral[0]}`,
    width: "40%"
  },
  generalInformationValue: {
    overflowWrap: "anywhere",
    wordBreak: "break-word",
    whiteSpace: "pre-line",
    color: theme.palette.type === 'dark' ? steel[100] : steel[600],
    padding: "6px",
    fontSize: "12px",
    fontFamily: `"Roboto", "Noto", sans-serif`,
    borderBottom: `1px solid ${theme.palette.type === 'dark' ? steel[900] : neutral[0]}`
  },
  toolbarHeader: {
    backgroundColor: theme.palette.type === 'dark' ? steel[900] : neutral[0]
  },
  detailIcons: {
    height: 10,
    width: 10,
    borderRadius: '50%',
    padding: 14,
    background: theme.palette.type === 'dark' ? steel[600] : neutral[300],
    color: theme.palette.type === 'dark' ? steel[800] : neutral[600],
    marginLeft: 20,
    '&:hover': {
      background: theme.palette.type === 'dark' ? steel[600] : neutral[300]
    }
  },
  expiredIcon: {
    width: 65,
    height: 21,
    color: theme.palette.type === 'dark' ? steel[600] : neutral[300],
    backgroundImage: theme.palette.type === 'dark' ? `url(/assets/svg/Expired_Tag_Dark.svg)` : `url(/assets/svg/Expired_Tag_Light.svg)`,
    backgroundRepeat: 'no-repeat',
    marginLeft: 10,
    display: 'inline-block',
    verticalAlign: "middle",
    cursor: "pointer",
  },
  additionContainer: {
    width: "max-content",
    marginLeft: 5,
    fontSize: "12px",
    fontFamily: `"Roboto", "Noto", sans-serif`,
  },
  additionText: {
    fontSize: "12px",
    color: theme.palette.type === 'dark' ? steel[100] : steel[600],
    marginLeft: "inherit",
  },

}));

const ContainerPaper = withStyles((theme) => ({
  root: {
    margin: `0 ${theme.spacing(1)}px`,
    flex: 1,
    borderRadius: '4px 4px 0 0',
    "&:first-of-type": {
      marginLeft: theme.spacing(0),
    },
    "&:last-of-type": {
      marginRight: theme.spacing(0),
      background: theme.palette.type === 'dark' ? steel[900] : neutral[0]
    },
  },
}))(Paper);

const TableToolbar = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
  },
}))(Toolbar);

export default function DetailsModalContent(props) {
  const classes = useStyles(props);
  const { data, open, handleOpen, eventData, workflowDirectory } = props;
  const { pageState, setAdditionalTimestamps, additionalTimestamps } = useContext(PageContext);


  if (!open) return null;

  function render() {
    return (
      <><TooltipContainer/>
        <AppBar elevation={0} position="relative">
          <Toolbar variant="dense" className={classes.toolbarHeader}>
            <span className={classes.title} >
              {data.title}
              {data.isExpired && RenderIconWithToolTip({ isIconRequired: data.isExpired, iconClass: classes.expiredIcon })}
            </span>
            {renderIcons()}
          </Toolbar>
        </AppBar>
        <div className={classes.container}>
          {renderGeneralInformation()}
          <ContainerPaper className={classes.processContainer}>
            <TableToolbar className={classes.processHeader} variant="dense">Process</TableToolbar>
            <DetailsModalProvider>{renderProcess()}</DetailsModalProvider>
          </ContainerPaper>
        </div>
      </>
    );
  }
  function renderIcons() {
    if (props.fullScreen) {
      return null;
    }

    return (<>
      <IconButton
        onClick={handleOpenNewTab}
        aria-label="Open Detail View in a new tab"
        edge="end"
        className={classes.detailIcons}
      >
        <PopOutIcon />
      </IconButton>
      <IconButton
        onClick={handleOpen(false)}
        aria-label="close modal"
        edge="end"
        className={classes.detailIcons}
      >
        <CloseIcon />
      </IconButton>
    </>);
  }

  function renderProcess() {
    switch (pageState) {
      case "acquire":
      case "acquireBasic":
      case "acquireAdvanced":
        return <ProcessDetailsModalAcquire {...data.process} refreshState={props.refreshState} fullScreen={props.fullScreen} />;
      case "scheduledOfferingsBasic":
      case "scheduledOfferingsAdvanced":
        return <ProcessDetailsModalEsp {...data.process} eventData = {eventData} refreshState={props.refreshState} fullScreen={props.fullScreen} />;
      case 'assetViewBasic':
      case 'assetViewAdvanced':
        return <ProcessDetailsModalAsset {...data.process} refreshState={props.refreshState} fullScreen={props.fullScreen} />;
      default:
        return null;
    }
  }

  function renderGeneralInformation() {
    return (
      <ContainerPaper className={classes.generalInformationContainer}>
        <TableToolbar className={classes.generalInformationHeader} variant="dense">General Information</TableToolbar>
        <Box className={classes.generalInformationBox}>
          <TableContainer classes={{ root: classes.generalInformationRoot }}>
            <Table aria-label="General information" size="small">
              <TableBody>{renderGeneralInformationRows()}</TableBody>
            </Table>
          </TableContainer>
        </Box>
      </ContainerPaper>
    );
  }

  function markup(value) {
    return {
      __html: value
    };
  };


  /**
   * @dangerouslySetInnerHTML - this is attribute is vulenarable to XSS attacks, so need to sanitize the string data
   * @Todo Sanitize the string data that is passing to dangerouslySetInnerHTML
   */
  function handleChangeTimestamps(event) {
    setAdditionalTimestamps(event.target.checked);
  }
  function renderGeneralInformationRows() {
    return data && data.generalInformation && data.generalInformation.map(([key, value], index) => (
      (key === "additionalTimestamps") ? (
        <div className={classes.additionContainer}>
          <input type="checkbox" onChange={handleChangeTimestamps} checked={additionalTimestamps}></input>
          <span className={classes.additionText} >{value}  <CustomTooltip style={{ marginLeft: "inherit" }} text={'Provides additional messages including additional IDs and timestamps for each message sent to DISCO'} info={true} /></span>
        </div>
      ) : (
        <TableRow className={classes.generalInformationTableRow} key={key + index}>
          <TableCell className={classes.generalInformationKey}>{key + ""} :</TableCell>
          <TableCell className={classes.generalInformationValue} dangerouslySetInnerHTML={markup(value)} />
        </TableRow>
      )
    ));
  }
  const text = "media";
  function handleOpenNewTab() {
    const detailId = workflowDirectory=='espEvents'?_.get(props, 'eventData.records[0].offering.espId', ''):_.get(props, 'data.detailId', '');
    const includeOfferings =props.includeOfferings?`&includeOfferings=${props.includeOfferings}`:'';
    window.open(`/?detailId=${detailId}&workflow=${pageState}${includeOfferings}`, "_blank");
  }

  return render();
}

DetailsModalContent.defaultProps = {
  handleOpen: _.noop(),
  isFullScreen: false,
  data: { generalInformation: [], isExpired: false },
};

DetailsModalContent.propTypes = {
  data: PropTypes.shape({
    generalInformation: PropTypes.arrayOf(PropTypes.array).isRequired,
    process: PropTypes.shape({
      id: PropTypes.string,
      milestones: PropTypes.any,
    }),
    title: PropTypes.string.isRequired,
    isExpired: PropTypes.bool
  }),
  handleOpen: PropTypes.func,
  open: PropTypes.bool,
  isFullScreen: PropTypes.bool,
};
