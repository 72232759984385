import React, { useState } from 'react';

import TopBar from '../molecules/TopBar';
import Navigator from '../molecules/Navigator';


export default function Header({handleToogleDrawer, openMenu}) {

    return (
        <>
            <TopBar isMenuOpen={openMenu} drawerHandler={handleToogleDrawer}>Dashboard</TopBar>
            <Navigator isMenuOpen={openMenu} drawerHandler={handleToogleDrawer} />
        </>
    );
};
