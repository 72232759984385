import React, {FunctionComponent, ReactElement} from 'react';
import {ListItem, ListItemText, makeStyles} from '@material-ui/core';
import {PageContext} from '../../services/PageProvider';
import clsx from 'clsx';

export interface NavItemProps {
    text: string
    icon: ReactElement
    to: string
}

const NavItem: FunctionComponent<NavItemProps> = ({children: text, icon, to}) => {
    const itemClasses = itemStyles();
    const itemTextClasses = itemTextStyles();
    const {pageState, setPageState, setGridState} = React.useContext(PageContext);

    function pageHandler() {
        if( pageState != to ){
            setGridState({gridApi:null,columnApi:null});
        }
        setPageState(to);
    }

    return <ListItem
        button
        onClick={pageHandler}
        className={clsx(itemClasses.root, {
            selected: pageState === to
        })}
        selected={pageState === to}
    >
        {icon}
        <ListItemText classes={itemTextClasses} primary={text}/>
    </ListItem>;
};

const itemStyles = makeStyles(theme => {
    const paddingLeftGutter: number = +theme.mixins.gutters().paddingLeft;

    return {
        root: {
            flexDirection: 'column',
            '&.selected': {
                backgroundColor: 'initial',
                borderLeft: '2px solid #9580FF',
                paddingLeft: paddingLeftGutter - 5,
                color: '#9580FF',
                transition: theme.transitions.create(['padding-left', 'border-left'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
            }
        }
    }
});

const itemTextStyles = makeStyles({
    root: {
        whiteSpace: 'pre-wrap',
        width: '95%'
    },
    primary: {
        fontSize: '10px',
        lineHeight: '14px',
        textAlign: 'center'
    }
});

export default NavItem;
