import React from "react";
import CustomTooltip from "../components/organisms/CustomTooltip";
import { EXPIRED_TOOLTIP } from "../constants/constants"
const TOOL_TIP = "This is an expired offering";

/**
 * RenderIconWithToolTip
 * @param isIconRequired 
 * @param iconClass 
 * @returns 
 */
const RenderIconWithToolTip = ({isIconRequired = false, iconClass = null}) => {
  return (
    isIconRequired && <span
      data-multiline
      data-for="hover-tooltip"
      data-tip={EXPIRED_TOOLTIP}
      data-showTooltip="true"
    >
      <span className={iconClass} />
    </span>
  );
};
/**
 * Blankholder 
 */
const renderBlankHolder = ()=>{
  const styleContents = {
    "paddingLeft":"22px"
  }
  return <span style= { styleContents } ></span>
}
/**
 * Render Icon with string
 * @param para
 * @returns
 */
const RenderStringWithIcon = ({ isIconRequired, title, iconClass }) => {
  return (
    <span className={"d-flex"}>
      { isIconRequired ? RenderIconWithToolTip({isIconRequired, iconClass}): renderBlankHolder()}
      <CustomTooltip cellContent={title || "NA"} />
    </span>
  );
};

export { RenderStringWithIcon, RenderIconWithToolTip };
