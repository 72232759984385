import React from "react";
import _ from "lodash";
import {FiberManualRecord, FiberManualRecordOutlined ,} from "@material-ui/icons";
import {getMaskedStatus} from "../../utils/table-utils";
import {makeStyles, SvgIcon as MuiSvgIcon, withStyles,} from "@material-ui/core";
import {steel, neutral} from '../templates/palette.json';

const useStyles = makeStyles((theme) => {
  return {
    green: {
      color: theme.disco.stopLights.green,
    },
    red: {
      color: theme.disco.stopLights.red,
    },
    yellow: {
      color: theme.disco.stopLights.yellow,
    },
    blue: {
      color: theme.disco.stopLights.blue,
    },
    gray: {
      color: theme.palette.type === 'dark' ? theme.disco.steel[600] : theme.disco.steel[200],
    },
    grey: {
      color: theme.disco.stopLights.grey
    },
    fillColor: {
      fill: theme.palette.type === 'dark' ? steel[600] : neutral[300],
    },
    strokeColor: {
      stroke: theme.palette.type === 'dark' ? steel[800] : neutral[600],
    },
  };
});
const SvgIcon = withStyles({
  root: {
    stroke: "currentColor",
  },
})(MuiSvgIcon);

const RefreshSvgIcon = withStyles({
  root: {
    stroke: "currentColor",
    fontSize: "1.75rem",
  },
})(MuiSvgIcon);

const CircleIcon = withStyles((_) => ({
  root: {
    fontSize: "1.8rem",
  },
}))(FiberManualRecord);

const CircleBorderIcon = withStyles((_) => ({
  root: {
    fontSize: "1.8rem",
    color: _.palette.type === 'dark' ? steel[700] : steel[300],

  },
}))(FiberManualRecordOutlined);

export function UserIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17 21v-2a3.9996 3.9996 0 00-1.1716-2.8284A3.9996 3.9996 0 0013 15H5a3.9998 3.9998 0 00-4 4v2M9 11c2.2091 0 4-1.7909 4-4 0-2.2091-1.7909-4-4-4-2.2091 0-4 1.7909-4 4 0 2.2091 1.7909 4 4 4zM23 21v-2a4.0002 4.0002 0 00-3-3.87M16 3.13a4 4 0 010 7.75"
      />
    </SvgIcon>
  );
}

export function AcquireIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 14h6v6M20 10h-6V4M14 10l7-7M3 21l7-7"
      />
    </SvgIcon>
  );
}

export function AssetViewIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.1161 3H4.0129C2.90121 3 2 3.69324 2 4.54839V7.64516C2 8.50031 2.90121 9.19355 4.0129 9.19355H20.1161C21.2278 9.19355 22.129 8.50031 22.129 7.64516V4.54839C22.129 3.69324 21.2278 3 20.1161 3Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M20.1161 14H4.0129C2.90121 14 2 14.6932 2 15.5484V18.6452C2 19.5003 2.90121 20.1936 4.0129 20.1936H20.1161C21.2278 20.1936 22.129 19.5003 22.129 18.6452V15.5484C22.129 14.6932 21.2278 14 20.1161 14Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.99951 6.22559H6.01021"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.99951 17.6133H6.01021"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>

    </SvgIcon>
  )
}

export function LogoutIcon(props) {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 14H3.3333A1.3335 1.3335 0 012 12.6667V3.3333A1.3334 1.3334 0 013.3333 2H6M10.6667 11.3333L14.0001 8l-3.3334-3.3333M14 8H6"
      />
    </SvgIcon>
  );
}

export function HelpIcon(){
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
</svg>
  );
}

export function RefreshIcon(props) {
  return (
    <RefreshSvgIcon viewBox="0 0 16 12" {...props}>
      <svg fill="none">
        <path
          d="M12.833 1.862v3.182H9.65M1.167 10.347V7.165h3.181"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.498 4.514a4.773 4.773 0 017.875-1.782l2.46 2.312M1.167 7.165l2.46 2.313a4.773 4.773 0 007.875-1.782"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </RefreshSvgIcon>
  );
}

export function PopOutIcon(props) {
  const classes = useStyles();
  return (
    <SvgIcon viewBox="0 0 20 20" fill="none" style={{ stroke: "transparent", fontSize:'1.67rem'}}>
      <circle cx="10" cy="10" r="10" className={classes.fillColor} />
      <path d="M8 5.5H6.5C6.23478 5.5 5.98043 5.60536 5.79289 5.79289C5.60536 5.98043 5.5 6.23478 5.5 6.5V8M14.5 8V6.5C14.5 6.23478 14.3946 5.98043 14.2071 5.79289C14.0196 5.60536 13.7652 5.5 13.5 5.5H12M12 14.5H13.5C13.7652 14.5 14.0196 14.3946 14.2071 14.2071C14.3946 14.0196 14.5 13.7652 14.5 13.5V12M5.5 12V13.5C5.5 13.7652 5.60536 14.0196 5.79289 14.2071C5.98043 14.3946 6.23478 14.5 6.5 14.5H8" className={classes.strokeColor} stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M11.5 5.5H14.5V8.5" className={classes.strokeColor} stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M14.5 5.5L11 9" className={classes.strokeColor} stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
    </SvgIcon>
  )
}

export function CloseIcon(props) {
  const classes = useStyles();
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" style={{ stroke: "transparent"}}>
      <circle cx="10" cy="10" r="10" className={classes.fillColor} />
      <path d="M13 7.4082L7 13.4249" className={classes.strokeColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7 7.4082L13 13.4249" className={classes.strokeColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </SvgIcon>
  )
}

export function StatusIcon(props) {
  const classes = useStyles();
  const status = getMaskedStatus(_.get(props, "status", "").toLowerCase());

  switch (status) {
    case "complete":
      return <CircleIcon className={classes.green} />;
    case "incomplete":
      return <CircleIcon className={classes.yellow} />;
    case "error":
      return <CircleIcon className={classes.red} />;
    case "not_applicable":
    case "cancelled":
        return <CircleIcon className={classes.gray} />;
    case "inprogress":
      return <RefreshIcon className={classes.blue} />;
    case "backfill":
      return <CircleIcon className={classes.green} />;
    default:
      return <CircleBorderIcon />;
  }
}