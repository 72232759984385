import axios from 'axios';
import axiosRetry from 'axios-retry';
import { useContext } from "react";
import { ConfigContext } from "../components/security/ConfigContext";
import { useOktaAuth } from '@okta/okta-react';
// Disco-service implementation accepts params values in lowercase 
// paramsSerializer can be removed later


const axiosInstance = axios.create({
    headers: {
        'Accept': 'application/json'
    },
    paramsSerializer: params => { 
        let result =  Object.keys(params).sort()
        .map(key => ((params['skipCaseMapping'] || params['view']) ? `${key}=${encodeURIComponent(params[key])}` : 
        `${key}=${encodeURIComponent(params[key])}`.toLowerCase()))//
        .join('&');
        return result;
    }
});
const axiosPostInstance = axios.create({
    headers: {
        'Accept': 'application/json'
    }
});

axiosInstance.interceptors.request.use(function (config) {
    // Do something before request is sent
    config.baseURL = config.baseApiGatewayUrl;
    config.headers['Authorization'] = 'Bearer ' + config.accessToken;
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

  axiosPostInstance.interceptors.request.use(function (config) {
    // Do something before request is sent
    config.baseURL = config.baseApiGatewayUrl;
    config.headers['Authorization'] = 'Bearer ' + config.accessToken;
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

axiosRetry(axiosInstance, {
    retries: 2,
    shouldResetTimeout: true
});

axiosRetry(axiosPostInstance, {
    retries: 2,
    shouldResetTimeout: true
});

const useAxios =  ()  => {
    let [config] = useContext(ConfigContext);
    const { authState,oktaAuth } = useOktaAuth();   
    function get (config) {
        config.method = 'get';
        return request(config);
    }

    function post (config) {
        config.method = 'post';
        return request(config);
    }

    function put (config) {
        config.method = 'put';
        return request(config);
    }

    function deleteReq (config) {
        config.method = 'delete';
        return request(config);
    }
    /**
     * Api Request Method
     * @param {*} params 
     * @returns api response 
     */
    async function request (params) {
        let callParams = {...params, ...config};
        const isAuthenticated = authState?.isAuthenticated || false;
        if (isAuthenticated) {
            const { accessToken } = await oktaAuth.tokenManager.get("accessToken");
            callParams.accessToken = accessToken;
            try {
                let res;
                if(callParams.method === 'post') {
                    res = await axiosPostInstance(callParams);
                } else {
                    res = await axiosInstance(callParams);
                }
                return res.data;
            }
            catch (e) {
                console.log('Api call error', e);
                Promise.reject(e);
            }
        }

    }

    return {
        get,
        post,
        put,
        deleteReq

    }
};

export default useAxios;


// Example:
// import axios from '../utils/axios';

// axios.get({url: '/v1/acquireEvents/count',
// params: {
//   enddatetime: 1599451140000,
//   milestone: JSON.stringify({"Name":["preProcessing","encoding"],"status":['complete']})
// }}).then((res)=> {

// }).catch(() => {

// })

// or we can use async await pattern
