import React from 'react';
import { makeStyles } from '@material-ui/core';
import { infoIcon, getMileStoneStatusClass } from '../../utils/table-utils';

const useStyles = makeStyles({
    addEllipsis: {
        maxWidth: '90%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'inline-block'
    },
    showCursor: {
        cursor: 'pointer'
    }
})

function InfoTooltip({ text }) {
    return (
        <span>
            <span
                data-iscapture='true'
                data-event='click'
                globalEventOff='click'
                data-tip={text}
                data-for='global-tooltip'
            >
                {infoIcon()}
            </span>
        </span>
    );
}

function HoverTooltip({ cellContent, tooltipContent, showTooltip }) {
    const classes = useStyles();
    let tooltipText = (cellContent === 'NA') ? '' : (tooltipContent || cellContent);

    return (
        <span
            data-multiline
            data-for='hover-tooltip'
            className={classes.addEllipsis}
            data-tip={tooltipText}
            data={cellContent}
            data-showTooltip={showTooltip}
        >
            {cellContent}
        </span>
    );
}

function MilestoneTooltip({ cellContent, tooltipText, data }) {
    return <div data={data} data-for='global-tooltip' data-multiline className={getMileStoneStatusClass(cellContent)} data-tip={tooltipText}></div>
}

function CustomTooltip(props) {
    return (
        <>
            {
                props.info ?
                    <InfoTooltip {...props} /> :
                    props.milestone ? <MilestoneTooltip {...props} /> : <HoverTooltip {...props} />
            }
        </>
    );
}

export default React.memo(CustomTooltip);
