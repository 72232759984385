import React from "react";
import { Grid, Container } from "@material-ui/core";
import { ServiceHelp } from "./ServiceHelp";
import { DiscoDoc } from "./HelpNav";
import { HelpDocs } from "./HelpDocs";
import { Row, Col } from "react-bootstrap";
import { makeStyles } from '@material-ui/core/styles';
import usePreference from "../preference/usePreference";
import storage from '../../utils/storage';

const useStyles = makeStyles((theme) => ({
    backgroundDark: {
        background: 'linear-gradient(180deg, #30404F 0%, #1B2329 144.77%)',
    },
    backgroundLight: {
        background: '#E5E5E5',
        // backgroundImage: `/assets/svg/background-bottom.svg)`
    },

    background: {
        height: '100%',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        gap: "50px",
        marginTop: "0px",
    }
}))

export function WrapperContainer(props) {
    const classes = useStyles();
    let darkStorage = false;
     // taking the flag for darkstorage
    if (props.match.params) {
        darkStorage = (props.match.params.darkStorage) ? atob(props.match.params.darkStorage) : false;
    }

    darkStorage = (darkStorage === 'true');
    const classApply = darkStorage ? classes.backgroundDark : classes.backgroundLight;

    return (
        <div className={`${classes.background} ${classApply} `}>
            <Container style={{ height: '100vh', padding: "32px", overflow: "auto" }} disableGutters={true} maxWidth={false}>
                <HelpDocs darkStorage={darkStorage} />

                <div className={classes.wrapper}>
                    <DiscoDoc darkStorage={darkStorage} />
                    <ServiceHelp darkStorage={darkStorage} />
                </div>


            </Container>
        </div>


    )
}