import {Drawer, List, Switch} from "@material-ui/core";
import React, {useContext, useEffect} from 'react';
import _ from 'lodash';
import NavItem from '../atoms/NavItem';
import PropTypes from 'prop-types';
import {TemplateContext} from '../templates/TemplateProvider';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import storage from "../../utils/storage";
import navItems from "../../config/navItems";
import { AutoRefreshContext } from "../AutoRefreshContext";
import usePreference from "../preference/usePreference";
import { CurrentUserContext } from '../security/SecurityContext';



const sessionStore = storage('session');

Menu.propTypes = {
    isMenuOpen: PropTypes.bool.isRequired
};

const useStyles = makeStyles(theme => ({
    drawer: {
        width: theme.drawerWidth,
    },
    drawerPaper: {
        whiteSpace: 'nowrap',
        width: theme.drawerWidth,
        borderRight: 'none',
        // paddingTop: 52,
        backgroundColor: theme.palette.primary.main,
        overflow: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxShadow: theme.palette.type === 'dark' ?
            '0px 0px 20px #03060E' : '0px 4px 15px rgba(18, 19, 19, 0.1)',
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(0),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(0),
        },
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.primary.main
    },
    switchTheme: {
        backgroundColor: theme.palette.primary.main,
        backgroundImage: `url(/assets/svg/sun-${theme.palette.type}.svg), url(/assets/svg/moon-${theme.palette.type}.svg)`,
        backgroundPosition: 'right 10px center, 10px center',
        backgroundRepeat: 'no-repeat',
        textAlign: 'center'
    },
    autoRefreshStyles: {
        backgroundColor: theme.palette.primary.main,
        backgroundImage: `url(/assets/svg/disabled-${theme.palette.type}.svg), url(/assets/svg/default-${theme.palette.type}.svg)`,
        backgroundPosition: 'right 10px center, 10px center',
        backgroundRepeat: 'no-repeat',
        textAlign: 'center'
    },
    betaIcon:{
        //position: 'absolute',
        width: '42px',
        height: '14px',
        //left: '23px',
        //top: '73px',
        marginTop: '5px',
        display: 'inline-block',
        background: '#D0D9E2',
        borderRadius:'10px'
    },
    beta:{
        fontFamily:' Open Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '10px',
        lineHeight: '14px',
        textAlign: 'center',
        color: theme.palette.type === 'dark' ? theme.disco.steel[700] : ''
    }
}));

export default function Menu({isMenuOpen}) {
    const classes = useStyles();
    const {darkState, setDarkState} = useContext(TemplateContext);
    const {autoRefresh, setAutoRefresh} = useContext(AutoRefreshContext);
    const [userInfo] = useContext(CurrentUserContext);
    const persistedTeamProfile = sessionStore.getItem("teamProfile");
    const { getUsersPrefesAutoRefresh, getUsersPrefesMode  } = usePreference();

    useEffect(() => {
        if (userInfo) {
            const autoStorage = getUsersPrefesAutoRefresh();
            const darkStorage = getUsersPrefesMode();
            setAutoRefresh(autoStorage !== null ? autoStorage : autoRefresh);
            setDarkState(darkStorage !== null ? darkStorage : darkState);
        }
      }, [userInfo]);


    function handleRefresh() {
        setAutoRefresh(!autoRefresh);
    }

    function themeHandler() {
        setDarkState(!darkState);
    }

    function mergeNavItemAndAppConfig() {
        const viewsConfig = sessionStore.getItem('viewsConfig') || [];
        if (viewsConfig && _.isObject(viewsConfig)) {
            let navItemsCopy = {...navItems};

            for (const key in navItemsCopy) {
                const persistedItem = viewsConfig.find(el => el.key === key);

                if(persistedItem && persistedItem.label) {
                    navItemsCopy[key].text = persistedItem.label;
                }
            }

            return navItemsCopy;
        }

        return navItems;
    }

    function filterNavItems() {
        let alterNavItems = mergeNavItemAndAppConfig();
        const priority = ['ops', 'non-ops'];

        if (persistedTeamProfile && _.isObject(persistedTeamProfile)) {
            return Object.keys(alterNavItems).map(key => {
                return _.assign(alterNavItems[key], getTeamProfilePriority(persistedTeamProfile, priority, key));
            }).filter(el => el.isVisible);
        }

        return Object.values(alterNavItems).filter(el => el.isVisible);
    }

    function  getTeamProfilePriority(teamProfile, priority, key) {
        if(teamProfile && Array.isArray(teamProfile) && teamProfile.length === 1) {
            return teamProfile[0][key];
        }

        if(priority && Array.isArray(priority) && priority.length === 0) {
            return undefined;
        }

        const output = teamProfile.find(el => {
            return el.key === priority[0];
        });

        if(output) {
            return output[key];
        }

        getTeamProfilePriority(teamProfile, priority.splice(1), key);
    }

    return (
        <React.Fragment>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                classes={{
                    paper: clsx(classes.drawerPaper, !isMenuOpen && classes.drawerPaperClose),
                }}
                open={isMenuOpen}
            >
                <div style={{height: '48px'}}></div>
                <List classes={{root: classes.root}}>
                    {
                        filterNavItems().map(item => (<NavItem key={item.text} icon={item.icon} to={item.to}>
                            {item.text}
                        </NavItem>))
                    }
                </List>
                <div className={classes.autoRefreshStyles} id="autoRefresh-parent">
                    <Switch
                        checked={autoRefresh !== true}
                        onChange={handleRefresh}
                        id="autoRefresh-switch"
                        name="Auto Refresh"
                        inputProps={{'aria-label': 'Auto Refresh'}}
                    />
                </div>
                <div className={classes.switchTheme} id="theme-parent">
                    <Switch
                        checked={darkState !== true}
                        onChange={themeHandler}
                        id="theme-switch"
                        name="change theme"
                        inputProps={{'aria-label': 'Change theme'}}
                    />
                </div>
            </Drawer>
            {/* <SearchFormDrawer isMenuClose={!isMenuOpen} filterForm={EspSearchForm}  /> */}
        </React.Fragment>
    );
};
