//Acquire Table constants starts here
export const acquireConst = {
  page: {
    noDataTxt: "There is no data",
  },
  showColumn: [
   
    {
      colId: "platform",
      hide: true,
    },
    {
      colId: "cid",
      hide: true,
    },
    {
      colId: "externalid",
      hide: false,
    },
    {
      colId: "titleid",
      hide: false,
    },
    {
      colId: "createddate",
      hide: false,
    },
    {
      colId: "workflowtype",
      hide: false,
    },
    {
      colId: "episodenumber",
      hide: false,
    },
    {
      colId: "seriesname",
      hide: false,
    },
    {
      colId: "componentInfo",
      hide: true,
    },
    {
      colId: "totalRunTime",
      hide: true,
    },
  ],

  defaultColumns: [
    {
      colId: "detailsView",
      hide:false,
    },
    {
      colId: "deeplink",
      hide:false,
    },
    {
      colId: "title",
      hide: false,
    }
  ],
};
//ESP Table constants starts here
export const espConst = {
  tableHeader: {
    title: "Title",
    platform: "Platform",
    territory: "Territory",
    fulfillmentType: "Fulfillment Type",
    airingID: "ID",
    cid: "CID",
    titlepaviid: "TitleId",
    scheduledAssetId: "Scheduled Asset Id",
    episodeInSeason: "Episode In Season",
    offeringType: "Offer. Type",
    series: "Series",
    seasonNumber: "Season Number",
    componentInfo: "Component Type & Language",
    playOrder: "Play Order#",
    startDate: "Start Date/Time(EDT)",
    totalRunTime: "Total RunTime",
    mediaRequestPublished: "Media Request Published",
    qmAvailable: "QM Availability (CID)",
    mezzFulfilment: "Mezz Fulfilment",
    cam: "Encoding Mezz Received (CAM)",
    derivativeCreation: "Derivative Creation",
    manualQC: "Manual  QC",
    encodeFulfilment: "Encode Fulfilment",
    metaDelivery: "Meta Delivery",
    videoDelivery: "Video Delivery",
    imageDelivery: "Image Delivery",
    endpointFulfilment: "Endpoint Fulfilment",
    endpointReceipt: "Endpoint Receipt",
    endpointreceiptMeta: "Endpoint Receipt Meta",
    endpointreceiptVideo: "Endpoint Receipt Video",
    endpointreceiptImage: "Endpoint Receipt Image",
  },
  tableWidths: {
    rowCollapseDetails: { width: 28 },
    detailButton: { width: 28 },
    avodLabel: { width: 28 },
    platform: { width: 70 },
    territory: { width: 108 },
    fulfillmentType: { width: 124 },
    title: { width: 144 },
    airingID: { width: 124 },
    cid: { width: 70 },
    titlepaviid: { width: 54 },
    scheduledAssetId: { width: 74 },
    episodeInSeason: { width: 60 },
    offeringType: { width: 82 },
    series: { width: 104 },
    playOrder: { width: 54 },
    seasonNumber: { width: 104 },
    componentInfo: { width: 104 },
    startDate: { width: 105, "text-align": "center" },
    mediaRequestPublished: {},
    qmAvailable: {},
    mezzFulfilment: {},
    cam: {},
    derivativeCreation: {},
    manualQC: {},
    encodeFulfilment: {},
    metaDelivery: {},
    videoDelivery: {},
    imageDelivery: {},
    endpointReceipt: {},
    endpointreceiptMeta: {},
    endpointreceiptVideo: {},
    endpointreceiptImage: {},
  },
  showColumn: [
    {
      colId: "platform",
      hide: true,
    },
    {
      colId: "territory",
      hide: false,
    },
    {
      colId: "fulfillmentType",
      hide: true,
    },
    {
      colId: "id",
      hide: false,
    },
    {
      colId: "cid",
      hide: true,
    },
    {
      colId: "titlepaviid",
      hide: true,
    },
    {
      colId: "scheduledAssetId",
      hide: true,
    },
    {
      colId: "offeringtype",
      hide: false,
    },
    {
      colId: "series",
      hide: false,
    },
    {
      colId: "playOrder",
      hide: true,
    },
    {
      colId: "seasonNumber",
      hide: true,
    },
    {
      colId: "episodeInSeason",
      hide: true,
    },
    {
      colId: "startdate",
      hide: false,
    },
    {
      colId: "componentInfo",
      hide: true,
    },
    {
      colId: "totalRunTime",
      hide: true,
    },
  ],
  defaultColumns: [
    {
      colId: "detailsView",
      hide:false,
    },
    {
      colId: "adstatus",
      hide:false,
    },
    {
      colId: "title",
      hide: false,
    },
  ],
};

export const DEFAULT_TEAM = [
  {
    name: "ops",
    key: "ops",
    label: "Operations",
    acquire: {
      // acquireAdvanced
      isVisible: true,
      filters: {
        distributors: [],
        networks: [],
      },
      columns: [],
    },
    acquireBasic: {
      isVisible: false,
      filters: {
        distributors: [],
        networks: [],
      },
      columns: [],
    },
    espConst: {
      isVisible: true,
      filters: {
        platforms: [],
        channels: [],
      },
      columns: [],
    },
    scheduledOfferingsBasic: {
      isVisible: false,
      filters: {
        platforms: [],
        channels: [],
      },
      columns: [],
    },
    assetViewAdvanced: {
      isVisible: true,
      columns: [],
      filters: {
        platforms: [],
        channels: [],
      },
    },
    scopes: ["mscp.disco.ops"],
    defaultView: "scheduledOfferingsAdvanced",
    isActive: true,
  },
];

export const DEFAULT_ROLES = [
  {
    group: "*MSE DiSCO Users (HBO)",
    scopes: [
      "mscp.disco.api.read",
      "mscp.disco.api.write",
      "mscp.disco.api.delete",
    ],
  },
  {
    group: "*MSE DiSCO Dev (HBO)",
    scopes: [
      "mscp.disco.api.read",
      "mscp.disco.api.write",
      "mscp.disco.api.delete",
    ],
  },
  {
    group: "STAGE|HBO|DISCO SPA STAGE|QANonOpsusers",
    scopes: ["mscp.disco.nonops"],
  },
  {
    group: "STAGE|HBO|DISCO SPA STAGE|QAOperationalUsers",
    scopes: ["mscp.disco.qa.ops"],
  },
  {
    group: "STAGE|HBO|DISCO SPA - UAT|UAT Product Team",
    scopes: ["Mscp.disco.uat.nonops"],
  },
  {
    group: "STAGE|HBO|DISCO SPA - UAT|UAT Operations and Support",
    scopes: ["mscp.disco.uat.ops"],
  },
  {
    group: "PRD|HBO|DISCO SPA|Production Product Team",
    scopes: ["mscp.disco.prod.nonops"],
  },
  {
    group: "PRD|HBO|DISCO SPA|Production Operations and Support",
    scopes: ["mscp.disco.prod.ops"],
  },
  {
    group: "*",
    scopes: ["openid", "profile", "email"],
  },
];

//Asset view table constants
export const assetConst = {
  tableHeader: {
    title: "Title",
    platform: "Platform",
    territory: "Territory",
    revisions: "Fulfill#",
    fulfillmentType: "Fulfillment Type",
    // id: "ID",
    cid: "CID",
    titlepaviid: "TitleId",
    csid: "CSID",
    // scheduledAssetId: "Scheduled Asset Id",
    // episodeInSeason: "Episode In Season",
    offeringType: "Offer. Type",
    series: "Series",
    seasonNumber: "Season Number",
    componentInfo: "Component Type & Language",
    startDate: "Need By Date/Time(EDT)",
    totalRunTime: "Total RunTime",
    mediaRequestPublished: "Media Request Published",
    qmAvailable: "QM Availability (CID)",
    mezzFulfilment: "Mezz Fulfilment",
    cam: "Encoding Mezz Received (CAM)",
    derivativeCreation: "Derivative Creation",
    manualQC: "Manual QC",
    encodeFulfilment: "Encode Fulfilment",
    // metaDelivery: "Meta Delivery",
    // videoDelivery: "Video Delivery",
    // imageDelivery: "Image Delivery",
    // endpointFulfilment: "Endpoint Fulfilment",
    // endpointReceipt: "Endpoint Receipt",
  },
  tableWidths: {
    detailButton: { width: "3%" }, // 28
    rowCollapseDetails: { width: "2%" },
    avodLabel: { width: "5%" },
    platform: { width: "9%" },
    territory: { width: "10%" },
    revisions: { width: "7%" },
    fulfillmentType: { width: "11%" },
    title: { width: "7%" },
    // id: { width: '7%' },
    cid: { width: "5%" },
    titlepaviid: { width: "7%" },
    csid: { width: "7%" },
    // scheduledAssetId: { width: '5%' },
    // episodeInSeason: { width: '5%' },
    offeringType: { width: "6%" },
    series: { width: "6%" },
    seasonNumber: { width: "6%" },
    componentInfo: { width: "11%" },
    startDate: { width: "15%", "text-align": "center" },
    mediaRequestPublished: { width: "9%" },
    qmAvailable: { width: "9%" },
    mezzFulfilment: { width: "9%" },
    cam: { width: "9%" },
    derivativeCreation: { width: "9%" },
    manualQC: { width: "9%" },
    encodeFulfilment: { width: "9%" },
    // endpointFulfilment:{width: '9%'},
    // metaDelivery: {},
    // videoDelivery: {},
    // imageDelivery: {},
    // endpointReceipt: {}
  },
  showColumn: [
    { colId: "platform", hide: false },
    { colId: "territory", hide: false },
    { colId: "revisions", hide: true },
    { colId: "fulfillmentType", hide: true },
    // id: true,
    { colId: "cid", hide: true },
    { colId: "titlepaviid", hide: true },
    { colId: "csid", hide: false },
    // scheduledAssetId: false,
    { colId: "offeringtype", hide: false },
    { colId: "series", hide: false },
    { colId: "seasonNumber", hide: true },
    // episodeInSeason: false,
    { colId: "componentInfo", hide: true },
    { colId: "totalRunTime", hide: true },
    { colId: "startdate", hide: false },
  ],
  defaultColumns: [
    { colId: "detailsView", hide: false },
    { colId: "deeplink", hide: false },
    { colId: "adstatus", hide: false }, 
    { colId: "title",hide: false},
  ],
};

export const COLUMNS_MAPPING = {
  scheduledOfferingsAdvanced: "schOffColumn",
  scheduledOfferingsBasic: "schOffColumn",
  assetViewAdvanced: "assetViewColumns",
  acquire: "acqColumn",
  acquireBasic: "acqColumn",
};

export const VIEWS_MAPPING = {
  scheduledOfferingsAdvanced: "esp",
  scheduledOfferingsBasic: "esp",
  assetViewAdvanced: "avp",
  acquire: "asp",
  acquireBasic: "asp",
};

export const USER_PREFERENCE = {
  SAVE: "Save Preferences",
  DISCARD: "Clear Preferences",
  CANCEL: "Cancel",
  CONTINUE: "Yes, clear all",
  SAVE_MESSAGE: "Changes in your preferences saved successfully.",
  ERROR_MESSAGE: "Changes in your preferences could not save.",
  DISCARD_MESSAGE_SUCCESS: "Preferences cleared successfully",
  DISCARD_MESSAGE_ERROR: "Preferences could not clear successfully",
};

export const PROMPT_MESSAGE = {
  header: "Clear Confirmation",
  textLineOne: "You are about discard all the changes applied to the dashboard",
  textLineTwo: "Do you want to continue with this action?",
};

export const MENU_SETTINGS = {
  export: "Export",
  exportSettings: "Export Settings",
  shareSearch: "Share Search",
};

export const EXPORT_SETTINGS = {
  cancel: "Cancel",
  save: "Save",
  columnText: "Columns",
  header: "Export Settings",
  export: "Export",
  defaultMessage:
    "No columns were selected, the export will include all column data by default.",
  columnCountMessage: "Columns selected to export",
};

export const getConstValues = (view) => {
  const pageType = {
    esp: espConst,
    avp: assetConst,
    asp: acquireConst,
  };
  return pageType[view];
};
export const EXPIRED_TOOLTIP = "This is an expired offering";
