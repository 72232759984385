import { LoginCallback, SecureRoute } from '@okta/okta-react';
import { BrowserRouter as Router,Route  } from 'react-router-dom';
import App from './application';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import React from 'react';
// import AppBar from './components/HeaderNav';
import WrappedSecurity from './components/security/WrappedSecurity';
// import EspDashboard from './pages/PageHandler';
// import Home from './pages/Home';
// import Profile from './pages/Profile';
import {WrapperContainer} from './components/helper/WrapperContainer';

const AppWithRouterAccess = () => {
  return (
    <Router>
      <WrappedSecurity>
        <CssBaseline />
        {/* <AppBar></AppBar> */}
        <Container style={{height: '100vh'}} disableGutters={true} maxWidth={false}>
          <SecureRoute path="/" exact component={App} />
          <Route path="/implicit/callback" component={LoginCallback} />
          <Route path="/help/:darkStorage" exact component={WrapperContainer} />
          {/* <SecureRoute path="/profile" component={Profile} /> */}
          {/* <SecureRoute path='/add' component={AddTitle} /> */}
        </Container>
      </WrappedSecurity>
    </Router>
  )
};
export default AppWithRouterAccess;