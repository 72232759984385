import { useState, useContext } from 'react';
import storage from './storage';
import useAxios from './axiosImpl';
import * as apiMap from './api.json';
import {DEFAULT_ROLES} from "../constants/constants"
import { useOktaAuth } from '@okta/okta-react';
import { SecurityContext } from '../components/security/WrappedSecurity';
const sessionStore = storage('session');

export function setUserRoles(data: any) {
  sessionStore.setItem("userRoles", data);
}

export function useUserRoles() {

  const [error, setError] = useState(null);
  const { get } = useAxios();
  const { authState, oktaAuth } = useOktaAuth();
  const [isSecurityLoaded, userScopes, config] = useContext(SecurityContext);

  let userRoles = sessionStore.getItem("userRoles");
  let customScopes = sessionStore.getItem("persistedScopes");

  async function fetchUserRolesAndScopes() {
    if (!userRoles) {
      try {
        const rbacRoles = await get({ url: apiMap.userRoles});
        userRoles = rbacRoles || DEFAULT_ROLES;
        setUserRoles(userRoles);
      } catch (err) {
        setError("Unable to fetch user roles");
        return;
      }
    }
    if(!customScopes) {
        try {
            const idToken = await oktaAuth.tokenManager.get("idToken");
            const userGroupList: string[] = idToken.claims?.[config.groupClaimName];
            if (!userGroupList) {
              throw new Error(`${config.groupClaimName} not found in idToken`)
            }
            let scopes: string[] = extractScopesForUserGroups(
                userGroupList,
                userRoles
              );
            customScopes = scopes;
            sessionStore.setItem("persistedScopes", scopes);
        } catch (err) {
           setError("Unable to fetch role scopes") 
           customScopes = config?.oidc?.scopes;
        }
    }
     return { userRoles, customScopes };
  }

  /**
 * get the list of scopes for the selected groups
 *
 * @param userGroups
 * @param rbacRoles
 */
function extractScopesForUserGroups(
    userGroups: string[],
    rbacRoles: any[]
  ): string[] {
    let scopes = (rbacRoles.find(obj => obj.group === "*") || { scopes: [] })
      .scopes;

    // loop on each AD group
    userGroups.forEach(group => {
      // get the scopes
      const roleObj = rbacRoles.find(role => role.group === group);
      if (roleObj) {
        roleObj.scopes.forEach(scope =>
          !scopes.includes(scope) ? scopes.push(scope) : null
        );
      }
      return group;
    });

    return scopes;
  }
  return {
    fetchUserRolesAndScopes
  }
}

