export const MILESTONE = {
    "scheduleProcess": "Media Request Published",
    "qmAvailable": "QM Availability (CID)",
    "mezzFulfilment": "Mezz Fulfilment",
    "contentAvailabilityMessage": "Encoding Mezz Received (CAM)",
    "transcode": "Derivative Creation",
    "manualQc": "Manual QC",
    "deliveryfullfillment": "Encode Fulfilment",
    // "delivery": "Endpoint Fulfilment",
    "upload": "Upload",
    "receipt": "Receipt",
    "qmCreation": "QM Creation",
    "mamProxyCreation": "MAM Proxy Creation",
    "metaDelivery" : "Endpoint Fulfillment Meta",
    "videoDelivery" : "Endpoint Fulfillment Video",
    "imageDelivery" : "Endpoint Fulfillment Image",
    "endPointReceiptVideo":"Endpoint Receipt Video",
    "endPointReceiptImage":"Endpoint Receipt Image",
    "endPointReceiptMeta":"Endpoint Receipt Meta"
};

export const ESP_MILESTONE_MAPPING = {
    "scheduleprocess":               "scheduleProcess",
    "qmavailable":                   "qmAvailable",
    "mezzfulfilment":                "mezzFulfilment",
    "contentavailabilitymessage":    "contentAvailabilityMessage",
    "transcode":                     "transcode",
    "manualqc":                      "manualQc",
    "manual-qc":                     "manualQc", //review all the constants in this file
    "deliveryfullfillment":          "deliveryfullfillment",
    "upload":                        "upload",
    "receipt":                       "receipt",
    "qmcreation":                    "qmCreation",
    "mamproxycreation":              "mamProxyCreation",
    "metadelivery":                  "metaDelivery",
    "videodelivery":                 "videoDelivery",
    "imagedelivery":                 "imageDelivery",
    "endpointreceiptvideo":          "endPointReceiptVideo",
    "endpointreceiptmeta":           "endPointReceiptMeta",
    "endpointreceiptimage":          "endPointReceiptImage"
};

const STATUS = {
    "completed"                     : "Completed",
    "incomplete"                    : "Incomplete",
    "error"                         : "Error",
    "inProgress"                    : "In Progress",
    "notStarted"                    : "Not Started",
    "hold"                          : "Hold",
    "notApplicable"                 : "Not Applicable",
    "fulfilled"                     : "Fulfilled",
    "cancelled"                     : "Cancelled"
}

export const TEAM_MILESTONE_MAPPING = {
    "scheduleProcess": "mediarequestpublished",
    "qmavailable": "qmavailable",
    "mezzfulfilment": "mezzfulfilment",
    "contentAvailabilityMessage": "encodingmezzreceived",
    "transcode": "derivativecreation",
    "encodeQc": "manualqc",
    "deliveryfullfillment": "encodefulfilment",
    // "delivery": "endpointfulfilment",
    "upload": "upload",
    "receipt": "receipt",
    "manual-qc": "manualqc",
    "qm-creation": "qmcreation",
    "mam-proxy-creation": "mamproxycreation",
    "metadelivery" : "metadelivery",
    "videodelivery" : "videodelivery",
    "imagedelivery" : "imagedelivery",
    "endpointreceiptmeta":"endpointreceiptmeta",
    "endpointreceiptimage":"endpointreceiptimage",
    "endpointreceiptvideo": "endpointreceiptvideo"

};

export const MILESTONE_MAP = new Map([
    [MILESTONE.scheduleProcess, "scheduleProcess"],
    [MILESTONE.qmAvailable, "qmAvailable"],
    [MILESTONE.mezzFulfilment, "mezzFulfilment"],
    [MILESTONE.contentAvailabilityMessage, "contentAvailabilityMessage"],
    [MILESTONE.transcode, "transcode"],
    [MILESTONE.deliveryfullfillment, "deliveryfullfillment"],
    // [MILESTONE.delivery, "delivery"],
    [MILESTONE.upload, "upload"],
    [MILESTONE.receipt,"receipt"],
    [MILESTONE.manualQc, "manual-qc"],
    [MILESTONE.qmCreation, "qm-creation"],
    [MILESTONE.mamProxyCreation, "mam-proxy-creation"],
    [MILESTONE.metaDelivery, "metaDelivery"],
    [MILESTONE.videoDelivery, "videoDelivery"],
    [MILESTONE.imageDelivery, "imageDelivery"],
    [MILESTONE.endPointReceiptVideo, "endPointReceiptVideo"],
    [MILESTONE.endPointReceiptImage, "endPointReceiptImage"],
    [MILESTONE.endPointReceiptMeta, "endPointReceiptMeta"]
]);

// Multiple values for milestone status
export const STATUS_MAP = new Map([
    [STATUS.completed, ["complete", "completed", "backfill","backfilled","skipped"]],
    [STATUS.incomplete, ["not_ready", "warning", "incomplete"]],
    [STATUS.error, ["failure", "error"]],
    [STATUS.inProgress, ["in-progress", "started", "inprogress"]],
    [STATUS.notStarted, ["not-started", "not started", "not_started"]],
    [STATUS.hold, ["hold", "onhold"]],
    [STATUS.notApplicable,["not applicable"]],
    [STATUS.fulfilled, ["fulfilled"]],
    [STATUS.cancelled, ["cancelled"]],
]);

export const ESP_ID = [
    { key: "airingId", name: "Airing Id" },
    { key: "paviId", name: "Pavi Id" },
    { key: "titleId", name: "Title Id" },
    { key: "cid", name: "CID" },
    { key: "ibmsId", name: "IBMS Id" },
    { key: "alephId", name: "Aleph Id" },
    { key: "eidr", name: "EIDR" },
    { key: "Id", name: "CSID" },
    { key: "eidr", name: "EIDR" },
    { key: "scheduledAssetId", name: "Schedule Asset Id" },
    { key: "ssId", name: "SSID" },
    { key: "promoAssetId", name: "Promo Asset ID" },
    { key: "metaId", name: "Meta Id" },
    { key: "all", name: "All" }
];

export const ESP_KEYWORD = [
    { key: "all", name: "all", text: "All" }, // set state to all
    { key: "seriesName", name: "seriesname", text: "Series Name" },
    { key: "offeringTitle", name: "episodename", text: "Offering Title" },
    { key: "seasonName", name: "seasonname", text: "Season Name" },
];

export const ESP_AVOD = [
    { key: "yes", name: "Yes", text: "Yes" },
    { key: "no", name: "No", text: "No" },
    { key: "both", name: "Both", text: "Both" }
];

export const ESP_OFFERING_STATUS = [
  { key: "all", name: "all", text: "Active + Expired" },
  { key: "active", name: "active", text: "Active Offerings" }, 
  { key: "expired", name: "expired", text: "Expired Only" }
];

export const ACQ_KEYWORD = [
    { key: "all", name: "All" }, // set state to all
    { key: "seriesName", name: "Series Name" },
    { key: "titleName", name: "Title Name" }
];

export const ACQ_ID = [
    { key: "workflowId", name: "Workflow Id" },
    { key: "titleId", name: "Title Id" },
    { key: "externalId", name : "External ID" },
    { key: "cid", name: "CID" },
    { key: "all", name: "All"}
];

//name -> value for checkbox, text -> to be displyed on UI
export const ESP_MILESTONE = [
    {key: "scheduleProcess", name: MILESTONE.scheduleProcess, text: MILESTONE.scheduleProcess},
    {key: "qmavailable", name: MILESTONE.qmAvailable, text: MILESTONE.qmAvailable},
    {key: "mezzfulfilment", name: MILESTONE.mezzFulfilment, text: MILESTONE.mezzFulfilment},
    {key: "contentAvailabilityMessage", name: MILESTONE.contentAvailabilityMessage, text: MILESTONE.contentAvailabilityMessage},
    {key: "transcode", name: MILESTONE.transcode, text: MILESTONE.transcode},
    {key: "encodeQc", name: MILESTONE.manualQc, text: MILESTONE.manualQc},
    {key: "deliveryfullfillment", name: MILESTONE.deliveryfullfillment, text: MILESTONE.deliveryfullfillment},
    // {key: "delivery", name: MILESTONE.delivery, text: MILESTONE.delivery}
    {key: "metadelivery", name: MILESTONE.metaDelivery, text: MILESTONE.metaDelivery},
    {key: "videodelivery", name: MILESTONE.videoDelivery, text: MILESTONE.videoDelivery},
    {key: "imagedelivery", name: MILESTONE.imageDelivery, text: MILESTONE.imageDelivery},
    {key: "endpointreceiptvideo", name: MILESTONE.endPointReceiptVideo, text: MILESTONE.endPointReceiptVideo},
    {key: "endpointreceiptmeta", name: MILESTONE.endPointReceiptMeta, text: MILESTONE.endPointReceiptMeta},
    {key: "endpointreceiptimage", name: MILESTONE.endPointReceiptImage, text: MILESTONE.endPointReceiptImage}
];

export const ESP_MILESTONE_STATUS = [
    {key: "complete", name: STATUS.completed, text: STATUS.completed},
    {key: "incomplete", name: STATUS.incomplete, text: STATUS.incomplete},
    {key: "inprogress", name: STATUS.inProgress, text: STATUS.inProgress},
    {key: "error", name: STATUS.error, text: STATUS.error},
    {key: "not_started", name: STATUS.notStarted, text: STATUS.notStarted},
    {key: "hold", name: STATUS.hold, text: STATUS.hold},
    {key: "notApplicable",name:STATUS.notApplicable,text:STATUS.notApplicable},
    {key: "inProgress", name: STATUS.fulfilled, text: STATUS.fulfilled},
    {key: "cancelled", name: STATUS.cancelled, text: STATUS.cancelled}

];

export const ACQ_MILESTONE = [
    {key: "upload", name: MILESTONE.upload, text: MILESTONE.upload},
    {key: "receipt", name: MILESTONE.receipt, text: MILESTONE.receipt},
    {key: "manual-qc", name: MILESTONE.manualQc, text: MILESTONE.manualQc},
    {key: "qm-creation", name: MILESTONE.qmCreation, text: MILESTONE.qmCreation},
    {key: "mam-proxy-creation", name: MILESTONE.mamProxyCreation, text: MILESTONE.mamProxyCreation},
];

export const ACQ_MILESTONE_STATUS = [
    {key: "complete", name: STATUS.completed, text: STATUS.completed},
    {key: "inprogress", name: STATUS.inProgress, text: STATUS.inProgress},
    {key: "error", name: STATUS.error, text: STATUS.error},
    {key: "not_started", name: STATUS.notStarted, text: STATUS.notStarted},
    {key: "hold", name: STATUS.hold, text: STATUS.hold},
    {key: "notApplicable",name:STATUS.notApplicable,text:STATUS.notApplicable}

];

export const ASSET_MILESTONE = [
    {key: "scheduleProcess", name: MILESTONE.scheduleProcess, text: MILESTONE.scheduleProcess},
    {key: "qmavailable", name: MILESTONE.qmAvailable, text: MILESTONE.qmAvailable},
    {key: "mezzfulfilment", name: MILESTONE.mezzFulfilment, text: MILESTONE.mezzFulfilment},
    {key: "contentAvailabilityMessage", name: MILESTONE.contentAvailabilityMessage, text: MILESTONE.contentAvailabilityMessage},
    {key: "transcode",                  name: MILESTONE.transcode,                  text: MILESTONE.transcode},
    {key: "encodeQc",                   name: MILESTONE.manualQc,                   text: MILESTONE.manualQc},
    {key: "deliveryfullfillment",       name: MILESTONE.deliveryfullfillment,       text: MILESTONE.deliveryfullfillment},
];

export const OFFERING = [
    {key: "series", name: "Series"},
    {key: "episode", name: "Episode"},
    {key: "promoextra", name: "PromoExtra"},
    {key: "productextra", name: "ProductExtra"},
    {key: "feature", name: "Feature"},
];

export const ESP_PLATFORMS = [
    {
        key : "ott",
        name : "OTT",
        value : "OTT"
    },
    {
        key : "wmce",
        name : "WMCE",
        value : "WMCE"
    },
    {
        key : "wmvip",
        name : "WMVIP",
        value : "WMViP"
    }
];

export const ESP_CHANNELS = [

    {
        key: "maxGo",
        name: "MAX GO",
        value: "MAX GO",
        type: "ott"
    },
    {
        key: "hboGo",
        name: "HBO GO",
        value: "HBO GO",
        type: "ott"
    },
    {
        key: "hboNow",
        name: "HBO NOW",
        value: "HBO NOW",
        type: "ott"
    },
    {
        key: "hboMax",
        name: "HBO MAX",
        value: "HBO Max",
        type: "ott"
    },
    {
        "key": "wmce-v2l_wm_ride",
        "name": "WMCE-V2L_WM_RIDE",
        "value": "WMCE-V2L_WM_RIDE",
        type: "wmce"
    },
    {
        "key": "wmce_wm_ride",
        "name": "WMCE_WM_RIDE",
        "value": "WMCE_WM_RIDE",
        type: "wmce"
    },
    {
        "key": "wmce_gm_rsi",
        "name": "WMCE_GM_RSI",
        "value": "WMCE_GM_RSI",
        type: "wmce"
    },
    {
        key: "wmce-v2l_gm_rsi",
        name: "WMCE-V2L_GM_RSI",
        value: "WMCE-V2L_GM_RSI",
        type: "wmce"
    },

    {
        key: "wmVip",
        name: "WMViP",
        value: "WMViP",
        type: "wmvip"

    }
];

export const CHECKBOX_VALUE = [
    { key: true, name: "Yes", text: "Yes" },
];

export const WEEK_OFFSET = 2;
export const OFFERING_TYPE = {
  FEATURE           : 'feature',
  EPISODE           : 'episode',
  SERIES            : 'series',
  PRODUCT_EXTRA     : 'productextra',
  PROMO_EXTRA       : 'promoextra',
  STANDALONE        : 'standalone',
  PROMO             : 'promo'
}

export const NAMESPACE = {
  TURNER            : 'turner',
  INTERNATIONAL     : 'international'
}

export const ESP_WEEK_OFFSET = 2;
export const ACQ_WEEK_OFFSET = 1;
export const ASSET_WEEK_OFFSET = 2;
export const DATE_FORMAT = 'MM-dd-yyyy hh:mm a';
export const PAGENATION_RECORD_LIMIT = 20000;
