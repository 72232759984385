import React from 'react'
import ReactTooltip from 'react-tooltip';
import { neutral, steel } from "../templates/palette.json";
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  hasTooltip: {
    color: (theme.palette.type === 'dark' ? neutral[0] : steel[600]) + '!important',
    padding: '5px !important',
    fontSize: '10px !important',
    opacity: '1 !important',
    lineHeight: '10px !important',
    zIndex: '10000 !important'
  }
}));

export default function TooltipContainer() {
  const classes = useStyles();
  const theme = useTheme();
  const background = (theme.palette.type === 'dark' ? steel[600] : steel[100]);

  const overridePosition = ({ left, top }, e, currentTarget) => {
    return {
      top: (!currentTarget.getAttribute('data-showToolTip') &&
        currentTarget.offsetWidth >= currentTarget.scrollWidth) ? -9999 : top,
      left
    }
  }

  return (
    <>
      <ReactTooltip  overridePosition={overridePosition} id='hover-tooltip' backgroundColor={background} className={classes.hasTooltip} place="top" effect="solid" />
      <ReactTooltip id='global-tooltip' backgroundColor={background} className={classes.hasTooltip} place="top" effect="solid" />
    </>
  )
}
