import React, {lazy, useContext, useState, useEffect} from "react";
import DetailsModal from "../components/molecules/DetailsModal";
import Header from "../components/organisms/Header";
import LazyLoader from "../utils/LazyLoader";
import clsx from "clsx";
import { Container, makeStyles } from "@material-ui/core";
import { PageContext } from "../services/PageProvider";
import "./App.css";
import storage from '../utils/storage';

const sessionStore = storage('session');

const AcquireDashboard = lazy(() => import("./AcquireDashboard"));
const EspDashboard = lazy(() => import("./EspDashboard"));
const AssetDashboard = lazy(() => import("./AssetDashboard"));

const useStyles = makeStyles((theme) => ({
  appBarSpacer: {
    minHeight: 48,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -100,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  container: {
    padding: 0
  },
  mainContent: {
    padding: 12
  },
  main: {
    height: '100vh',
    padding: '12px 16px',
    minWidth: 0,
    backgroundImage: `url(./assets/svg/background-bottom.svg), 
    ${theme.palette.type === 'dark' 
    ? 'linear-gradient(180deg, #30404F 0%, #1D272F 100%)' : 'linear-gradient(#EAEAEA, #EAEAEA)'}`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom left",
  },
  backdrop: {
    background: '#FFFFFF',
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1201
  }
}));
document.body.addEventListener("click", (event) => {
  if (
    event.target.getAttribute("class") &&
    !event.target.getAttribute("class").includes("headerInfo")
  ) {
    let headerToolTip = document.querySelectorAll(".hasHeaderTooltip .msHover");
    headerToolTip.forEach((headerItem) =>
      headerItem.setAttribute("style", "display:none")
    );
  }
});

export default function PageHandler() {
  const classes = useStyles();
  const [openMenu, setOpenMenu] = useState(true);
  let {pageState, teamProfile: teams} = useContext(PageContext);

  function render() {
    return (
      <div style={{display: 'flex'}}>
        <Header handleToogleDrawer={handleToggleDrawer} openMenu={openMenu} />
        <main className={
          clsx(classes.main,classes.content, {
          [classes.contentShift]: openMenu,
        })}>
          <div className={classes.appBarSpacer} />
          {/* <div className={classes.mainContent}> */}
            <Container maxWidth="xl" className={classes.container}>
                {renderDashboard()}
            </Container>
          {/* </div> */}
        </main>
      </div>
    );
  }

  function renderDashboard() {
    const detailViewProps = getQueryParams();
    if (detailViewProps.fullScreen) {
      pageState = "detailsView";
    }

    switch (pageState) {
      case "detailsView":
        return (<React.Fragment>
          <div className={classes.backdrop}/>
          <DetailsModal {...detailViewProps} open={true} />
        </React.Fragment>);
      case "acquire":
      case "acquireBasic":
      case "acquireAdvanced":
        return LazyLoader(AcquireDashboard, {currentPage: pageState, teamProfile: teams});
      case "scheduledOfferingsBasic":
      case "scheduledOfferingsAdvanced":
        return LazyLoader(EspDashboard, {currentPage: pageState, teamProfile: teams});
      case "assetViewAdvanced":
      case "assetViewBasic":
        return LazyLoader(AssetDashboard, {currentPage: pageState, teamProfile: teams});
    }
  }
  function getQueryParams() {
    const queryParams = new URLSearchParams(window.location.search);
    let detailViewProps = {
      detailId: queryParams.get('detailId'),
      workflow: queryParams.get('workflow'),
    }
    if(queryParams.get('includeOfferings')) {
      detailViewProps.includeOfferings = queryParams.get('includeOfferings');
    }
    detailViewProps.fullScreen = !!(detailViewProps.detailId && detailViewProps.workflow);

    return detailViewProps;
  }

  function handleToggleDrawer() {
    setOpenMenu(!openMenu);
  }

  return render();
}
