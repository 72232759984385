import _ from "lodash";

class MappingForAcquireModalDetailView  {
    private readonly data: any;
    private readonly submilestones: any;

    constructor(data, submilestones) {
        this.data = data;
        this.submilestones = submilestones;
    }

    get upload(): object {
        return _.get(this, "data.upload");
    }

    get packageReceived(): object {
        return _.get(this, "data.packageReceived");
    }

    get fileTransfer(): object {
        return _.get(this, "data.fileTransfer");
    }

    get receipt(): object {
        return _.get(this, "data.receipt");
    }

    get normalized(): object {
        return _.get(this, "data.normalized");
    }

    get autoQc(): object {
        return _.get(this, "data.autoQc");
    }

    get manualQc(): object {
        return _.get(this, "data.manualQc");
    }

    get tech(): object {
        return _.get(this, "data.tech");
    }

    get qmCreation(): object {
        return _.get(this, "data.qmCreation");
    }

    get mamProxyCreation(): object {
        return _.get(this, "data.mamProxyCreation");
    }

   get tabData(): object {
       return {
           upload: {
               content: _.get(this, "data.upload"),
               submilestones: [
                {
                    title: _.get(this, "submilestones.upload.packageReceived.name", ""),
                    status: _.get(this, "submilestones.upload.packageReceived.value.status"),
                    content: _.get(this, "data.packageReceived"),
                },
                {
                    title: _.get(this, "submilestones.upload.fileTransfer.name", ""),
                    status: _.get(this, "submilestones.upload.fileTransfer.value.status"),
                    content: _.get(this, "data.fileTransfer"),
                },
            ],
           },
           receipt: {
               content: _.get(this, "data.receipt"),
               submilestones: [
                   {
                       title: _.get(this, "submilestones.receipt.normalized.name", ""),
                       status: _.get(this, "submilestones.receipt.normalized.value.status"),
                       content: _.get(this, "data.normalized"),
                   },
                   {
                       title: _.get(this, "submilestones.receipt.autoQc.name", ""),
                       status: _.get(this, "submilestones.receipt.autoQc.value.status"),
                       content: _.get(this, "data.autoQc"),
                   },
               ],
           },
           manualQc: {
               content: [
                   ..._.get(this, "manualQc", []),
                   ..._.get(this, "tech", []),
               ],
           },
           qmCreation: {
               content: _.get(this, "data.qmCreation"),
           },
           mamProxyCreation: {
               content: _.get(this, "data.mamProxyCreation"),
           },
       }
   }
}

export default MappingForAcquireModalDetailView;