import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { DESCRIPTION, TITLE } from '../../constants/helpDocs-constant';
const useStyles = makeStyles((theme) => ({
    backgroundDark:{
        background: '#1B2329',
    },
    backgroundLight:{
        background: '#FFFFFF',
    },
    root: {
        flex: 75,
        borderRadius: '8px',
        color: 'white',
        fontSize: '12',
        padding: '32px'
    },
    textDark: {
        color: '#FAFAFA'
    },
    textLight: {
        color: '#57748E'
    },
    text: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '22px',
        display: 'flex',
        alignItems: 'center',
        
        marginBottom: '32px'
        // marginLeft: '32px',
    },
    linkDark: {
        color: 'white'
    },
    linkLight: {
        color: '#57748E'
    },
    link: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '18px',
        alignItems: 'center',
        textDecorationLine: "underline",
        lineHeight: "25px"
        
    },
    textDark:{
        color: '#FAFAFA'
      },
      textLight:{
        color: '#57748E'
      },
      text: {
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '16px',
          lineHeight: '22px',
          display: 'flex',
          alignItems: 'center'
      },    
    heading: {
        color: 'white',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        alignItems: 'center',
        color: '#FAFAFA',
    },
    groccer: {
        display: 'flex',
        // marginLeft: '32px',
        gap: '16px',
        marginTop:'18px'
    },
    overview: {
        display: 'flex',
        gap: '16px'
    },
    release: {
        display: 'flex',
        gap: '16px'
    },
    guide: {
        display: 'flex',
        // marginLeft: '32px',
        gap: '16px',
        marginTop: '10px'
    },
    heightIcon: {
        height: '128px'
    }
}))

export const DiscoDoc = (props) => {
    const classes = useStyles();
    const {darkStorage}=props;

    return (
        <div className={`${classes.root} ${darkStorage? classes.backgroundDark : classes.backgroundLight}`}>
            <div className={`${classes.text} ${darkStorage? classes.textDark : classes.textLight}`}>
                <p>Here you can find downloadable documents to help you understand DiSCO and stay up-to-date on it. <br />
                    Click on the one you want to get.</p>
            </div>
            <div className={classes.groccer}>
                <div className={classes.overview}>
                    <div>
                        
                        { darkStorage ? (<img className={classes.heightIcon} src= {`/assets/svg/groccer-icon-dark.svg`} />) : (<img className={classes.heightIcon} src= {`/assets/svg/groccery-icon-light.svg`} />) } 
                    </div>
                    <div>
                        <a href="\assets\docs\DISCO Terminology and GlossaryV1.0.0.pdf" className={`${classes.link} ${darkStorage? classes.linkDark : classes.linkLight}`} download="DISCO Terminology and GlossaryV1.0.0.pdf"> {TITLE.DiSCO_GLOSSARY} </a><br />
                        <p className={`${classes.text} ${darkStorage? classes.textDark : classes.textLight}`} >{DESCRIPTION.GLOSSARY}</p>
                    </div>
                </div>
                <div className={classes.overview} style={{marginLeft: "7px"}}>
                    <div>
                        
                        { darkStorage ? (<img className={classes.heightIcon} src={`/assets/svg/overview-Icon-dark.svg`}/>) : (<img className={classes.heightIcon} src={`/assets/svg/overview-Icon-light.svg`}/>) } 
                    </div>
                    <div>
                        <a href="\assets\docs\DiSCO Overview Help Page v1.0.0.pdf" className={`${classes.link} ${darkStorage? classes.linkDark : classes.linkLight}`}  download="DiSCO Overview Help Page v1.0.0.pdf">{TITLE.DiSCO_OVERVIEW}</a><br />
                        <p className={`${classes.text} ${darkStorage? classes.textDark : classes.textLight}`} >{DESCRIPTION.OVERVIEW}</p>
                    </div>
                </div>
            </div>
            <div className={classes.guide}>
                <div className={classes.release}>
                    <div>
                        
                        { darkStorage ? (<img className={classes.heightIcon} src={`/assets/svg/training-guide-icon-dark.svg`} />) : (<img className={classes.heightIcon} src={`/assets/svg/training-guide-icon-light.svg`} />) } 

                    </div>
                    <div>
                        <a href="\assets\docs\DiSCOTrainingv1_6_0.pdf" className={`${classes.link} ${darkStorage? classes.linkDark : classes.linkLight}`}  download="DiSCOTrainingv1_6_0.pdf">{TITLE.DISCO_GUIDE}</a><br />
                        <p className={`${classes.text} ${darkStorage? classes.textDark : classes.textLight}`} >{DESCRIPTION.GUIDE}</p>
                    </div>
                </div>
                <div className={classes.release} style={{marginLeft: "0px"}}>
                    <div>
                        

                        { darkStorage ? (<img className={classes.heightIcon} src={`/assets/svg/release-icon-dark.svg`}/>) : (<img className={classes.heightIcon} src={`/assets/svg/release-icon-light.svg`}/>) } 


                    </div>
                    <div>
                        <a href="\assets\docs\Release-Notes.pdf" className={`${classes.link} ${darkStorage? classes.linkDark : classes.linkLight}`}  download="Release-Notes.pdf">Release Notes</a><br />
                        <p className={`${classes.text} ${darkStorage? classes.textDark : classes.textLight}`} >{DESCRIPTION.RELEASE}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
