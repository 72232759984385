import { useOktaAuth } from '@okta/okta-react';
import React, { useEffect, useState } from 'react';

interface MyContext {
  [name: string]: any
}
export const CurrentUserContext = React.createContext<[MyContext | null]>([null]);
export const CurrentUserProvider = (props) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (authState && authState.isAuthenticated && !Boolean(userInfo)) {
      oktaAuth.getUser().then((info) => setUserInfo(info))
    }
  }, [authState]);

  return (
    <CurrentUserContext.Provider value={[userInfo]}>
      {props.children}
    </CurrentUserContext.Provider>
  );
};