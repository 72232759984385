import _ from "lodash";
import {generalInformationData, getComponents} from "./default";
import {formatBrand, formatCId, checkKeyInOnject} from "../table-utils";
import {formatDateToDateTime, formatDateToHHMMSS, formatDateToMMDDYYYY} from "../date";
import {sortByOrder} from "../array-utils";
import storage from '../storage';
import {toProperCase} from '../string-utils';
import { OFFERING_TYPE, NAMESPACE } from "../../constants/search-constants";

const sessionStore = storage('session');
class MappingForAssetObject {
    private readonly data: any;
    private teamsColumns: any;

    protected constructor(data,teamsColumns) {
        this.data = data;
        this.teamsColumns = teamsColumns;
    }

    get title(): string {
        let title = checkKeyInOnject(this.data, 'preview')
                    ? (_.find(_.get(this, 'data.preview.titles'), {'language': 'en-US'}) || _.first(_.get(this, 'data.preview.titles')) || {})
                    : (_.find(_.get(this, 'data.program.titles'), {'language': 'en-US'}) || _.first(_.get(this, 'data.program.titles')) || {});

        return title.full || title.short || "";
    }

    get titleId(): string {
        let titleId =  _.get(this, 'data.offering.subscriptionFullfillment.title.id', '');
        return titleId || '-';
    }
    get PaviId(): string {
        let paviId = checkKeyInOnject(this.data, 'preview')
            ? _.get(this, 'data.preview.id', '')
            : _.get(this, 'data.offering.scheduledAsset.id', '');
        return paviId || '';
    }

    get scheduledAssetId(): string {
        return _.get(this, 'data.offering.scheduledAsset.id', '') || '';
    }

    get label(): string {
        let namespace = _.get(this, 'data.offering.scheduledAsset.namespace', '').toLowerCase();
        let filter = sessionStore.getItem("filtersMap");
        let filterMap = _.get(filter, 'scheduledOfferings.id.filters', []);
        let label = 'Pavi ID';
        filterMap.forEach(filter => {
            if(filter.namespace && filter.namespace.includes(namespace)) {
                label = filter.name;
                return;
            }
        });
        return label;
    }

    get TitlePaviIdValue(): string {
        let label = this.label;
        let titleId = this.titleId || '';
        let paviId = this.PaviId || '';
        label = label && label.toLowerCase && label.toLowerCase() || label ;
        switch(label) {
            case 'title id':
                return titleId;
            case 'pavi id':
                return paviId;
            default:
                return '';
        }
    }

    get idValue(): string {
        if(_.get(this, 'data.offering.subscriptionFullfillment.title.id', '') ){
            let titleId =  _.get(this, 'data.offering.subscriptionFullfillment.title.id', '');
            return titleId;
        }
        return _.get(this, 'data.offering.scheduledAsset.id', '');
    }

    get id(): string {
        return _.get(this, 'data.offering.scheduleId');
    }

    get detailId(): string {
        return _.get(this, 'data._id', '');
    }

    get offeringType(): string {
        return _.get(this, 'data.offering.type');
    }

    get language(): string {
        return _.get(this, 'data.offering.subscriptionFullfillment.language');
    }

    get adSupported(): string {
        return (_.get(this, 'data.offering.subscriptionFullfillment.adSupported')) ? "True" : "False";
    }

    get brand(): string {
        return checkKeyInOnject(this.data, 'preview')
            ? _.get(this, 'data.preview.brands')
            : _.get(this, 'data.program.brands');
    }

    get series(): string {
        return checkKeyInOnject(this.data, 'preview')
                ? ""
                : _.get(this, 'data.program.seriesName',"");
    }

    get seasonNumber(): string {
        return checkKeyInOnject(this.data, 'preview')
                ? ""
                : _.get(this, 'data.program.seasonNumber',"");
    }

    get season(): string {
        return checkKeyInOnject(this.data, 'preview')
                ? ""
                : _.get(this, 'data.program.seasonName',"");
    }
    get offeringstartDateFormatted(): string{
        const date = formatDateToDateTime(_.get(this,'data.startDate')) || ''
        return date;
    }

    get offeringStartDate(): string {
        const date = formatDateToMMDDYYYY(_.get(this, 'data.startDate')) || '';
        const time = this.offeringStartTime || '';

        return `${date} ${time}`;
    }

    get detailViewStartDate() : string {
        const date = formatDateToMMDDYYYY(_.get(this, 'data.startDate')) || '';
        return `${date}`;
    }

    get offeringEndDate(): string {
        return formatDateToMMDDYYYY(_.get(this, 'data.offering.endDate'));
    }

    get offeringStartTime(): string {
        return formatDateToHHMMSS(_.get(this, 'data.startDate'));
    }

    get mediaRequestPublished(): string {
        return _.get(this, 'data.milestones.scheduleProcess.status');
    }

    get qmAvailable(): string {
        return _.get(this, 'data.milestones.qmAvailable.status');
    }

    get mezzFulfilment(): string {
        return _.get(this, 'data.milestones.mezzFulfilment.status');
    }

    get cam(): string {
        return _.get(this, 'data.milestones.contentAvailabilityMessage.status');
    }

    get derivativeCreation(): string {
        return _.get(this, 'data.milestones.transcode.status');
    }

    get manualQC(): string {
        return _.get(this, 'data.milestones.encodeQc.status');
    }

    get encodeFulfilment(): string {
        return _.get(this, 'data.milestones.deliveryFullfillment.status');
    }

    get endpointFulfilment(): string {
        return _.get(this, 'data.milestones.delivery.status');
    }
    get endpointReceipt(): string {
        return _.get(this, 'data.milestones.endPointReceipt.status');
    }
    get csid(): string{
        return _.get(this, 'data.dsco.mediarequestid');
    }
    get cid(): string {
        return _.get(this.data.program || this.data.preview, "alternateIds", "");
    }
    get ssid(): string {
        return _.get(this, 'data.offering.subscriptionFullfillment.csSourceSystemId', "") || '-';
    }
    get alternateIds(): Array<{}> {
        let additionalIds = [];
        let alternateIds = _.get(this.data.program || this.data.preview, "alternateIds", "");
        // offering.platformReceipt.offeringInfo.relatedIds
        // let platformAckAlternateIds = _.get(((((this.data.offering || {}).platformReceipt) || {}).offeringInfo || {}), "relatedIds", []);
        if(alternateIds && Array.isArray(alternateIds)) {
            additionalIds = alternateIds;
        }
        // to convert deliveryAck related ids into name/value format
        // let platformReceiptIds = []
        // platformAckAlternateIds.forEach(obj =>{
        //     for(let [key,value] of Object.entries(obj)){
        //         let relatedIdObj :  {'name': any, 'value': any} = {'name': '', 'value' : ''};
        //         relatedIdObj.name = key;
        //         relatedIdObj.value = value;
        //         platformReceiptIds.push(relatedIdObj);

        //     }
        // })
        additionalIds = [...additionalIds];
        //Add global Ids to be shown under alternate Ids section of details View
        let globalId = _.get(this.data.dsco || {}, 'globalId' , '') || "";
        if(globalId) {
            additionalIds.push({"name": "GlobalId", "value": globalId});
        }
        return additionalIds;
    }

    // get pavid(): string {
    //     return _.get(this, "data.offering.scheduledAsset.id", "")
    // }

    get episodeInSeason(): string {
        let offeringType = _.get(this, 'data.offering.type', '').toLowerCase();

        return offeringType.includes("episode")
            ? _.get((this.data.program || this.data.preview), 'episodeNumberInSeason', '')
            : "";
    }

    get episodeInSeries(): string {
        let offeringType = _.get(this, 'data.offering.type', '').toLowerCase();

        return offeringType.includes("episode")
            ? _.get((this.data.program || this.data.preview), 'episodeNumberInSeries', '')
            : "";
    }

    get idf(): string {
        return _.get(this, 'data.milestones.delivery.submilestone.video.idfEssenceManifestVersion');
    }

    get receivedAiringData(): string {
        return _.get(this, 'data.milestones.offeringReceipt.status');
    }

    get encodeRequest(): string {
        return  _.get(this, 'data.milestones.contentSubscriptionRequest.status');
    }

    get fetchVersion(): string {
        return _.get(this, 'data.milestones.fetchVersion.status');
    }
    get fetchTitle(): string {
        return _.get(this, 'data.milestones.fetchTitle.status');
    }

    get avodGating (): string {
        return _.get(this, 'data.milestones.avodGating.status');
    }

    get renderMezz(): string {
        return _.get(this, 'data.milestones.renderMezz.status');
    }

    get captionConvert(): string {
        return _.get(this, 'data.milestones.captionConvert.status');
    }

    get transferMedia(): string {
        return _.get(this, 'data.milestones.transferMedia.status');
    }

    get transferMeta (): string {
        return _.get(this, 'data.milestones.transferMeta.status');
    }

    get image(): string {
        return _.get(this, 'data.milestones.delivery.submilestone.images.status');
    }

    get video(): string {
        return _.get(this, 'data.milestones.delivery.submilestone.video.status');
    }

    get meta(): string {
        return _.get(this, 'data.milestones.delivery.submilestone.meta.status');
    }
    get endpointReceiptImage(): string {
        return _.get(this, 'data.milestones.endPointReceiptImage.status');
    }

    get endpointReceiptVideo(): string {
        return _.get(this, 'data.milestones.endPointReceiptVideo.status');
    }

    get endpointReceiptMeta(): string {
        return _.get(this, 'data.milestones.endPointReceiptMeta.status');
    }
    get platform(): string{
        return _.get(this, 'data.platform','');
    }

    get channel(): string{
        return _.get(this, 'data.offering.channel','');
    }

    get totalRunTime() {
        return _.get(this, "data.master.totalRunTime", "");
    }

    get subscriptionFullfillmentCID(): string {
        const namespace = _.get(this, 'data.offering.scheduledAsset.namespace', '').toLowerCase();
        const offeringType = _.get(this, 'data.offering.type', '').toLowerCase();
        let subscriptionFullfillmentCID = '';
        if([NAMESPACE.TURNER, NAMESPACE.INTERNATIONAL].includes(namespace) && ![OFFERING_TYPE.PROMO_EXTRA, OFFERING_TYPE.STANDALONE].includes(offeringType)) {
            subscriptionFullfillmentCID = _.get(this, 'data.offering.subscriptionFullfillment.cid', '') || '-';
        }
        return subscriptionFullfillmentCID;
    }

    get sourceType(): string{
        let convertedPromoTypes;
        let promoType = _.get(this, 'data.program.sourceType', '');
        promoType = promoType ? promoType.toLowerCase() : '';
        switch(promoType) {
            case 'gntx':
                convertedPromoTypes = 'TEXTLESS';
                break;
            case 'gtex':
                convertedPromoTypes = 'TEXTED';
                break;
            default:
                convertedPromoTypes = '';
                break;
        }
        return convertedPromoTypes;
    }

    get fulfillCount() : string{
        return _.get(this, 'data.offering.subscriptionFullfillment.fulfillCount','');
    }

    // get reFulfillmentReason() : string{
    //     return _.get(this, 'data.offering.subscriptionFullfillment.refulfillmentReason', '');
    // }
    get revisionDetails(): string{
        let revision = '';
        let components = [];
        components.push(getComponents(this.data, "contentAvailabilityMessage"));
        components.push(getComponents(this.data, "deliveryFullfillment"));
        components.forEach((component, index) => {
            let revisionTitle = '', val = '';
            _.forIn(component, (value, key) => {
                let rev;
                if(index === 0){
                    rev = _.get(value, 'attr.rev') || _.get(value, '[0].attr.rev');
                    revisionTitle = 'CAM Rev:\n';
                }else{
                    rev = _.get(value, 'revision');
                    revisionTitle = 'DNRS Rev:\n';
                }
                if(rev >= 0) val += "\xa0\xa0" + toProperCase(key) + ": " + rev + "\n";
            });
            val = val ? revisionTitle + val : val;
            revision += val;
        })
        return revision;
    }
    get territory(): string {
        let territories =  _.get(this, 'data.territory','');
        return territories instanceof Array ? territories.filter(ele => ele).join(', ') : 'NA';
    }

    get revisions(): string{
        const rev =  _.get(this, 'data.offering.subscriptionFullfillment.fulfillCount', '');
        return rev || "";
    }

    get values(): object {
        let counter = 1;
        return {
            title: {
                name: "Title",
                value: this.title,
                order: counter++
            },
            offeringType: {
                name: "Offering Type",
                value: this.offeringType,
                order: counter++
            },
            series: {
                name: "Series",
                value: this.series,
                order: counter++
            },
            // should change seasonName to seasonNumber in mapping
            seasonNumber: {
                name: "Season Number",
                value: this.seasonNumber,
                order: counter++
            },
            season: {
                name: "Season",
                value: this.season,
                order: counter++
            },
            episodeInSeries: {
                name: "Episode# in Series",
                value: this.episodeInSeries,
                order: counter++
            },
            episodeInSeason: {
                name: "Episode# in Season",
                value: this.episodeInSeason,
                order: counter++
            },
            adSupported: {
                name: "Ad-Supported",
                value: this.adSupported,
                order: counter++
            },
            language: {
                name: "Language Supported",
                value: this.language,
                order: counter++
            },
            startDate: {
                name: "Need By Date",
                value: this.detailViewStartDate,
                order: counter++
            },
            startTime: {
                name: "Need By Time",
                value: this.offeringStartTime,
                order: counter++
            },
            platform: {
                name: "Platform",
                value: this.platform,
                order: counter++
            },
            channel:{
                name: "Channel",
                value: this.channel,
                order: counter++
            },
            territory:{
                name: "Territory",
                value: this.territory,
                order: counter++
            },
            sourceType:{
                name: "Source Type",
                value: this.sourceType,
                order: counter++
            },
            // brand: {
            //     name: "Brand",
            //     value: this.brand && this.brand !== "NA" && formatBrand(this.brand),
            //     order: counter++
            // },
            // endDate: {
            //     name: "Asset Sunset Date",
            //     value: this.offeringEndDate,
            //     order: counter++
            // },
            // id: {
            //     name: "Airing ID",
            //     value: this.id,
            //     order: counter++
            // },
            scheduledAssetid: {
                name: "Schedule Asset ID",
                value: this.scheduledAssetId,
                order: counter++
            },
            idLabel: {
                name: this.label,
                value: this.TitlePaviIdValue,
                order: counter++
            },
            subscriptionFullfillmentCID: {
                name: "CID",
                value: this.subscriptionFullfillmentCID,
                order: counter++
            },
            ssid: {
                name: "SSID",
                value: this.ssid,
                order: counter++
            },
            csid: {
                name: "CSID",
                value: this.csid,
                order: counter++

            },
            totalRuntime: {
                name: "Total RunTime",
                value: this.totalRunTime,
                order: counter++
            },
            cid: {
                name: "Alternate IDs",
                value: this.alternateIds && formatCId(this.alternateIds),
                order: counter++
            },
            revisionDetails: {
                name: "Revision Details",
                value: this.revisionDetails,
                order: counter++
            },
            iDF: {
                name: "IDF Version",
                value: this.idf,
                order: counter++
            },
            fulfillCount:{
                name : 'Fulfill count',
                value: this.fulfillCount,
                order: counter++
            },
            milestones: {
                mediaRequestPublished: this.mediaRequestPublished,
                qmAvailable: this.qmAvailable,
                mezzFulfilment: this.mezzFulfilment,
                cam: this.cam,
                derivativeCreation: this.derivativeCreation,
                manualQC: this.manualQC,
                encodeFulfilment: this.encodeFulfilment,
                // endpointFulfilment: this.endpointFulfilment,
                // endpointReceipt: this.endpointReceipt,
            },
            submilestone: {
                 mediaRequestPublished: {
                //     receivedAiringData: {
                //         name: "Received Airing Data ESP",
                //         value: this.receivedAiringData,
                //     },
                     encodeRequest: {
                         name: "Encode Request Sent",
                         value: this.encodeRequest,
                     },
                 },
                qmAvailable: {
                    fetchVersion: {
                        name: "Fetch Version",
                        value: this.fetchVersion,
                    },
                    fetchTitle: {
                        name: "Fetch Title",
                        value: this.fetchTitle,
                    }
                },
                mezzFulfilment: {
                    avodGating: {
                        name: "Avod Gating",
                        value: this.avodGating,
                    },
                    renderMezz: {
                        name: "Render Mezz",
                        value: this.renderMezz,
                    },
                    captionConvert: {
                        name: "Caption Convert",
                        value: this.captionConvert,
                    },
                    transferMedia: {
                        name: "Transfer Media",
                        value: this.transferMedia,
                    },
                    transferMeta: {
                        name: "Transfer Meta",
                        value: this.transferMeta,
                    },
                },
                // endpointFulfilment: {
                //     deliveryImages: {
                //         name: "Image Delivery",
                //         value: this.image,
                //     },
                //     deliveryVideos: {
                //         name: "Video Delivery",
                //         value: this.video,
                //     },
                //     deliveryMeta: {
                //         name: "Meta Delivery",
                //         value: this.meta,
                //     },
                // },
                // endpointReceipt: {
                //     deliveryImages: {
                //         name: "Image Delivery",
                //         value: this.endpointReceiptImage,
                //     },
                //     deliveryVideos: {
                //         name: "Video Delivery",
                //         value: this.endpointReceiptVideo,
                //     },
                //     deliveryMeta: {
                //         name: "Meta Delivery",
                //         value: this.endpointReceiptMeta,
                //     },
                // },
            },
            additionalTimestamps: {
                name : "additionalTimestamps",
                value: "Show additional msg details",
                order: counter++
               },
        }
    }

    get modalDataAdvanced(): object {
        const generalInformation = sortByOrder(generalInformationData(this.values));
        // const id = this.detailId.toLowerCase();
        // const offeringType = id.includes('program') ? 'program' : 'preview';
        const offeringType = checkKeyInOnject(this.data, 'preview') ? 'preview' : 'program';
        return {
            title: `${this.title || 'NA'} - ${this.id}`,
            detailId: this.detailId,
            generalInformation,
            process: {
                id: _.get(this, 'data._id', ''),
                offeringType,
                submilestones: _.get(this, 'values.submilestone'),
                milestones: {
                    mediaRequestPublished: this.mediaRequestPublished,
                    qmAvailable: this.qmAvailable,
                    mezzFulfilment: this.mezzFulfilment,
                    cam: this.cam,
                    derivativeCreation: this.derivativeCreation,
                    manualQC: this.manualQC,
                    encodeFulfilment: this.encodeFulfilment,
                    // endpointFulfilment: this.endpointFulfilment,
                    // endpointReceipt: this.endpointReceipt,
                },
            },
        }
    }

    get modalDataBasic(): object {
        const generalInformation = sortByOrder(generalInformationData(this.values));
        const offeringType = checkKeyInOnject(this.data, 'preview') ? 'preview' : 'program';
        const milestones = _.get(this, "values.milestones", {});
        this.teamsColumns = this.teamsColumns ? this.teamsColumns : getTeamsColumns();
        return {
            title: `${this.title || 'NA'} - ${this.id}`,
            detailId: this.detailId,
            generalInformation,
            process: {
                id: _.get(this, 'data._id', ''),
                offeringType,
                submilestones: _.get(this, 'values.submilestone'),
                milestones: filterColumn(this.teamsColumns,milestones)
                // {
                //     mediaRequestPublished: this.mediaRequestPublished,
                //     encodeFulfilment: this.encodeFulfilment,
                //     endpointFulfilment: this.endpointFulfilment,
                // },
            },
        }
    }
}
const filterColumn = (teamsColumns, milestones) => {
    let filteredColumns = {};
    Object.entries(milestones).map(([name, val]) => {
        if (teamsColumns.includes(name.toLowerCase()))
            filteredColumns[name] = val;
    })

    return filteredColumns;
}
const getTeamsColumns = ()=>{
    let columns=[];
	let teamsProfile = sessionStore.getItem("teamProfile")
      teamsProfile.forEach(profile=>{
        columns = Array.from(new Set(columns.concat( profile['scheduledOfferingsBasic']['columns'])))
      })
    return columns;
    }
export default MappingForAssetObject ;

