import React, {FunctionComponent} from "react";
import "./styles/loaderFallback.css";
import {CircularProgress} from "@material-ui/core";

const LoaderFallback: FunctionComponent = ((props) => {
    function render() {
        return (
            <div className="loader-fallback">
                <CircularProgress className="loader-fallback__icon" color="secondary" />
            </div>
        );
    }

    return render();
});

export default LoaderFallback;