import { Grid } from "@material-ui/core";
import React from "react";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 50,
        // height: '210px',
        color: 'white',
        fontSize: '12',
        // marginBottom: '2rem',
        // marginTop: '14px'
    },
    backgroundDark:{
        background: '#1B2329',
    },
    backgroundLight:{
        background: '#FFFFFF',
    },
    serviceNow: {
        borderRadius: '8px',
        
        marginBottom: '32px'
    },
    buttonLight:{
        color: '#FFFFFF',
    },
    buttonDark:{
        color: 'black',
    },
    button: {
        backgroundColor: '#9580FF',
        hover: 'none',
        marginTop: "28px", 
        cursor: "pointer",
        padding: '10px',
        TextDecoder: 'none',
        borderRadius: '10px',
        fontWeight: 'bold',
        border: '0px'
    },
    feedback: {
        borderRadius: '8px',
        color: 'white',
        fontSize: '12',
        marginTop: '2rem',
        overflow: 'none'
    },
    titleDark: {
        color: '#DADADA',
    },
    titleLight: {
        color: '#57748E'
    },
    title: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '24px',
        lineHeight: '20px',
        
    },
    textDark:{
      color: '#FAFAFA'
    },
    textLight:{
      color: '#57748E'
    },
    text: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '22px',
        display: 'flex',
        alignItems: 'center',
        marginTop: "38px"
    },
    help: {
        display: 'flex',
        justifyContent: 'space-between',
        overflow: 'none',
        marginTop: '18px',
        
    },
    subroot: {
        position: 'obsolute',
        overflow: 'hidden', 
        padding: "20px",
    },
    link: {
        color: '#57748E',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        alignItems: 'center',
        textDecoration: 'none'
    }

}))


export const ServiceHelp = (props) => {
    const classes = useStyles();
    const {darkStorage}=props;
    
    return (
        <div className={classes.root}>
            <div className={`${classes.serviceNow} ${darkStorage? classes.backgroundDark : classes.backgroundLight}`}>
                <div className={classes.subroot}>
                    <div className={classes.help}>
                        <div className={`${classes.title} ${darkStorage? classes.titleDark : classes.titleLight}`}>
                            Do you need help?
                        </div>
                        <div>
                       { darkStorage ? (<img src = {`/assets/svg/Tool-icon-dark.svg`}/>) : (<img src = {`/assets/svg/Tool-icon-light.svg`}/>) } 
                        </div>
                    </div>
                    <div className={`${classes.text} ${darkStorage? classes.textDark : classes.textLight}`}>
                        <span>
                        If you find a data discrepancy or want to report something broken, you can create a ticket on the ServiceNOW portal.<br/>
                       <a href="\assets\docs\DISCO Support for Access or Data discrepency Ticket process.pdf" className={classes.link} download="DISCO Support for Access or Data discrepency Ticket process.pdf">Download Support Instructions for DiSCO</a>
                       </span>
                    </div>
                    <div>
                    <a href = "https://warnermedia.service-now.com/tsp" target="_blank">
                         <input type = "button" className={`${classes.button} ${darkStorage? classes.buttonDark : classes.buttonLight}`} value= "Go to SNOW" />
                        </a>
                    </div>
                </div>
            </div>
            <div className={`${classes.feedback} ${darkStorage? classes.backgroundDark : classes.backgroundLight}`}>
                <div className={classes.subroot}>
                    <div className={classes.help}>
                        <div className={`${classes.title} ${darkStorage? classes.titleDark : classes.titleLight}`}>
                            Share your thoughts
                        </div>
                        <div>
                       
                        {darkStorage ? ( <img src={`/assets/svg/icon-smile-dark.svg`} />) : ( <img src={`/assets/svg/icon-smile-light.svg`} />) } 

                        </div> 
                    </div>
                    <div className={`${classes.text} ${darkStorage? classes.textDark : classes.textLight}`}>
                        if you have questions on functionality, have
                        enhancement requests or would like to provide
                        general feedback, please send details using the
                        link below:
                    </div>
                    <div>
                        <a href = "https://app.smartsheet.com/b/form/f3d023e1b3004a2badc9c4b2abb71a75" target="_blank">
                         <input type = "button" className={`${classes.button} ${darkStorage? classes.buttonDark : classes.buttonLight}`} value= "Go to Feedback Portal" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}