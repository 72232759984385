import _ from "lodash";

class MappingForESPModalDetailView {
    private readonly data: any;
    private readonly submilestones: object;

    constructor(data: any, submilestones: {}) {
        this.data = (data.records && data.records[0].eventHistory) ? data.records[0].eventHistory : '';
        this.submilestones= submilestones;
    }

    get receivedAiringData(): object {
       return _.get(this, "data.offeringReceipt");
    }
    get encodeRequest(): object {
       return _.get(this, "data.contentSubscriptionRequest");
    }
    get preProcessingSentMezz(): object {
       return _.get(this, "data.sentMezz");
    }

    get fetchVersion(): object {
        return _.get(this, 'data.fetchVersion');
    }
    get fetchTitle(): object {
        return _.get(this, 'data.fetchTitle');
    }
    get qmAvailable(): object{
        return _.get(this,'data.qmAvailable');
    }

    get renderMezz(): object {
        return _.get(this, 'data.renderMezz');
    }

    get captionConvert(): object {
        return _.get(this, 'data.captionConvert');
    }

    get transferMedia(): object {
        return _.get(this, 'data.transferMedia');
    }

    get transferMeta (): object {
        return _.get(this, 'data.transferMeta');
    }

    get avodGating (): object {
        return _.get(this, 'data.avodGating');
    }

    get cam(): object {
        return _.get(this, 'data.contentAvailabilityMessage');
    }

    get derivativeCreation(): object {
        return _.get(this, 'data.transcode');
    }

    get manualQC(): object {
        return _.get(this, 'data.encodeQc');
    }

    get encodeFulfilment (): object {
        return _.get(this, 'data.deliveryFulfillment');
    }

    get deliveryVideos(): object {
       return _.get(this, "data.videos");
    }
    get deliveryMeta(): object {
       return _.get(this, "data.meta");
    }
    get deliveryImages(): object {
       return _.get(this, "data.images");
    }
    get endpointReceiptVideos(): object {
        return _.get(this, "data.endPointReceiptVideo");
     }
     get endpointReceiptMeta(): object {
        return _.get(this, "data.endPointReceiptMeta");
     }
     get endpointReceiptImages(): object {
        return _.get(this, "data.endPointReceiptImage");
     }
    get tabData(): object {
        let encodeStatus =  _.get(this, "submilestones.mediaRequestPublished.encodeRequest.value");
        return {
            mediaRequestPublished: {
                submilestones: [
                    {
                        title: _.get(this, "submilestones.mediaRequestPublished.receivedAiringData.name"),
                        status: _.get(this, "submilestones.mediaRequestPublished.receivedAiringData.value"),
                        content: this.receivedAiringData,
                    },
                    {
                        title: _.get(this, "submilestones.mediaRequestPublished.encodeRequest.name"),
                        status: _.get(this, "submilestones.mediaRequestPublished.encodeRequest.value"),
                        content:  encodeStatus && encodeStatus.toLowerCase() === 'not applicable' ? [] : this.encodeRequest,
                    },
                ],
            },
            qmAvailable: {
                submilestones: [
                    {
                        title: _.get(this, "submilestones.qmAvailable.fetchVersion.name"),
                        status: _.get(this, "submilestones.qmAvailable.fetchVersion.value"),
                        content: this.fetchVersion,
                    },
                    {
                        title: _.get(this, "submilestones.qmAvailable.fetchTitle.name"),
                        status: _.get(this, "submilestones.qmAvailable.fetchTitle.value"),
                        content: this.fetchTitle,
                    }
                ],
                content: this.qmAvailable
            },
            mezzFulfilment: {
                submilestones: [
                    {
                        title: _.get(this, "submilestones.mezzFulfilment.avodGating.name"),
                        status: _.get(this, "submilestones.mezzFulfilment.avodGating.value"),
                        content: this.avodGating,
                    },
                    {
                        title: _.get(this, "submilestones.mezzFulfilment.renderMezz.name"),
                        status: _.get(this, "submilestones.mezzFulfilment.renderMezz.value"),
                        content: this.renderMezz,
                    },
                    {
                        title: _.get(this, "submilestones.mezzFulfilment.captionConvert.name"),
                        status: _.get(this, "submilestones.mezzFulfilment.captionConvert.value"),
                        content: this.captionConvert,
                    },
                    {
                        title: _.get(this, "submilestones.mezzFulfilment.transferMedia.name"),
                        status: _.get(this, "submilestones.mezzFulfilment.transferMedia.value"),
                        content: this.transferMedia,
                    },
                    {
                        title: _.get(this, "submilestones.mezzFulfilment.transferMeta.name"),
                        status: _.get(this, "submilestones.mezzFulfilment.transferMeta.value"),
                        content: this.transferMeta,
                    },
                ]
            },
            cam: {
                content: this.cam,
                options: {
                    showMoreInfo: {
                        eventType: ["complete"],
                    },
                }
            },
            derivativeCreation: {
                content: this.derivativeCreation,
            },
            manualQC: {
                content: this.manualQC,
            },
            encodeFulfilment: {
                content: this.encodeFulfilment,
            },
            endpointFulfilment: {
                submilestones: [
                    {
                        title: _.get(this, "submilestones.endpointFulfilment.deliveryVideos.name"),
                        status: setVideoStatusColor(_.get(this, "submilestones.endpointFulfilment.deliveryVideos.value")),
                        content: this.deliveryVideos,
                    },
                    {
                        title: _.get(this, "submilestones.endpointFulfilment.deliveryMeta.name"),
                        status: _.get(this, "submilestones.endpointFulfilment.deliveryMeta.value"),
                        content: this.deliveryMeta,
                    },
                    {
                        title: _.get(this, "submilestones.endpointFulfilment.deliveryImages.name"),
                        status: _.get(this, "submilestones.endpointFulfilment.deliveryImages.value"),
                        content: this.deliveryImages,
                    },
                ],
            },
            endpointReceipt: {
                submilestones: [
                    {
                        title: _.get(this, "submilestones.endpointReceipt.deliveryVideos.name"),
                        status: setVideoStatusColor(_.get(this, "submilestones.endpointReceipt.deliveryVideos.value")),
                        content: this.endpointReceiptVideos,
                    },
                    {
                        title: _.get(this, "submilestones.endpointReceipt.deliveryMeta.name"),
                        status: _.get(this, "submilestones.endpointReceipt.deliveryMeta.value"),
                        content: this.endpointReceiptMeta,
                    },
                    {
                        title: _.get(this, "submilestones.endpointReceipt.deliveryImages.name"),
                        status: _.get(this, "submilestones.endpointReceipt.deliveryImages.value"),
                        content: this.endpointReceiptImages,
                    },
                ],
            },
        }
    }
}

function setVideoStatusColor(status) {
    // for (let i = 0; i < collection.length; i++) {
        if(status === 'fulfilled') {
            return 'incomplete';
        }
    // }

    return status;
}

export default MappingForESPModalDetailView;
