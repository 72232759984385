const ACQ_KEYS = {
    TITLE               :   'title',
    WORKFLOW_TYPE       :   'workflowType',
    VIDEO_TYPE          :   'videoType',
    ASPECT_RATIO        :   'aspectRatio',
    FRAME_RATE          :   'frameRate',
    SERIES_NAME         :   'seriesName',
    SEASON_NUMBER       :   'seasonNumber',
    EPISODE_NUMBER      :   'episodeNumber',
    DESCRIPTION         :   'description',
    START_DATE          :   'startDate',
    START_TIME          :   'startTime',
    DISTRIBUTOR         :   'distributor',
    NETWORK_NAME        :   'networkName',
    ASSET_RUNTIME       :   'assetRuntime',
    UPLOAD_TYPE_MMC     :   'uploadTypeMMC',
    ID                  :   'id',
    ALTERNATE_IDS       :   'alternateIds',
    MILESTONES          :   'milestones',
    SUBMILESTONE        :   'submilestone',
    ADDITIONAL_TIMESTAMPS:  'additionalTimestamps'
};

const SCHEDULED_ID_FILTER = {
    all                 : 'all',
    airingId            : "airingId",
    alephId             : "alephId",
    cId                 : "cId",
    Id                  : "Id",
    eidr                : "eidr",
    ibmsId              : "ibmsId",
    paviId              : "paviId",
    metaId              : "metaId",
    promoAssetId        : "promoAssetId",
    scheduledAssetId    : "scheduledAssetId",
    titleId             : "titleId",
    ssId                : "ssId"
}

const ASSET_ID_FILTER = {
    all                 : "all",
    cId                 : "cId",
    metaId              : "metaId",
    Id                  : "Id",
    ssId                : "ssId",
    titleId             : "titleId"
}

const SCHEDULED_FILTERS = {
    offeringType        : 'offeringType',
    brands              : "brands",
    id                  : "id",
    namespace           : "namespace",
    adStatus            : "adStatus",
    reasonCodes         : "reasonCodes",
    fulfillmentType     : "fulfillmentType"
}

const ASSET_FILTERS = {
    keyword             : "keyword",
    id                  : "id",
    adStatus            : "adStatus",
    fulfillmentType     : "fulfillmentType"
}

const REFRESH_STATE = 120000;

module.exports = {
    ACQ_KEYS,
    REFRESH_STATE,
    SCHEDULED_FILTERS,
    ASSET_FILTERS,
    SCHEDULED_ID_FILTER,
    ASSET_ID_FILTER
}