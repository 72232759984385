import React from 'react';
import {Paper } from '@material-ui/core';
import TemplateProvider from './components/templates/TemplateProvider';
import PageProvider from './services/PageProvider';
import PageHandler from "./pages/PageHandler";
import { AutoRefreshProvider } from './components/AutoRefreshContext';

export default function App() {
  return (
    <AutoRefreshProvider>
      <TemplateProvider>
          <Paper>
            <PageProvider>
                <PageHandler />
            </PageProvider>
          </Paper>
      </TemplateProvider>
    </AutoRefreshProvider>
  );
}
