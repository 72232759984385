// import './index.css';
import 'babel-polyfill'; 
import 'whatwg-fetch';
import '@okta/okta-auth-js/polyfill';
import polyfillSupport from "./ie11-polyfill-support"
import * as serviceWorker from './serviceWorker';

import AppWithRouterAccess from './AppWithRouterAccess';
import { ConfigContextProvider } from './components/security/ConfigContext';
import React from 'react';
import ReactDOM from 'react-dom';

polyfillSupport();
ReactDOM.render(
  <React.StrictMode>
    <ConfigContextProvider>
      <AppWithRouterAccess />
    </ConfigContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
