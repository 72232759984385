import { useContext, useState, useEffect } from 'react';
import { CurrentUserContext } from "./SecurityContext";
import { useOktaAuth } from "@okta/okta-react";
import { SecurityContext } from './WrappedSecurity';
// import { ConfigContext } from "./ConfigContext";

const useSecurity = () => {
  const [userInfo] = useContext(CurrentUserContext);
  const [isSecurityLoaded, userScopes] = useContext(SecurityContext);
  const { authState, oktaAuth } = useOktaAuth();
  // const [config, isConfigLoading] = useContext(ConfigContext);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // update "isAuthenticated" when everything is done
  useEffect(() => {
    setIsAuthenticated(authState.isAuthenticated && isSecurityLoaded)
  }, [isSecurityLoaded, authState])

  return {
    userInfo,
    authService:oktaAuth,
    isAuthenticated,
    userScopes,
    isPending: authState.isPending,
    // code needs to "refetch" token because it might have changed in authState
    getAccessToken: oktaAuth.getAccessToken(),
    idToken: authState.idToken
    // config: config
  }
};

export default useSecurity;