import { useState } from 'react';
import storage from './storage';
import useAxios from './axiosImpl';
import * as apiMap from './api.json';
import {DEFAULT_TEAM} from "../constants/constants"
const sessionStore = storage('session');

export function setTeamProfile(data: any) {
  sessionStore.setItem("teamProfile", data);
}

export function getTeamProfile() {
  return  sessionStore.getItem("teamProfile") || [];
}

export function getUserRole() {
  const profile = getTeamProfile();
  if(profile && Array.isArray(profile) && profile.length > 0) {
    return profile[0].key;
  }
  return "";
}


export function useTeamsProfile() {

  const [error, setError] = useState(null);
  const { get } = useAxios();

  let teamProfile = sessionStore.getItem("teamProfile");

  async function fetchTeamsProfile(scopes: Array<string>) {
    const payload = {};
    if(scopes && scopes.length > 0){
      payload['scopes']= JSON.stringify({"name": scopes});
    }
    if (!teamProfile) {
      try {
        const teams = await get({ url: apiMap.teams, params: payload});
        teamProfile = teams || DEFAULT_TEAM;
        setTeamProfile(teamProfile);
      } catch (err) {
        setError("Unable to fetch teams data");
        return;
      }
    }
     return teamProfile;
  }
  return {
    fetchTeamsProfile
  }
}

