import { AppBar, Box, Typography, LinearProgress, Tab as MuiTab, Toolbar as MuiToolbar, TableContainer, Tabs, makeStyles, withStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { DetailsModalContext } from "../../services/DetailsModalProvider";
import DetailsModalMoreInfo from "../molecules/DetailsModalMoreInfo";
import DetailsTable from "../molecules/DetailsTable";
import { StatusIcon } from "../atoms/Icons";
import TabPanel from "../atoms/TabPanel";
import _ from "lodash";
import useAxios from "../../utils/axiosImpl";
import useSecurity from "../security/useSecurity"
import MappingForAssetModalDetailView from "../../utils/data-map/mappingForAssetModalDetailView";
import { neutral, steel } from "../templates/palette.json";
import { assetConst } from "../../constants/constants";
import { setDefault } from "../../utils/data-map/default";
import { REFRESH_STATE } from '../../constants/data-contant';

const useStyles = makeStyles((theme) => ({
    tabPanel: {
        background: theme.palette.type === 'dark' ? steel[900] : neutral[0],
        flex: 1,
        height: "calc(100% - 147px)",
        overflow: "auto"
    },
    indicator: {
        backgroundColor: theme.palette.type === 'dark' ? steel[500] : neutral[800]
    },
    subTableContainer: {
        marginBottom: '20px',
        maxHeight: 'fit-content',
        overflow: 'hidden',
    },
    tabSpace: {
        marginBottom: 0
    }
}));

const Tab = withStyles((theme) => ({
    root: {
        alignItems: 'flex-start',
        background: theme.palette.type === 'dark' ? steel[800] : neutral[200],
        border: theme.palette.type === 'dark' ? `2px solid ${steel[900]}` : `2px solid ${neutral[0]}`,
        borderTop: 'none',
        color: theme.palette.type === 'dark' ? neutral[100] : steel[600],
        display: 'flex',
        flex: 1,
        fontFamily: `"Roboto", "Noto", sans-serif`,
        lineHeight: '1.5',
        minWidth: 'auto',
        opacity: 1,
        padding: '4px 12px',
    },
    wrapper: {
        display: 'flex'
    }
}))(MuiTab);

const Toolbar = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.type === 'dark' ? steel[700] : neutral[800],
        borderRadius: '4px 4px 0 0',
        color: theme.palette.type === 'dark' ? steel[100] : steel[600],
        display: "flex",
        fontFamily: `"Roboto", "Noto", sans-serif`,
        fontSize: "12px",
        minHeight: "28px",
        // padding: 10,
        paddingLeft: 0,
    },
}))(MuiToolbar);

export default function ProcessDetailsModalEsp(props) {
    const { moreInfoState, resetMoreInfoState } = useContext(DetailsModalContext);
    const { fullScreen, refreshState } = props;
    const classes = useStyles();
    const [selectedTabState, setSelectedTabState] = useState(0);
    const [dataState, setDataState] = useState({});
    const [loadingState, setLoadingState] = useState(true);
    const [errorState, setErrorState] = useState("");
    const { get } = useAxios();
    const { isAuthenticated } = useSecurity();

    useEffect(() => {
        setLoadingState(true);
        setErrorState("");

        getDetailsViewData();

        if (fullScreen) {
            const interval = setInterval(() => {
                getDetailsViewData();
            }, REFRESH_STATE);

            return () => clearInterval(interval);
        }

    }, [refreshState]);

    function render() {
        return (
            <>
                <AppBar elevation={0} position="relative">
                    <Tabs
                        value={selectedTabState}
                        onChange={handleTabChange}
                        aria-label="Process tab selection"
                        classes={{ indicator: classes.indicator }}
                    >
                        {renderTabs()}
                    </Tabs>
                    {loadingState ? <LinearProgress color="secondary" /> : null}
                </AppBar>
                <Box>{!errorState ? renderBox : errorState}</Box>
            </>
        );
    }

    function renderBox() {
        return (
            <>
                {!moreInfoState.show && !loadingState ? renderPanels() : null}
                {moreInfoState.show ? (
                    <DetailsModalMoreInfo
                        title={moreInfoState.title}
                        status={moreInfoState.status}
                        eventId={moreInfoState.eventId}
                    />
                ) : null}
            </>
        );
    }

    function renderTabs() {
        const entries = Object.entries(props.milestones);

        return entries.map(([key, status]) => (
            <Tab
                label={<span className={classes.tabSpace}>{assetConst.tableHeader[key]}</span>}
                key={key}
                icon={<StatusIcon status={status} />}
                index={key}
            />
        ));
    }

    function renderPanels() {
        const map = new MappingForAssetModalDetailView(dataState, props.submilestones);
        const tabData = setDefault(map.tabData);
        const keys = Object.keys(props.milestones);

        return keys.map((key, index) => {
            const item = tabData[key];
            let content;

            if (hasNoData(item)) {
                content = <Typography variant="body2" component="p">No data to display for selected milestone</Typography>;
            } else {
                content = (
                    <>
                        {item.content ? (
                            <DetailsTable title={assetConst.tableHeader[key]} content={item.content} options={item.options} submil={false} />
                        ) : null}
                        {item.submilestones
                            ? renderSubmilestones(item.submilestones)
                            : null}
                    </>
                );
            }

            return (
                <TabPanel
                    className={classes.tabPanel}
                    value={selectedTabState}
                    key={key}
                    index={index}
                >
                    {content}
                </TabPanel>
            );
        });
    }

    function renderSubmilestones(submilestones) {
        return submilestones.map((submilestone) => {
            if (_.isEmpty(submilestone) || submilestone.status === 'NA') return null;

            return (
                <TableContainer key={`${submilestone.title}-table`} className={classes.subTableContainer}>
                    <Toolbar variant="dense">
                        <StatusIcon status={submilestone.status} />
                        {submilestone.title}
                    </Toolbar>
                    {(hasNoData(submilestone)) ? <Typography variant="body2" component="p">No data to display for selected milestone</Typography>
                        :
                        <DetailsTable
                            title={submilestone.title}
                            content={submilestone.content}
                            options={submilestone.options}
                            submil={true}
                        />}
                </TableContainer>
            );
        });
    }

    const getDetailsViewData = () => {
        get({
            url: '/v1/assetViewEvents/details',
            params: {
                id: props.id,
                offeringtype: props.offeringType
            }
        })
        .then((response) => response && setDataState(response))
        .catch((error) => error && setErrorState(error.message))
        .finally(() => setLoadingState(false));
    }

    function handleTabChange(event, newValue) {
        setSelectedTabState(newValue);
        resetMoreInfoState();
    }

    function hasNoData(item) {
        if (_.isEmpty(item) || item === "NA") {
            return true;
        } else if (!_.isObject(item.submilestones) && (_.isEmpty(item.content) || item.content === "NA")) {
            return true;
        }

        return false;
    }

    return render();
}
