import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStylesFacebook = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative"
    },
    bottom: {
      color: theme.palette.grey[theme.palette.type === "dark" ? 700 : 200]
    },
    top: {
      color: "#1a90ff",
      animationDuration: "550ms",
      position: "absolute",
      left: 0
    },
    circle: {
      strokeLinecap: "round"
    }
  })
);

export default function ProgressBar(props) {
    const classes = useStylesFacebook();
    return (
        <div className={classes.root}>
          <CircularProgress
            variant="indeterminate"
            disableShrink
            className={classes.top}
            classes={{
              circle: classes.circle
            }}
            size={40}
            thickness={4}
          ></CircularProgress>
        </div>
      );
}