import React, { useState, useEffect } from "react";
import { espConst, acquireConst, assetConst } from "../constants/constants";
import storage from "../utils/storage";
import _ from "lodash";
import usePreference from "../components/preference/usePreference";
import { useTeamsProfile } from "../utils/teams";
import { useUserRoles } from "../utils/userRoles";
import apiMap from "../utils/api";
import useAxios from "../utils/axiosImpl";
import ProgressBar from "../utils/style-utils";
import navItems from "../config/navItems";
import DialogModal from "../components/molecules/DialogModal";
import { getLocationQueries, clearUrlQuery } from "../utils/query-utils";
import { setFiltersMap } from "../utils/filter-utils";

let accessToken = {};

export const PageContext = React.createContext(null);

const sessionStore = storage("session");
// sessionStore.setItem("schOffColumn", espConst.showColumn);
// sessionStore.setItem("acqColumn", acquireConst.showColumn);
// sessionStore.setItem("assetViewColumns", assetConst.showColumn);

const modalInitialState = {
  open: false,
  message: "",
};

export default function PageProvider({ children }) {
  const DEFAULT_INITIAL_PAGE = "scheduledOfferingsAdvanced";
  const [pageState, setPageState] = useState(DEFAULT_INITIAL_PAGE);
  const [filterState, setFilterState] = useState({ sample: "val" });
  const [teamProfile, setTeamProfile] = useState({});
  const [holdRequest, setHoldRequest] = useState(false);
  const [isTeamsLoading, setIsTeamsLoading] = useState(true);
  const [isUserRolesLoading, setIsUserRolesLoading] = useState(true);
  const [isAppConfigLoading, setIsAppConfigLoading] = useState(true);
  const [isPreferencesLoading, setIsPreferencesLoading] = useState(true);
  const [isSharedViewLoaded, setSharedView] = useState(false);
  const [additionalTimestamps, setAdditionalTimestamps] = useState(false);
  const [isDeepLink, setDeepLink] = useState(false);
  const { fetchUserRolesAndScopes } = useUserRoles();
  const { getPreferences } = usePreference();
  const { fetchTeamsProfile } = useTeamsProfile();
  const { get } = useAxios();
  const [modalState, setModalState] = useState(modalInitialState);
  const [pageWithQuery, setPageWithQuery] = useState(false);
  const queryParams = getLocationQueries();
  const ACQUIREOPS = "acquire";

  const [totalCount, setTotalCount] = useState(0);
  const [eventParams, setEventParams] = useState(null);
  const [resetGridState, setResetGridState] = useState(null);

  const [gridState, setGridState] = useState({gridApi:null,columnApi:null});
  useEffect(() => {
    const getUIConfig = async () => {
      try {
        getAppConfig();
        const userScopes = await getUserRolesAndScopes();
        await getTeamsProfile(userScopes);
        await fetchPreferences();
      } catch (ex) {}
    };
    getUIConfig();
  }, []);

  useEffect(updatePageState, [isTeamsLoading]);

  function renderContentWithDialog(renderFunction) {
    return (
      <React.Fragment>
        {renderFunction()}
        <DialogModal
          header="Error"
          open={modalState.open}
          handleCloseDialog={handleCloseModal}
          error={true}
        >
          {modalState.message}
        </DialogModal>
      </React.Fragment>
    );
  }

  async function getTeamsProfile(userScopes) {
    try {
      const team = await fetchTeamsProfile(userScopes || [""]);
      setTeamProfile(team);
      setIsTeamsLoading(false);
    } catch (ex) {
      setIsTeamsLoading(false);
    }
  }

  async function fetchPreferences() {
    try {
      console.log("fetchPreferences");
      await getPreferences();
      console.log("after fetchPreferences");
      setIsPreferencesLoading(false);
    } catch (ex) {
      setIsPreferencesLoading(false);
    }
  }

  async function getUserRolesAndScopes() {
    try {
      const { userRoles, customScopes } = await fetchUserRolesAndScopes();
      setIsUserRolesLoading(false);
      return customScopes;
    } catch (ex) {
      setIsUserRolesLoading(false);
    }
  }

  async function getAppConfig() {
    let columnConfig = sessionStore.getItem("columnConfig");
    let viewsConfig = sessionStore.getItem("viewsConfig");
    let filtersMap = sessionStore.getItem("filtersMap");

    if (!viewsConfig || !columnConfig || !filtersMap) {
      try {
        let appconfig = await get({ url: apiMap.filtersMap });
        console.log("getAppConfig", appconfig);
        viewsConfig = appconfig.viewsConfig;
        columnConfig = appconfig.columnConfig;
        filtersMap = appconfig.filterMap;
        if (
          !(viewsConfig instanceof Object) ||
          !(columnConfig instanceof Object) ||
          !(filtersMap instanceof Object)
        ) {
          setIsAppConfigLoading(false);
          return;
        }
        columnConfig && setColumnConfig(columnConfig);
        viewsConfig && setViewsConfig(viewsConfig);
        filtersMap && setFiltersMap(filtersMap);
        setIsAppConfigLoading(false);
      } catch (err) {
        setIsAppConfigLoading(false);
      }
      return;
    }
    setIsAppConfigLoading(false);
  }

  function setColumnConfig(data) {
    sessionStore.setItem("columnConfig", data);
  }

  function setViewsConfig(data) {
    sessionStore.setItem("viewsConfig", data);
  }

  function getDefaultView(teamProfile) {
    let defaultViewArray = [];
    teamProfile = Array.isArray(teamProfile) ? teamProfile : [teamProfile];
    teamProfile.forEach((team) => {
      defaultViewArray.push(team.defaultView);
    });
    const appConfig = sessionStore.getItem("appConfig");
    const viewsConfig = _.get(appConfig, "viewsConfig", []);

    if (defaultViewArray.length === 1) return defaultViewArray[0];
    return defaultViewArray.reduce((prev, current) => {
      let currentPriority = viewsConfig.forEach((view) => {
        return view.key === current ? view.priority : 2e1029;
      });
      let prevPriority = viewsConfig.forEach((view) => {
        return view.key === prev ? view.priority : 2e1029;
      });
      return currentPriority < prevPriority ? current : prev;
    });
  }

  function updatePageState() {
    if (!isTeamsLoading) {
      try {
        if (queryParams.view && queryParams.searchId) {
          setPageWithQuery(true);
          updatePageStateWithQuery();
        } else if (queryParams.csid) {
          updatePageStateWithQuery();
        } else if (queryParams.hasOwnProperty("titleId")) {
          updatePageStateWithQuery();
        } else {
          updatePageStateWithDefaultView();
        }
      } catch (error) {
        updatePageStateWithDefaultView();
        setHoldRequest(true);
        setModalState({
          open: true,
          message: error.message,
        });
      }
    }
  }

  function updatePageStateWithQuery() {
    try {
      setPageState(queryParams.view);
      validateUrlParameters();
      queryParams.searchId ? setSharedView(true) : setDeepLink(true);
    } catch (error) {
      updatePageStateWithDefaultView();
      setHoldRequest(true);
      setPageWithQuery(false);
      setModalState({
        open: true,
        message: error.message,
      });
    }
  }
  function updatePageStateWithDefaultView() {
    const defaultView = getDefaultView(teamProfile) || DEFAULT_INITIAL_PAGE;
    setPageState(defaultView);
  }

  function handleCloseModal() {
    setHoldRequest(false);
    setModalState(function updateClose(current) {
      return {
        ...current,
        open: false,
      };
    });
    clearUrlQuery();
  }

  function checkViewPermissions(view) {
    let isAllowed = false;
    const profilesCopy = Array.isArray(teamProfile)
      ? [...teamProfile]
      : [teamProfile];
    profilesCopy.forEach((profile) => {
      isAllowed = isAllowed || _.get(profile, `${view}.isVisible`, false);
    });

    return isAllowed;
  }

  function checkViewForAcrossTeam(view) {
    let isOpsUser = false;
    const profilesCopy = Array.isArray(teamProfile)
      ? [...teamProfile]
      : [teamProfile];
    profilesCopy.forEach((profile) => {
      isOpsUser =
        isOpsUser ||
        (!checkViewPermissions(view) && _.get(profile, "name", "") === "ops");
      if (!checkViewPermissions(view) && isOpsUser) {
        view === "acquireBasic"
          ? setPageState(ACQUIREOPS)
          : setPageState(DEFAULT_INITIAL_PAGE);
      }
    });
    return isOpsUser;
  }

  function validateUrlParameters() {
    const validViews = Object.keys(navItems);
    const validUUIDRegex = new RegExp(
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/
    );
    const { view, searchId, csid, titleId } = queryParams;

    if (!validViews.includes(view)) {
      throw new Error(`The Requested View does not exist - ${view}`);
    }
    if (!checkViewPermissions(view) && !checkViewForAcrossTeam(view)) {
      throw new Error(`Requested View is unavailable- ${view}`);
    }
    if (!searchId && !csid && (!titleId || titleId == "null")) {
      throw new Error(`Id is missing/invalid`);
    }
  }

  return renderContentWithDialog(function render() {
    if (
      isTeamsLoading ||
      isAppConfigLoading ||
      isUserRolesLoading ||
      isPreferencesLoading
    ) {
      return <ProgressBar />;
    } else {
      return (
        <PageContext.Provider
          value={{
            pageState,
            setPageState,
            filterState,
            setFilterState,
            teamProfile,
            holdRequest,
            setHoldRequest,
            queryParams,
            isSharedViewLoaded,
            setSharedView,
            pageWithQuery,
            setPageWithQuery,
            isDeepLink,
            setDeepLink,
            additionalTimestamps,
            setAdditionalTimestamps,
            totalCount,
            setTotalCount,
            eventParams,
            setEventParams,
            resetGridState,
            setResetGridState,
            gridState,
            setGridState
          }}
          holdRequest={modalState.holdRequest}
        >
          {children}
        </PageContext.Provider>
      );
    }
  });
}
