/**
 * - This file is for extending string prototype instance for code reusability
 * - declare global requires an empty export {} at the bottom, and do not export any thing 
 * 
 */
declare global {
    interface String {
        /**
         * truncate the string with custom characters or symbols
         * @param maxLength - maximum length of characters to truncate string
         * @param truncateString - character sequence to truncate the string
         */
        truncate(maxLength: number, truncateString?: string): string;

        /**
         * this function will make single line text into multi line
         * while condition is taking dynamic length of the string 
         * @param noOfLines - no of times that new line <br /> is being added to the source string
         */
        multiline(noOfLines: number): string;
    }
}

String.prototype.truncate = function (maxLength: number, truncateString: string): string {
    truncateString = truncateString || '...';
    return this.length > maxLength ? this.toString() : this.slice(0, maxLength) + truncateString;
}

String.prototype.multiline = function(noOfLines: number): string {
    const strToBeInserted = '<br />';
    const newStrLen = strToBeInserted.length;
    let str: string = this.toString();
    let pos: number = Math.ceil(str.length / noOfLines) + newStrLen;

    while(pos < str.length) {
        str  = str.replace(new RegExp(`^(.{${pos}})(.)`), `$1${strToBeInserted}$2`);
        pos = pos + Math.ceil(str.length / noOfLines) + newStrLen;
    }

    return str;
}

export { };