// STORAGE.JS - simple wrapper over local/session storage with in memory fallback
const localCache = {};
const sessionCache = {};

function getMemoryStore(type) {
  var _cache = (type === 'session') ? sessionCache : localCache;
  return {
    getItem(key) {
      return _cache[key];
    },
    setItem(key, value) {
      _cache[key] = value;
    },
    removeItem(key) {
      return delete _cache[key];
    },
    clear() {
      _cache = {};
    }
  };
}

(function (root, factory) {
  module.exports = function (type) {
    let store = (type === 'session') ? root.sessionStorage : root.localStorage;

    // fallback to in memory store
    store = store || getMemoryStore(type);

    return factory(root, store, {});
  }
})(typeof window !== 'undefined' ? window : global, function (root, store, Storage) {

  Storage.getItem = function (key) {
    let item = store.getItem(key);
    try {
      item = JSON.parse(item);
    } catch (e) {
      console.log("error while parsing to JSON object", e);
    }
    return item;
  };

  Storage.setItem = function (key, value) {
    let item = value;
    if (typeof value != "string") {
      item = JSON.stringify(item)
    }
    return store.setItem(key, item);
  };

  Storage.removeItem = function (key) {
    return store.removeItem(key);
  };

  Storage.clear = function () {
    return store.clear();
  };

  return Storage;

})
