import {
  AppBar,
  Button,
  Container,
  Divider,
  LinearProgress, makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React, { FunctionComponent, useContext, useEffect, useState } from "react";

import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import { DetailsModalContext } from "../../services/DetailsModalProvider";
import { StatusIcon } from "../atoms/Icons";
import _ from "lodash";
import useAxios from "../../utils/axiosImpl";
import useSecurity from "../security/useSecurity"
import { steel, neutral } from '../templates/palette.json';
import format from "xml-formatter";

interface DetailsModalMoreInfoProps {
  eventId: string,
  status: string,
  title: string
}

const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.palette.type === 'dark' ? steel[200] : steel[600],
    flex: 1,
    fontSize: '0.75rem',
    overflow: "auto",
    padding: "12px 24px",
    position: "relative"
  },
  backButton: {
    color: theme.palette.type === 'dark' ? steel[100] : steel[600],
    fontSize: 10
  },
  camStatus: {
    fontSize: 10,
    lineHeight: '14px'
  },
  camHeader: {
    minHeight: 18,
    background: theme.palette.type === 'dark' ? steel[700] : neutral[200]
  },
  camDetails: {
    background: theme.palette.type === 'dark' ?  steel[800] : neutral[100],
    color: theme.palette.type === 'dark' ? steel[200] : steel[600],
    padding: 16,
    borderLeft: theme.palette.type === 'dark' ? `4px solid ${steel[500]}` : `4px solid ${steel[400]}`
  },
}));

const DetailsModalMoreInfo: FunctionComponent<DetailsModalMoreInfoProps> = (props  => {
  const classes = useStyles();
  const { resetMoreInfoState } = useContext(DetailsModalContext);
  let [errorState, setErrorState] = useState("");
  let [dataState, setDataState] = useState();
  let [loadingState, setLoadingState] = useState(false);
  const { get } = useAxios();
  const { isAuthenticated } = useSecurity();
  let formatedData;

  useEffect(() => {
    setErrorState("");
    setLoadingState(true);

    if (!props.eventId) {
      return setErrorState(`Event ID ${props.eventId} not found`);
    }

    get({
        url: '/v1/camInfo/details',
        params: {
          eventid: props.eventId
        }
      })
      .then((response) => setDataState(_.get(response, "0.msg")))
      .catch((error) => setErrorState(error.message))
      .finally(() => setLoadingState(false));
  }, [props, isAuthenticated]);

  function handleMoreInfoState() {
    resetMoreInfoState();
  }
  
  if(dataState !== undefined && props.status !== 'error') {
    formatedData = format(dataState);
  } else {
    formatedData = dataState
  }

  return (
    <>
      <AppBar position="relative">
        <Toolbar variant="dense" className={classes.camHeader}>
          <Button onClick={handleMoreInfoState} startIcon={<ArrowBackIcon />} className={classes.backButton}>
            Go Back
          </Button>
          <Divider orientation="vertical" flexItem />
          <StatusIcon status={props.status} />
          <Typography className={classes.camStatus}>{props.title}</Typography>
        </Toolbar>
        {loadingState ? <LinearProgress color="secondary" /> : null}
      </AppBar>
      <Container className={classes.container}>
        {!errorState ? <pre className={classes.camDetails}>{formatedData}</pre> : errorState}
      </Container>
    </>
  );
}
);

export default DetailsModalMoreInfo;
