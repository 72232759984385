import {
  addWeeks,
  subWeeks,
  startOfWeek,
  endOfWeek,
  differenceInDays,
  isValid,
  isAfter,
  isSameDay,
  isBefore,
  startOfDay
} from "date-fns";
import { format, formatToTimeZone, convertToLocalTime, convertToTimeZone } from "date-fns-timezone";

const timeZone = "America/New_York";
const defaultDateFormat = "MM/DD/YYYY hh:mm A";
const weekStartsOn = 1;  // Weeks starts on Monday

const ONE_WEEK_MS = 1000 * 60 * 60 * 24 * 7;

function getBucketKey(dateParam) {
  if (!dateParam) return null;

  let date = dateParam;

  if (typeof date === "string") {
    date = parseDate(date);
  }

  let fullYear = date.getFullYear();
  let st = new Date(fullYear, 0, 1);
  let id = Math.floor((date.getTime() - st.getTime()) / ONE_WEEK_MS) + 1;

  return `${id < 10 ? "0" + id : id}`;
}

function parseDate(datestr) {
  let rs = datestr.match(/(\d{4})(\d{0,2})(\d{0,2})/i);
  if (rs && rs.length > 2)
    return new Date(rs[1], rs[2] ? parseInt(rs[2]) - 1 : null, rs[3]);
  return new Date(); // todays date
}

export function getWeekDetail(weekOffset = 0, frmt = "MM/DD") {
  const start = startOfWeek(addWeeks(new Date(), weekOffset));
  const end = endOfWeek(addWeeks(new Date(), weekOffset));
  const formatType = "YYYYMMDD";

  return {
    start: start,
    end: end,
    startYYYMMDD: format(start, formatType),
    endYYYMMDD: format(end, formatType),
    label: " (" + format(start, frmt) + " to " + format(end, frmt) + ")",
    number: parseInt(getBucketKey(format(start, formatType))),
    offset: weekOffset,
  };
}

export function getDateRange(weekOffset = 0, format = "YYYYMMDD") {
  const fromDate = startOfWeek(subWeeks(new Date(), weekOffset));
  const toDate = endOfWeek(addWeeks(new Date(), weekOffset));

  return {
    fromDate: format(fromDate, format),
    toDate: format(toDate, format),
  };
}

export function DifferenceOfWeeks(fromDate, UntilDate) {
  const fDate = new Date(fromDate);
  const uDate = new Date(UntilDate);

  return differenceInDays(uDate, fDate);
}

export function formatDateToDateTime(date) {
  if (!isValid(new Date(date))) {
    return "NA";
  }

  return formatToTimeZone(new Date(date), "MM/DD/YYYY hh:mm A", {
    timeZone: "America/New_York",
  }).slice(0, -1);
}

export function formatDateForView(date) {
  if (!isValid(new Date(date))) {
    return "NA";
  }

  return formatToTimeZone(new Date(date), "MM/DD/YYYY hh:mm:ss A", {
    timeZone: "America/New_York",
  });
}

export function formatDateToMMDDYYYY(date) {
  if (!isValid(new Date(date))) {
    return null;
  }

  return formatToTimeZone(new Date(date), "MM/DD/YYYY", {
    timeZone: "America/New_York",
  });
}

export function formatDateToHHMMSS(date) {
  if (!isValid(new Date(date))) {
    return null;
  }

  return formatToTimeZone(new Date(date), "hh:mm:ss A", {
    timeZone: "America/New_York",
  });
}

export function formatDateToYYYYMMDD(date) {
  if (!isValid(new Date(date))) {
    return null;
  }

  return format(new Date(date), "YYYYMMDD", { timeZone: "Etc/UTC" });
}

export function isBetweenDates(date, from, to) {
  const dDate = new Date(date);
  const dFrom = new Date(from);
  const dTo = new Date(to);

  if (!isValid(dDate) || !isValid(dFrom) || !isValid(dTo)) {
    return true;
  }

  if (isAfter(dFrom, dTo)) {
    return true;
  }

  return (
    (isSameDay(dDate, dFrom) || isAfter(dDate, dFrom)) &&
    (isSameDay(dDate, dTo) || isBefore(dDate, dTo))
  );
}

export const getStartOfDay = () => startOfDay(new Date());

export const getStartOfWeek = (weekOffset = 0) => startOfWeek(subWeeks(new Date(), weekOffset), { weekStartsOn });

export const getEndOfWeek = (weekOffset = 0) => endOfWeek(addWeeks(new Date(), weekOffset), { weekStartsOn });

export function getTzDate(date) {
  if (!isValid(new Date(date))) {
    return "NA";
  }
  return formatToTimeZone(new Date(date), defaultDateFormat, { timeZone });
}

export function getTimestamp(date) {
  if (!isValid(new Date(date))) {
    return null;
  }

  let localTime = convertToLocalTime(new Date(date), { timeZone }); // converting from timezone
  return formatToTimeZone(localTime, 'x', { timeZone, convertTimeZone: false });
}
export function isDateExpired(date) {
  if (!date) {
    return false;
  }
  return Number(new Date(date)) < Date.now();
}

export function convertToTzDate(date) {
  if (!isValid(new Date(date))) {
    return null;
  }
  return convertToTimeZone(date, { timeZone })
}
