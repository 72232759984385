import _ from "lodash";
import {formatDateToHHMMSS, formatDateToMMDDYYYY} from "../date";

class MilestoneData {
    private readonly data: any;

    constructor(data) {
        this.data = data;
    }

    get details() {
        return _.get(this, "data.statusDetails");
    }

    get status() {
        return _.get(this, "data.status", "NOT STARTED");
    }

    get lastUpdatedDate() {
        const updatedDate = _.get(this, "data.updatedDate", "");

        return formatDateToMMDDYYYY(updatedDate);
    }

    get lastUpdatedTime() {
        const updatedDate = _.get(this, "data.updatedDate", "");

        return formatDateToHHMMSS(updatedDate)
    }

    get submilestone() {
        return _.get(this, "data.subMilestone");
    }

    get values() {
        return {
            details: this.details,
            status: this.status,
            lastUpdatedDate: this.lastUpdatedDate,
            lastUpdatedTime: this.lastUpdatedTime,
            submilestone: this.submilestone,
        };
    }
}

class SubmilestoneData {
    private readonly data: any;

    constructor(data) {
        this.data = data;
    }

    get status() {
        return _.get(this, "data.status");
    }

    get date() {
        return formatDateToMMDDYYYY(_.get(this, "data.updatedDate", ""));
    }

    get time() {
        return formatDateToHHMMSS(_.get(this, "data.updatedDate", ""));
    }

    get description() {
        return _.get(this, "data.statusDetails");
    }

    get values() {
        return {
            status: this.status,
            date: this.date,
            time: this.time,
            description: this.description,
        };
    }
}

function isEmptyArray(value: any) {
    if(!Array.isArray(value)) return false;

    return value.length ? false : true;
}

function generalInformationSectionData(values) {
    let arr = [];
    values.forEach((value, key) => {
        if (key !== "milestones" && key !== "submilestone") {
            if (value.value && value.value !== "NA" && !isEmptyArray(value.value)) {
                arr.push([value.name, value.value]);
            }
        }
    });
    return arr;
}
const setPlayOrder =(value:any,includeOfferings:any)=>{
    if( includeOfferings ){
        value.value = _.get(includeOfferings, 'offering.relatedOfferings.parent.playorder', "")
    }
}
const setPlaylistName =(value:any,includeOfferings:any)=>{
    if( includeOfferings ){
        value.name = 'Playlist Name';
        const title =(_.find(_.get(includeOfferings, 'program.titles'), { 'language': 'en-US' }) || _.first(_.get(this, 'program.titles')) || {}) 
        value.value = (title.full || title.short) || title || "";
    }
}
function generalInformationData(values: object,includeOfferings:any=[]) {
    let offeringType;
    return Object.entries(values).reduce(
        (array, [key, value]) => {
            if (key === 'offeringType') {
                offeringType = value.value;
            }
            if (key === 'series' && (offeringType == 'PLAYLIST_PROMO' || offeringType == 'FEATURE' || offeringType == 'EPISODE')) {
                setPlaylistName(value,includeOfferings[0])
            }
            if (key === 'playOrder' && (offeringType == 'FEATURE' || offeringType == 'EPISODE')) {
                setPlayOrder(value,includeOfferings[0])
            }
            if (key !== "milestones" && key !== "submilestone") {
                // let valueException = value && value.name === 'Title ID';
                if (value.value && value.value !== "NA" && !isEmptyArray(value.value)) {
                    array.push([value.name, value.value, value.order]);
                }
            }
            return array;
        },
        []
    );
}

function setDefault(value, defaultValue = 'NA') {
    switch (getType(value)) {
        case 'object':
        case 'array':
            return setDefaultObject(value, defaultValue);
        default:
            return isEmpty(value) ? defaultValue : value;
    }
}

function setDefaultObject(obj, defaultVal) {
    if(isEmpty(obj)) {
        obj = defaultVal;
    } else {
        Object.entries(obj).forEach(([key, value]) => {
            const type = getType(value);

            if (["object", "array"].includes(type)) {
                if(isEmpty(value)) {
                    obj[key] = defaultVal;
                } else {
                    return setDefaultObject(value, defaultVal);
                }
            }
            obj[key] = isEmpty(value) ? defaultVal : value;
        });
    }

    return obj;
}
function getType(variable) {
    const types = {
        '[object Boolean]': 'boolean',
        '[object Date]': 'date',
        '[object Error]': 'error',
        '[object Function]': 'function',
        '[object Number]': 'number',
        '[object RegExp]': 'regexp',
        '[object String]': 'string',
        '[object Undefined]': 'undefined',
        '[object Array]': 'array',
        '[object Object]': 'object',
    };
    const type = Object.prototype.toString.call(variable);

    return types[type];
}
function isEmpty(value: any): boolean {
    return value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0);
}

function getComponents(
  offering : object,
  milestone: "contentAvailabilityMessage" | "deliveryFullfillment")
   : object {
  let components = _.get(offering, `milestones.${milestone}.component`, {});
  if(milestone === "contentAvailabilityMessage") {
    const camComponents = _.get(offering, `milestones.${milestone}.components`);
    if(Array.isArray(camComponents)) {
      // tranform to legacy component
      return camComponents.reduce((acc, component) => {
        const [componentKey] = Object.keys(component);
        if (!Array.isArray(acc[componentKey])) {
          acc[componentKey] = [];
        }
        acc[componentKey].unshift(component[componentKey]);
        return acc;
      }, {})
    }
  }
  return components;
}

export {
    MilestoneData,
    SubmilestoneData,
    generalInformationData,
    generalInformationSectionData,
    setDefault,
    getComponents,
};

