import { useContext, useState, useEffect } from 'react';
import { customizeSharedFilters } from '../../utils/shared-search-utils';
import { AutoRefreshContext  } from '../AutoRefreshContext';
import { TemplateContext } from '../templates/TemplateProvider';
import storage from "../../utils/storage";
import { getUserRole } from "../../utils/teams";
import { COLUMNS_MAPPING, getConstValues, VIEWS_MAPPING } from "../../constants/constants";
import { formatFilters } from '../../utils/shared-search-utils';
import useAxios from '../../utils/axiosImpl';
import apiMap from '../../utils/api.json';
import _ from "lodash";







// Below is the config of filters not allowed in user preference for 
// diffretne views under different roles. Later we will move it as 
// part of teamCollection. TODO (bchopra). Keeping the same structure.
const teamProfile = [
  {
    "key" : "ops",
    "acquire" : {
      "userNotPrefsFilters" : [
        "idparam",
        "acquirekeyword",
        "acquireenddatetime",
        "acquirestartdatetime"
      ]
    },
    "scheduledOfferingsAdvanced" : {
        "userNotPrefsFilters" : [
          "idparam",
          "keyword",
          "startdatetime",
          "enddatetime",
          "seasonname"
        ]
    },
    "assetViewAdvanced" : {
      "userNotPrefsFilters" : [
        "idparam",
        "keyword",
        "assetstartdatefrom",
        "assetstartdateto",
        "seasonname"
      ]
    }
  },
  {
    "key" : "non-ops",
    "acquireBasic" : {
      "userNotPrefsFilters" : [
        "idparam",
        "acquirekeyword",
        "acquireenddatetime",
        "acquirestartdatetime"
      ]
    },
    "scheduledOfferingsBasic" : {
      "userNotPrefsFilters" : [
        "idparam",
        "keyword",
        "startdatetime",
        "enddatetime",
        "seasonname"
      ]
    }
  },
];

const sessionStore = storage('session');

let cachedPrefences = {};
let cachedPrefencesId = null;


const usePreference = () => {
  const { autoRefresh } = useContext(AutoRefreshContext);
  const { darkState } = useContext(TemplateContext);
  const userRole = getUserRole();
  const { get, put, post } = useAxios();

  function filterPreferences(payload, notAllowedFilters) {
    return Object.keys(payload)
    .filter(key => !notAllowedFilters.includes(key))
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: payload[key]
      };
    }, {});
  }

  /*
    NOTE: It returns th filters that Users are NOT
    interested to save as part of the preference.
  */

  function getConfigNotPrefsFilters(currentView) {
    const roleProfile = teamProfile.filter(obj => {
      return obj.key === userRole
    });
    if(roleProfile && Array.isArray(roleProfile) && roleProfile.length === 1) {
      return roleProfile[0][currentView].userNotPrefsFilters;
    }
    return [];
  }

  /**
   * From schema Version:2 Table columns must be in array format
   * convert array to object if any
   * @returns 
   */
  const convertColumnsObjectToArray = (payloadPref) => {
    Object.keys(payloadPref||{}).forEach((key) => {
      const columns = payloadPref[key].columns;
      if (columns && _.isPlainObject(columns)) {
        const results = prepareViewBasedColumns(payloadPref[key].columns,key) ;
        payloadPref[key].columns = results;
      }
    })
    return payloadPref;
  }
  /**
   * @purpose:Function to convert column State to array if it's in object format 
   * @param {*} columnState 
   * @param {*} view 
   * @returns 
   */
  const prepareViewBasedColumns = (columnState, key) => {
    const { showColumn, defaultColumns } = getConstValues(VIEWS_MAPPING[key]) || { showColumn: [], defaultColumn: [] };
    const viewBasedColumns = showColumn || [];
    viewBasedColumns.map((objColumn) => {
      let colId = objColumn.colId;
      if (columnState[colId] != undefined) {
        objColumn.hide = !columnState[colId];
        delete columnState[colId];
      }
    })
    /** check if any objects exists in columnState  */
    const columnKeys = Object.keys(columnState) || {};
    
    columnKeys.map((cKey) => {
      const isDefaultColumn = defaultColumns.findIndex(obj => obj.colId == cKey) > -1;
      if (cKey && !isDefaultColumn) {
        viewBasedColumns.push({
          colId: cKey,
          hide: !columnState[cKey]
        })
      }
    })
    return [...defaultColumns,...viewBasedColumns];
  }
  async function getPreferences() {
    if (Object.keys(cachedPrefences).length === 0) {
      try {
        const payload = await get({ url: apiMap.preferences,
          params: {
            role: getUserRole()
          } });
        cachedPrefences = convertColumnsObjectToArray(payload.preferences);
        cachedPrefencesId = payload.id;
      } catch(err) {
        cachedPrefences = {};
        return;
      }
    }
    return cachedPrefences;

  }

  function getUsersPrefsView(currentView) {
    return _.isEmpty(cachedPrefences && cachedPrefences[currentView]) ? null : cachedPrefences[currentView];
  }

  function getUsersPrefesFilters(currentView) {
    let storageView = getUsersPrefsView(currentView);
    return (storageView && storageView.filters) ? formatFilters(VIEWS_MAPPING[currentView], {...storageView.filters}) : {};
  }

  function getUsersPrefesAutoRefresh() {
    return Object.keys(cachedPrefences).length > 0 ? cachedPrefences.autoRefresh : null;
  }

  function getUsersPrefesMode() {
    return Object.keys(cachedPrefences).length > 0 ? cachedPrefences.darkState : null;
  }

  function getUsersPrefsLimit(currentView) {
    let storageView = getUsersPrefsView(currentView);
    return (storageView && storageView.resultsPerPage) || null;
  }

  function getUsersPrefesReport(currentView) {
    let storageView = getUsersPrefsView(currentView);
    return (storageView && storageView.reports && storageView.reports.columns) || [];

  }

  function getUsersPrefesColumns(currentView) {
    let storageView = getUsersPrefsView(currentView);
    return (storageView && storageView.columns) || {};
  }

  async function generateAndSaveDB(currentView, preferencesPayload = {}) {
    let storeObj = {};

    let viewInfo = preferencesPayload.viewInfo || {};
    let generalSettings = preferencesPayload.generalSettings || {};

    if (cachedPrefences && Object.keys(cachedPrefences).length > 0) {
      storeObj = cachedPrefences[currentView] ?
        {
          ...cachedPrefences,
          [currentView]: {
            ...cachedPrefences[currentView], ...viewInfo
          },
          ...generalSettings
        } :
        {
          ...cachedPrefences,
          [currentView]: {...viewInfo},
          ...generalSettings
        }
    } else {
      storeObj = {
        ...cachedPrefences,
        [currentView]: {...viewInfo},
        ...generalSettings
      }
    }

    console.log("Final preferences to store in DB", storeObj);

    if (cachedPrefencesId) {
      await updatePreferences(storeObj).catch((err) => {
        throw err;
      })
    } else {
      await insertPreferences(storeObj).catch((err) => {
        throw err;
      })
    }

  }

  async function saveUsersPrefesFilters(payload, currentView, limit) {
    const filtered = filterPreferences(payload, getConfigNotPrefsFilters(currentView));
    const customizedFilters = customizeSharedFilters(filtered, currentView, true);
    let preferencesPayload = {};
    preferencesPayload.viewInfo = {
      filters: customizedFilters,
      columns: sessionStore.getItem(COLUMNS_MAPPING[currentView]),
      resultsPerPage: limit
    };
    preferencesPayload.generalSettings = {
      autoRefresh,
      darkState
    };

    await generateAndSaveDB(currentView, preferencesPayload);
  }

  async function saveUsersPrefsReport(currentView, reportingPreferences) {
    let preferencesPayload = {};
    preferencesPayload.viewInfo = {
      reports: {
        columns: reportingPreferences
      }
    };
    await generateAndSaveDB(currentView, preferencesPayload);
  }

  async function updatePreferences(storeObj) {
    try {
      const putPayload = await put({
        url: `/v1/preferences/${cachedPrefencesId}`,
        data: {
          role: userRole,
          preferences:storeObj
        }
      });
      cachedPrefences = putPayload && putPayload.preferences;
    } catch(err) {
      console.log("Preferences not saved in DB but keeping cache copy");
      throw err;
    }
  }

  async function insertPreferences(storeObj) {
    try {
      const postPayload = await post({
        url: apiMap.preferences,
        data: {
          role: userRole,
          preferences:storeObj
        }
      });
      cachedPrefences = postPayload && postPayload.preferences;
      cachedPrefencesId = postPayload && postPayload.id;
    } catch(err) {
      console.log("Preferences not saved in DB but keeping cache copy");
      throw err;
    }
  }

  async function discardUserPrefes(currentView) {
    // Just remove dashboard preferences and not the reporting.
    if (cachedPrefences && cachedPrefences[currentView]) {
      delete cachedPrefences[currentView].filters;
      delete cachedPrefences[currentView].columns;
      delete cachedPrefences[currentView].resultsPerPage
    }
    await updatePreferences(cachedPrefences);
  }

  return {
    saveUsersPrefesFilters,
    saveUsersPrefsReport,
    getUsersPrefesReport,
    getUsersPrefesFilters,
    getUsersPrefesColumns,
    getUsersPrefesAutoRefresh,
    getUsersPrefesMode,
    getUsersPrefsView,
    getUsersPrefsLimit,
    discardUserPrefes,
    getPreferences
  }
};

export default usePreference;