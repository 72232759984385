import _ from "lodash";
import {generalInformationSectionData, MilestoneData, SubmilestoneData} from "./default";
import {formatDateToHHMMSS, formatDateToMMDDYYYY} from "../date";
import storage from '../storage';
import { ACQ_KEYS } from '../../constants/data-contant';

const sessionStore = storage('session');
class MappingForAcquireObject {
    private readonly data: any;
    private  teamsColumns: any;
    constructor(data,teamsColumns) {
        this.data = data;
        this.teamsColumns = teamsColumns;
    }

    private allAlternateIds = [
        { key: 'workflowid', value: 'Workflow ID', mapping: 'data.workflowId' },
        { key: 'externalid', value: 'External ID', mapping: 'data.title.externalIds' }
    ];

    get alternateIds(): string {
        let value = '';
        this.allAlternateIds.map(id => {
            let data = _.get(this, id.mapping, '');
            value += data ? (id.key === 'externalid' && Array.isArray(data) ?
                ('<strong>' + id.value + '</strong>' + ': ' + (data.reduce((acc,v)=>[...acc,"<strong>" + v["Name"]+ "</strong>"+":"+v["Key"]],[]).join(";<br/>")))
                : ('<strong>' + id.value + '</strong>' + ': ' + data)) : '';
            value += value ? "\r\n" : '';
            return value;
        })
        return value;
    }

    get detailId(): string {
        return _.get(this, 'data._id', '');
    }

    get aspectRatio() {
        return _.get(this, "data.master.aspectRatio", "");
    }

    get assetRuntime() {
        return _.get(this, "data.master.totalRunTime", "");
    }

    get autoQc() {
        const autoQc = _.get(this, "data.milestones.autoQc");
        const submilestoneData = new SubmilestoneData(autoQc);

        return submilestoneData.values;
    }

    get brand() {
        return "";
    }

    get createdDate() {
        const date = _.get(this, "data.createdDate", "");

        return formatDateToMMDDYYYY(date);
    }

    get startTime() {
        const date = _.get(this, "data.createdDate", "");

        return formatDateToHHMMSS(date);
    }

    get description() {
        return "";
    }

    get distributor() {
        return _.get(this, "data.distributor", "");
    }

    get episodeNumber() {
        return _.get(this, "data.title.episodeNumber");
    }

    get frameRate() {
        return _.get(this, "data.master.frameRate", "");
    }

    get manualQc() {
        const milestones = _.get(this, "data.milestones", "");
        const manualQcData = _.find(milestones, milestone => {
            return ["manualqc"].includes(milestone.name.toLowerCase());
        });
        const milestoneData = new MilestoneData(manualQcData);
        let qcData = milestoneData.values;

        qcData["displayName"] = _.has(this, "data.qcAssignee.assignee.displayName") ? (this.data.qcAssignee.assignee || {}).displayName : "";
        qcData["notes"] = _.has(this, "data.qcAssignee.notes") ? (this.data.qcAssignee || {}).notes : "";

        return qcData;
    }

    get networkName() {
        return _.get(this, "data.networkName", "");
    }

    get normalized() {
        const normalized = _.get(this, "data.milestones.normalization");
        const submilestoneData = new SubmilestoneData(normalized);

        return submilestoneData.values;
    }

    get platform() {
        return "";
    }

    get proxyCreation() {
        const milestones = _.get(this, "data.milestones", "");
        const proxyCreation = _.find(milestones, milestone => {
            return ["proxycreation"].includes(milestone.name.toLowerCase());
        });
        const milestoneData = new MilestoneData(proxyCreation);

        return milestoneData.values;
    }

    get qmCreation() {
        const milestones = _.get(this, "data.milestones", "");
        const qmCreation = _.find(milestones, milestone => {
            return ["qmcreation"].includes(milestone.name.toLowerCase());
        });
        const milestoneData = new MilestoneData(qmCreation)
        let qmData = milestoneData.values;

        qmData["cid"] = _.has(this, "data.master.cid") ? (this.data.master || {}).cid : "";

        return qmData;
    }

    get receipt() {
        const milestones = _.get(this, "data.milestones", "");
        const preProcessing = _.find(milestones, milestone => {
            return ["receipt"].includes(milestone.name.toLowerCase());
        });
        const milestoneData = new MilestoneData(preProcessing);
        let receiptData = milestoneData.values;

        receiptData["files"] = _.get(this, "data.packageFiles", "");

        return receiptData;
    }

    get seasonNumber() {
        return _.get(this, "data.title.seasonNumber");
    }

    get seriesName() {
        return _.get(this, "data.title.seriesName");
    }

    get startingTime() {
        const date = _.get(this, "data.createdDate", "");

        return formatDateToHHMMSS(date || "");
    }

    get title() {
        return _.get(this, "data.title.name", "");
    }

    get titleId() {
        return _.get(this, "data.title.id", "");
    }

    get upload() {
        const milestones = _.get(this, "data.milestones", "");
        const acquireData = _.find(milestones, milestone => {
            return ["upload"].includes(milestone.name.toLowerCase());
        });
        const milestoneData = new MilestoneData(acquireData);

        return milestoneData.values;
    }

    get packageReceived() {
        const packageReceived = _.get(this, "data.milestones.packageReceived");
        const submilestoneData = new SubmilestoneData(packageReceived);

        return submilestoneData.values;
    }

    get fileTransfer() {
        const fileTransfer = _.get(this, "data.milestones.fileTransfer");
        const submilestoneData = new SubmilestoneData(fileTransfer);

        return submilestoneData.values;
    }

    get uploadTypeMMC() {
        return _.get(this, "data.milestones.upload.ingestType");
    }

    get videoType() {
        return "";
    }

    get worflowId() {
        return _.get(this, "data.workflowId");
    }

    get workflowType(){
        return _.get(this, "data.workflowAssetType");
    }

    get values() {
        let map = new Map();
        map.set(ACQ_KEYS.TITLE,                     { name: "Title",                    value: this.title });
        map.set(ACQ_KEYS.WORKFLOW_TYPE,             { name: "Workflow Type",            value: this.workflowType });
        map.set(ACQ_KEYS.VIDEO_TYPE,                { name: "Video Type",               value: this.videoType });
        map.set(ACQ_KEYS.ASPECT_RATIO,              { name: "Aspect Ratio",             value: this.aspectRatio });
        map.set(ACQ_KEYS.FRAME_RATE,                { name: "Frame Rate",               value: this.frameRate });
        map.set(ACQ_KEYS.SERIES_NAME,               { name: "Series Name",              value: this.seriesName });
        map.set(ACQ_KEYS.SEASON_NUMBER,             { name: "Season #",                 value: this.seasonNumber });
        map.set(ACQ_KEYS.EPISODE_NUMBER,            { name: "Episode #",                value: this.episodeNumber });
        map.set(ACQ_KEYS.DESCRIPTION,               { name: "Description",              value: this.description });
        map.set(ACQ_KEYS.START_DATE,                { name: "Start Date",               value: this.createdDate });
        map.set(ACQ_KEYS.START_TIME,                { name: "Start Time",               value: this.startTime });
        map.set(ACQ_KEYS.DISTRIBUTOR,               { name: "Distributor",              value: this.distributor });
        map.set(ACQ_KEYS.NETWORK_NAME,              { name: "Network Name",             value: this.networkName });
        map.set(ACQ_KEYS.ASSET_RUNTIME,             { name: "Total Runtime",            value: this.assetRuntime });
        map.set(ACQ_KEYS.UPLOAD_TYPE_MMC,           { name: "Upload Type",              value: this.uploadTypeMMC });
        map.set(ACQ_KEYS.ID,                        { name: "ID",                       value: this.titleId });
        map.set(ACQ_KEYS.ALTERNATE_IDS,             { name: "Additional Ids",           value: this.alternateIds });
        map.set(ACQ_KEYS.MILESTONES,                {
                upload: this.upload,
                receipt: this.receipt,
                manualQc: this.manualQc,
                qmCreation: this.qmCreation,
                mamProxyCreation: this.proxyCreation,
        });
        map.set(ACQ_KEYS.SUBMILESTONE,              {
                receipt: {
                    normalized: {
                        name: "Normalized",
                        value: this.normalized,
                    },
                    autoQc: {
                        name: "Auto QC",
                        value: this.autoQc,
                    },
                },
                upload: {
                    packageReceived: {
                        name: "Package Received",
                        value: this.packageReceived,
                    },
                    fileTransfer: {
                        name: "File Transfer",
                        value: this.fileTransfer,
                    }
                }
        });
        map.set(ACQ_KEYS.ADDITIONAL_TIMESTAMPS,{
         name: "additionalTimestamps",
         value:"Show additional msg details"});
        return map;
    }

    get modalDataAdvanced(): object {
        const generalInformation = generalInformationSectionData(this.values);
        const milestones = this.values.get('milestones') || {};
        const submilestones = this.values.get('submilestone') || {};

        return {
            title: `${this.title || 'NA'} - ${this.titleId}`,
            detailId: this.detailId,
            generalInformation,
            process: {
                id: this.worflowId,
                submilestones,
                milestones,
            },
        };
    }


    get modalDataBasic(): object {
        const generalInformation = generalInformationSectionData(this.values);
        const milestones = this.values.get('milestones') || {};
        const submilestones = this.values.get('submilestone') || {};
        this.teamsColumns = this.teamsColumns ? this.teamsColumns : getTeamsColumns();
        return {
            title: `${this.title || 'NA'} - ${this.titleId}`,
            detailId: this.detailId,
            generalInformation,
            process: {
                id: this.worflowId,
                submilestones,
                milestones:filterColumn(this.teamsColumns,milestones),
            },
        };
    }
}
const filterColumn = (teamsColumns, milestones) => {
    let filteredColumns = {};
    Object.entries(milestones).map(([name, val]) => {
        if (teamsColumns.includes(name.toLowerCase()))
            filteredColumns[name] = val;
    })

    return filteredColumns;
}

const getTeamsColumns = ()=>{
    let columns=[];
	let teamsProfile = sessionStore.getItem("teamProfile")
      teamsProfile.forEach(profile=>{
        columns = Array.from(new Set(columns.concat( profile['acquireBasic']['columns'])))
      })
    return columns;
    }
export default MappingForAcquireObject;