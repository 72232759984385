
import React, {Suspense} from 'react';
import LoaderFallback from "../components/atoms/LoaderFallback";

function LazyLoader(WrappedComponent: any, props?: any) {
        return <Suspense fallback={<LoaderFallback />}>
            <WrappedComponent {...props}/>
        </Suspense>;
};

export default LazyLoader;