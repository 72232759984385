import _ from "lodash";
import { ASSET_ID_FILTER, SCHEDULED_ID_FILTER } from '../../constants/data-contant';

class MappingForSearchIdMap {
    private readonly data: object;
    
    protected constructor(data) {
        this.data = data;
    }

    get offeringType(): object{
        return _.get(this, 'data.scheduledOfferings.offeringType', {});
    }

    get offeringStatus(): object{
      return _.get(this, 'data.scheduledOfferings.offeringStatus', {});
    }

    get brands(): object{
        return _.get(this, 'data.scheduledOfferings.brands', {});
    }

    get namespace(): object{
        return _.get(this, 'data.scheduledOfferings.namespace', {});
    }

    get adStatus(): object{
        return _.get(this, 'data.scheduledOfferings.adStatus', {});
    }

    get reasonCodes(): object{
        return _.get(this, 'data.scheduledOfferings.reasonCodes', {});
    }

    get fulfillmentType(): object{
        return _.get(this, 'data.scheduledOfferings.fulfillmentType', {});
    }

    get espId(): Array<string>{
        const idMap = _.get(this, 'data.scheduledOfferings.id.filters', []);
        const espIdArr = [];
        
        idMap.forEach(filter => {
            SCHEDULED_ID_FILTER[filter.key] && SCHEDULED_ID_FILTER[filter.key].toLowerCase() === filter.key.toLowerCase() && espIdArr.push(filter);
        })
  
        return espIdArr;
    }

    get assetId(): Array<string>{
        const clonedObj = JSON.parse(JSON.stringify(this.data))
        const idMap = _.get(clonedObj, 'scheduledOfferings.id.filters', []);
        const assetIdArr = [];
        const assetAllMap = [];
  
        idMap.forEach(filter => {
            if(ASSET_ID_FILTER[filter.key] && ASSET_ID_FILTER[filter.key].toLowerCase() === filter.key.toLowerCase()) {
                assetIdArr.push(filter);
                filter.key !== 'all' && assetAllMap.findIndex(data => data === filter.mapping[0]) == -1 && assetAllMap.push(...filter.mapping);
            }
        })

        _.set(assetIdArr, '[0].mapping', assetAllMap);

        return assetIdArr;
    }

    get keyword(): object{
        return _.get(this, 'data.scheduledOfferings.keyword', {});
    }

    get scheduledOfferingsMap(): object {
        return {
            offeringType: this.offeringType,
            brands: this.brands,   
            namespace: this.namespace,
            adStatus: this.adStatus,
            reasonCodes: this.reasonCodes,
            fulfillmentType: this.fulfillmentType,
            offeringStatus: this.offeringStatus, 
            id: {
                filters: this.espId
            }
        }
    }

    get assetMap(): object {
        return {
            keyword: this.keyword,
            adStatus: this.adStatus,
            fulfillmentType: this.fulfillmentType,
            id: {
                filters: this.assetId
            }
        }
    }

}


export default MappingForSearchIdMap;
    
