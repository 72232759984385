function getLocationQueries() {
    const location = window.location.search;
    const params = new URLSearchParams(location);

    return Object.fromEntries(params);
}

function clearUrlQuery() {
    window.history.replaceState(null, "Disco App", "/");
}

export {
    clearUrlQuery,
    getLocationQueries,
};