import React, {useContext, useEffect, useState} from "react";
import {
    AppBar,
    Box,
    LinearProgress,
    makeStyles,
    Tab as MuiTab,
    TableContainer,
    Tabs,
    Toolbar as MuiToolbar,
    withStyles,
} from "@material-ui/core";
import DetailsModalMoreInfo from "../molecules/DetailsModalMoreInfo";
import DetailsTable from "../molecules/DetailsTable";
import MappingForAcquireModalDetailView from "../../utils/data-map/mappingForAcquireModalDetailView";
import {StatusIcon} from "../atoms/Icons";
import TabPanel from "../atoms/TabPanel";
import _ from "lodash";
import useAxios from "../../utils/axiosImpl";
import useSecurity from "../security/useSecurity"
import {DetailsModalContext} from "../../services/DetailsModalProvider";
import { neutral, steel } from "../templates/palette.json";
import {setDefault} from "../../utils/data-map/default";
import { PageContext } from "../../services/PageProvider";
import storage from '../../utils/storage';
import { REFRESH_STATE } from '../../constants/data-contant';

const tabMapping = {
    upload: "Upload",
    receipt: "Receipt",
    manualQc: "Manual QC",
    qmCreation: "QM Creation",
    mamProxyCreation: "MAM Proxy Creation",
};

const useStyles = makeStyles((theme) => ({
    tabPanel: {
        overflow: "auto",
        height: "calc(100% - 147px)",
        background: theme.palette.type === 'dark' ? steel[900] : neutral[0]
    },
    indicator:{
        backgroundColor: theme.palette.type === 'dark' ? steel[500] : neutral[800]
    },
    tabSpace:{
        marginBottom: 0
    }
}));

const Tab = withStyles((theme) => ({
    root: {
        alignItems: 'flex-start',
        background: theme.palette.type === 'dark' ? steel[800] : neutral[200],
        border: theme.palette.type === 'dark' ? `2px solid ${steel[900]}` : `2px solid ${neutral[0]}`,
        borderTop: 'none',
        color: theme.palette.type === 'dark' ? neutral[100] : steel[600],
        display: 'flex',
        flex: 1,
        fontFamily: `"Roboto", "Noto", sans-serif`,
        lineHeight: '1.5',
        minWidth: 'auto',
        maxWidth: 'unset',
        opacity:1,
        padding: '4px 12px',
    },
    wrapper: {
        display: 'inline'
    }
}))(MuiTab);

const Toolbar = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.type === 'dark' ? steel[700] : neutral[800],
        borderRadius: '4px 4px 0 0',
        color: theme.palette.type === 'dark' ? steel[100] : steel[600],
        display: "flex",
        fontFamily: `"Roboto", "Noto", sans-serif`,
        // padding: 10,
        paddingLeft: 0,
    },
}))(MuiToolbar);

const sessionStore = storage('session');

export default function ProcessDetailsModalAcquire(props) {
    const {moreInfoState, resetMoreInfoState} = useContext(DetailsModalContext);
    const { fullScreen, refreshState } = props;
    const { pageState } = useContext(PageContext);
    const classes = useStyles();
    let [selectedTabState, setSelectedTabState] = useState(0);
    let [dataState, setDataState] = useState({});
    let [loadingState, setLoadingState] = useState(true);
    let [errorState, setErrorState] = useState("");
    const {get} = useAxios();
    const isAuthenticated = useSecurity();

    useEffect(() => {
        setErrorState('');
        setLoadingState(true);
        
        getDetailsViewData();

        if(fullScreen) {
            const interval = setInterval(() => {
              getDetailsViewData();
            }, REFRESH_STATE);
            return () => clearInterval(interval);
        }
        
    }, [refreshState]);

    function render() {
        return (
            <>
                <AppBar elevation={0} position="relative">
                    <Tabs
                        value={selectedTabState}
                        onChange={handleTabChange}
                        aria-label="Process tab selection"
                        classes = {{indicator: classes.indicator}}
                    >
                        {renderTabs()}
                    </Tabs>
                    {loadingState ? <LinearProgress color="secondary"/> : null}
                </AppBar>
                <Box>{!errorState ? renderBox : errorState}</Box>
            </>
        );
    }

    function renderBox() {
        return (
            <>
                {!moreInfoState.show ? renderPanels() : null}
                {moreInfoState.show ? (
                    <DetailsModalMoreInfo
                        title={moreInfoState.title}
                        status={moreInfoState.status}
                        eventId={moreInfoState.eventId}
                    />
                ) : null}
            </>
        );
    }

    function renderTabs() {
        const entries = Object.entries(props.milestones);

        return entries.map(([key, value]) => (
            <Tab
                label={<p className = {classes.tabSpace}>{tabMapping[key]}</p>}
                key={key}
                icon={<StatusIcon status={value.status}/>}
                index={key}
            />
        ));
    }

    function renderPanels() {
        const map = new MappingForAcquireModalDetailView(dataState, props.submilestones);
        const tabData = setDefault(map.tabData);

        const keys = Object.keys(props.milestones);
        if(_.has(tabData,"manualQc.content") && _.isArray(tabData.manualQc.content) && tabData.manualQc.content.length > 1){
            tabData.manualQc.content.sort(function(a,b){

                return new Date(b.timestamp) - new Date(a.timestamp);
            });
        }
        return keys.map((key, index) => {
            const item = tabData[key];
            let content;

            if (hasNoData(item)) {
                content = <p>No data to display for selected milestone</p>;
            } else {
                content = (
                    <>
                        {(Array.isArray(item.content) && item.content.length>0) ? (
                            <DetailsTable title={tabMapping[key]} content={item.content}/>
                        ) : null}
                        {item.submilestones
                            ? renderSubmilestones(item.submilestones)
                            : null}
                    </>
                );
            }

            return (
                <TabPanel
                    className={classes.tabPanel}
                    value={selectedTabState}
                    key={key}
                    index={index}
                >
                    {content}
                </TabPanel>
            );
        });
    }

    function showDetailTable(submilestone) {
        return Array.isArray(submilestone.content) && submilestone.content.length > 0 ? (
            <DetailsTable title={submilestone.title} content={submilestone.content} options={submilestone.options} />
        ) : (
            <br></br>
        );
    }

    function renderSubmilestones(submilestones) {
        return submilestones.map((submilestone) => {
            if (_.isEmpty(submilestone)) return null;

            return (<>
                <TableContainer key={`${submilestone.title}-table`}>
                    <Toolbar variant="dense">
                        <StatusIcon status={submilestone.status}/>
                        {submilestone.title}
                    </Toolbar>
                    {(hasNoData(submilestone)) ? <p>No data to display for selected milestone</p> 
                    : showDetailTable(submilestone) }
                </TableContainer></>
            );
        });
    }

    const getDetailsViewData = () => {
        get({
            url: '/v1/acquireEvents/details',
            params: {
                id: props.id
            }
        })
        .then(filterData)
        .then(setDataState)
        .catch((error) => setErrorState(error.message))
        .finally(() => setLoadingState(false))
    }

    function handleTabChange(event, newValue) {
        setSelectedTabState(newValue);
        resetMoreInfoState();
    }

    function filterData(data) {
        let teamsProfile = sessionStore.getItem("teamProfile");
        let filter = {};

        teamsProfile = Array.isArray(teamsProfile) ? teamsProfile : [teamsProfile];
        filter = mergeFilters(teamsProfile, pageState);

        return applyHideFilter(data, filter);
    }

    function mergeFilters(teamProfile, workflow) {
        return teamProfile.reduce(function mergeHideFilter(filter, current) {
            const detailsFilter = _.get(current, `${workflow}.filters.details`);
            
            for (const key in detailsFilter) {
                if (filter[key]) {
                    filter[key] = {
                        hide: !!(detailsFilter[key]?.hide && filter[key]?.hide)
                    }
                } else {
                    filter = {...detailsFilter}
                }
            }

            return filter;
        }, {});
    }

    function applyHideFilter(data, filter) {
        if (filter) {
            const copy = { ...data };

            for (const key in filter) {
                if (filter[key]?.hide === true) {
                    delete copy[key];
                }
            }

            return copy;
        } else {
            return data;
        }
    }

    function hasNoData(item) {
        if (_.isEmpty(item) || item === "NA") {
            return true;
        } else if (!_.isObject(item.submilestones) && (_.isEmpty(item.content) || item.content === "NA")) {
            return true;
        }

        return false;
    }

    return render();
}

