import React from "react";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
help: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '36px',
    lineHeight: '49px',
    color: '#9580FF',
    marginTop: '0px',
},
guideDark: {
    color: '#DADADA'
},
guideLight: {
    color: '#57748E'
},
guide: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '33px',
    

},
disco: {
    width: '150px',
    height: '90px',
    marginTop: '-30px',
}

})
)
 export const HelpDocs = (props) =>{
    const classes = useStyles();
    const {darkStorage}=props;
    return (
    <div>
    <div>
        <img  className={classes.disco} src="/assets/svg/brand-dark.svg" />
    </div>
    <div>
        <h1 className={classes.help}>Help and Documents</h1>
    </div>
    <div>
        <h3 className={`${classes.guide} ${darkStorage ? classes.guideDark : classes.guideLight}`}>Guides and Documentation</h3>
    </div>
    </div>
    )
}