import React, { useState } from "react";

export const DetailsModalContext = React.createContext(null);

export default function DetailsModal({ children }) {
  const defaults = {
    eventId: 0,
    show: false,
    status: "",
    title: "",
  };
  const [moreInfoState, setMoreInfoState] = useState(defaults);

  function resetMoreInfoState() {
    setMoreInfoState(defaults);
  }

  return (
    <DetailsModalContext.Provider
      value={{ moreInfoState, resetMoreInfoState, setMoreInfoState }}
    >
      {children}
    </DetailsModalContext.Provider>
  );
}
