const TITLE = {
    DiSCO_GLOSSARY : 'DiSCO Glossary & Terminology',
    DiSCO_OVERVIEW : 'DiSCO Overview',
    DISCO_GUIDE    : 'DiSCO Training Guide',
    DISCO_RELEASE  : 'Release Notes',
}
const DESCRIPTION ={
    GLOSSARY : 'This glossary and terminology pdf guide will provide definitions for some of the terms found throughout the DiSCO dashboard.',
    OVERVIEW : 'This overview deck will provide a high-level outline of the DISCO system, key stakeholders, and other info about the platform.',
    GUIDE    : 'This DiSCO training guide will help provide general training and understanding for using DiSCO application.',
    RELEASE  : 'Release notes include features, bugs, and improvements for the latest release in the DiSCO application.'
}
module.exports = {
    TITLE,
    DESCRIPTION
}