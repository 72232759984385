export function toProperCase(str: String) {
  return str?.replace(/\w\S*/g, (txt)  => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

export function camelCaseToTitleCase(str: String) {
  var result = str                         
      ?.replace(/(_)+/g, ' ')                             
      .replace(/([a-z])([A-Z][a-z])/g, "$1 $2")           
      .replace(/([A-Z][a-z])([A-Z])/g, "$1 $2")          
      .replace(/([a-z])([A-Z]+[a-z])/g, "$1 $2")          
      .replace(/([A-Z]+)([A-Z][a-z][a-z])/g, "$1 $2")     
      .replace(/([a-z]+)([A-Z0-9]+)/g, "$1 $2")          
      .replace(/([A-Z]+)([A-Z][a-rt-z][a-z]*)/g, "$1 $2") 
      .replace(/([0-9])([A-Z][a-z]+)/g, "$1 $2")          
      .replace(/([A-Z]{2,})([0-9]{2,})/g, "$1 $2")      
      .replace(/([0-9]{2,})([A-Z]{2,})/g, "$1 $2")        
      .trim();

return result.charAt(0).toUpperCase() + result.slice(1);
}