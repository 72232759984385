import React, { useState } from 'react';
import {
    AppBar,
    Dialog as MuiDialog,
    DialogTitle as MuiDialogTitle,
    IconButton as MuiIconButton,
    Paper as MuiPaper,
    Theme,
    Toolbar as MuiToolbar,
    Tooltip,
    Typography,
    withStyles, Box, Button, TextField, makeStyles
} from '@material-ui/core';
import {neutral, steel} from '../templates/palette.json';
import {Close as CloseIcon} from "@material-ui/icons";

const useStyle = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        marginTop: 10
    },
    textfield: {
        flex: 1,
        backgroundColor: theme.palette.type === 'dark' ? steel[800] : neutral[100],
        color: theme.palette.type === 'dark' ? steel[300] : steel[500]
    },
    resize: {
        fontSize: 10
    },
    copybtn: {
        color: neutral[0],
        backgroundColor: steel[400],
        marginLeft: 10,
        height: 26,
        fontSize: 10,
        '&:hover': {
            backgroundColor: steel[500],
        }
    }
}))

interface IProps {
    open: boolean;
    handleCloseDialog(): void;
    header: string;
    message: string;
    error: boolean;
    headerIcon?: null;
}

interface ITheme extends Theme {
    background: string;
    disco: {
        neutral: string,
        steel: string
    };
}

const DialogModal: React.FC<IProps> = ({ open, handleCloseDialog, header, children, error, headerIcon }) => {
    const classes = useStyle();
    const [isTooltipOpen, setTooltipOpen] = useState(false);

    const handleCopyText = () => {
        navigator.clipboard.writeText(`${children}`)
            .then(() => {
                setTooltipOpen(true);
                setTimeout(() => {
                    setTooltipOpen(false);
                }, 1500);  
            });
    };
    const Container = withStyles((theme: ITheme) => ({
        root: {
            backgroundColor: theme.background,
            padding: !!error ? 1 : 16,
        },
    }))(MuiPaper);
    const DialogContent = withStyles((theme: ITheme) => ({
        root: {
            backgroundColor: !!error ? theme.palette.type === 'dark' ? steel[700] : neutral[300]: theme.palette.type === 'dark' ? steel[900] : theme.palette.primary.light,
            fontSize: '1rem',
            borderRadius: 8,
            minWidth: !!error ? 0 : 400,
            fontFamily: "Open Sans",
            padding: theme.mixins.gutters().paddingLeft
        },
    }))(MuiPaper);
    const DialogStyles = {
        borderRadius: 8,
        ...(!!error && {
            boxShadow: "0 4px 4px rgba(0,0,0,0.25) !important",
            maxWidth: "300px !important",
            maxHeight: "185px !important",
            borderRadius: 8,
            overflow: 'hidden'
        })
    }
    
    return (
        <MuiDialog open={open} PaperProps={{ style: DialogStyles }}>
            {
                !!open &&
                <><AppBar elevation={0} position="relative">
                    <Toolbar variant="dense">
                        <DialogTitle disableTypography>
                            {headerIcon}{header}
                        </DialogTitle>
                        <IconButton
                            onClick={handleCloseDialog}
                            aria-label="close modal"
                            edge="end"
                        >
                            <CloseIcon fontSize='small' />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Container>
                    <DialogContent elevation={0}>
                        {
                            (!!error ) ?
                                <Typography variant="body2" style={{textAlign: 'center'}}>
                                        {children}
                                </Typography> :
                                <Box>
                                    <Typography variant="body2">Sharing Link</Typography>
                                    <Box className={classes.container}>
                                        <TextField 
                                            value={children} 
                                            className={classes.textfield}
                                            inputProps={{
                                                readOnly: true
                                            }}
                                            InputProps={{
                                                classes: {
                                                input: classes.resize,
                                                },
                                            }}
                                        />
                                        <Tooltip
                                            open={isTooltipOpen}
                                            title="Copied"
                                            disableHoverListener
                                            disableFocusListener
                                            disableTouchListener
                                            leaveDelay={1500}
                                            placement="top"
                                            onClose={() => setTooltipOpen(false)}
                                        >
                                            <Button onClick={() => handleCopyText()} className={classes.copybtn}>
                                                Copy URL
                                            </Button>
                                        </Tooltip>
                                    </Box>
                                </Box>
                        }
                    </DialogContent> 
                </Container></>
            }
        </MuiDialog>
    );
}

const DialogTitle = withStyles((theme: ITheme) => ({
    root: {
        padding: '12px 24px 12px 0',
        fontSize: 16,
        fontFamily: 'Open Sans',
        color: theme.palette.type === 'dark' ? theme.disco.neutral[0] : theme.disco.steel[600],
    },
}))(MuiDialogTitle);

const IconButton = withStyles((theme: ITheme) => ({
    root: {
        height: 10,
        width: 10,
        borderRadius: '50%',
        padding: 13,
        background: theme.palette.type === 'dark' ? theme.disco.steel[600] : theme.disco.neutral[300],
        color: theme.palette.type === 'dark' ? theme.disco.steel[800] : theme.disco.neutral[600],
        marginLeft: 20,
        '&:hover': {
            background: theme.palette.type === 'dark' ? theme.disco.steel[600] : theme.disco.neutral[300]
        }
    }
}))(MuiIconButton);

const Toolbar = withStyles((theme: ITheme) => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: 52,
        backgroundColor: theme.palette.type === 'dark' ? steel[900] : theme.palette.primary.light,
        color: theme.palette.type === 'dark' ? steel[300] : steel[500]
    },
}))(MuiToolbar);

export default DialogModal;
