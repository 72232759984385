import { TextEncoder } from 'text-encoding';
function polyfillSupport() {
    if (typeof (window).TextEncoder === 'undefined') {
        (window).TextEncoder = TextEncoder;
    }
    // missing forEach on NodeList for IE11
    if (window.NodeList && !NodeList.prototype.forEach) {
        NodeList.prototype.forEach = Array.prototype.forEach;
    }
}

export default polyfillSupport;