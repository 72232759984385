import storage from './storage';
import { STATUS_MAP, MILESTONE, ACQ_MILESTONE, ESP_KEYWORD, ESP_AVOD, ESP_MILESTONE_MAPPING, ESP_OFFERING_STATUS } from '../constants/search-constants';
import { validateKeywordIdLength, handleMilestoneSearchParams } from './filter-utils';
import { convertToTzDate } from './date';
const sessionStore = storage('session');

const selectedFilters = {
  keywordType: "",
  keywordValue: "",
  idType: "",
  idValue: "",
  brands: [],
  milestone: [],
  milestoneStatus: [],
  offering: [],
  distributor: [],
  network: [],
  platform: [],
  channel: [],
  territory: [],
  ingestType: "",
  refulfilled: false,
  seasonName: [],
  seasonData: [],
  reasonCodes: "",
  fulfillmentType: "",
  avod: "",
  adStatus: [],
  exact: true,
  workflowType: [],
  milestoneSearch: false,
  allOfferings: false,
  offeringStatus: ""
};

export const getFiltersMap = (view) => {
  const filterObj = sessionStore.getItem('filtersMap');
  const commonFilters = (filterObj && filterObj.common) || {};
  let filtersMap;

  switch(view) {
    case 'esp': 
      filtersMap = (filterObj && filterObj.scheduledOfferings) || {};
      break;
    case 'avp': 
      filtersMap = (filterObj && filterObj.asset) || {};
      break;
  }

  return { filtersMap: filtersMap, commonFilters: commonFilters }
}

export const validateSharedSearch = (payload, setToastState, currentPage) => {
  const startdate = currentPage === 'assetViewAdvanced' ? 'assetstartdatefrom' : 'startdatetime';
  const enddate = currentPage === 'assetViewAdvanced' ? 'assetstartdateto' : 'enddatetime';
  if (!payload[startdate] && !payload[enddate]) {
    setToastState({ open: true, message: "At least one date must be selected to return results", autoHideDuration: 3000, ClickAwayListenerProps: { 'mouseEvent': 'onClick' } });
    return false;
  }

  payload = validateKeywordIdLength(payload, setToastState);
  if (!payload) return false;

  payload = handleMilestoneSearchParams(payload);

  payload = customizeSharedFilters(payload, currentPage);
  let skipDateRestriction = payload.search.hasOwnProperty('idparam');
  if (skipDateRestriction) {
    payload.search['startdatetime'] = 0;
    delete payload.search.enddatetime;
    if (currentPage === 'assetViewAdvanced') {
      delete payload.search.startdatetime;
      delete payload.search.assetstartdateto;
      // 0 is required as we have atleast one date should be supplied restriction
      payload.search['assetstartdatefrom'] = 0;
    }
  }

  return payload;
}

export const customizeSharedFilters = (filters, view = "", userPrefes = false) => {
    let selectedFilters = {}, payload = {};
    Object.keys(filters).forEach(filter => {
        let obj = {};
        switch(filter){
            case 'keyword':
            case 'acquirekeyword':
                let keyword = JSON.parse(filters[filter]);
                obj['type'] = [keyword.name.toLowerCase()];
                obj['value'] = obj['value'] = filters.exact ? [keyword.value] : keyword.value.split(' ').filter(kw => kw.length);
                selectedFilters['keyword'] = [obj];
                break;
            case 'idparam':
                let parsedVal = JSON.parse(filters[filter]);
                let key = Object.keys(parsedVal)[0];
                obj['type'] = [key.toLowerCase()];
                obj['value'] = parsedVal[key].split(' ');
                selectedFilters[filter] = [obj];
                break;
            case 'milestone':
                let milestone = JSON.parse(filters[filter]);
                obj['type'] = milestone.name.map(val => val.toLowerCase());
                obj['value'] = milestone.status.map(val => val.toLowerCase());
                selectedFilters[filter] = [obj];
                break; 
            case 'brand':
            case 'offeringtype':
            case 'avod':
            case 'reasoncodes':
            case 'acquireingesttype':
                let filterkey = filter.replace('acquire', '');
                selectedFilters[filterkey] = filters[filter].toLowerCase().split(',');
                break;
            case 'fulfillmentType':
                selectedFilters['fulfillmenttype'] = filters[filter].toLowerCase().split(',');
                break;
            case 'platform':
            case 'channel':
            case 'territory':
            case 'adstatus':
                selectedFilters[filter] = JSON.parse(filters[filter]).name.map(val => val.toLowerCase());
                break;
            case 'acquiredistributor':
            case 'acquirenetwork':
            case 'acquireworkflowtype':
                let acquirekey = filter.replace('acquire', '');
                selectedFilters[acquirekey] = JSON.parse(filters[filter]).name.map(val => val.toLowerCase());
                break;
            case 'refulfilled':
            case 'seasonName':
                selectedFilters[filter] = filters[filter];
                break;
            case 'milestonesearch':
                let sortMilestone = JSON.parse(filters['milestone']).name.toString();
                selectedFilters[filter] = filters[filter].toString();
                break;
            case 'exact':
            case 'getnanstartdates':
                selectedFilters[filter] = filters[filter].toString();
                break;
            case 'offeringStatus':
                selectedFilters['offeringstatus'] = filters[filter].toLowerCase().split(',');
                break;
            case 'startdatetime':
            case 'enddatetime':
            case 'updateddatefrom':
            case 'updateddateto':
            case 'acquirestartdatetime':
            case 'acquireenddatetime':
            case 'acquireupdateddatefrom':
            case 'acquireupdateddateto':
            case 'assetstartdatefrom':
            case 'assetstartdateto':
                selectedFilters[filter] = Number(filters[filter]);
        }
    });


    return !userPrefes ? { view: view, search: selectedFilters} : selectedFilters
}

export const setEspDateFilter = (sharedFilter) => {
    const startdate = sharedFilter.hasOwnProperty('startdatetime');
    const enddate   = sharedFilter.hasOwnProperty('enddatetime');
    const updateddateto   = sharedFilter.hasOwnProperty('updateddateto');
    const updateddatefrom = sharedFilter.hasOwnProperty('updateddatefrom');
    const assetstartdate = sharedFilter.hasOwnProperty('assetstartdatefrom');
    const assetenddate   = sharedFilter.hasOwnProperty('assetstartdateto');
    let from;
    let to;
    if(startdate && sharedFilter['startdatetime'] === 0) return;
    if(assetstartdate && sharedFilter['assetstartdatefrom'] === 0) return;

    let startdatekey = startdate ? 'startdatetime' : assetstartdate ? 'assetstartdatefrom' : 'updateddatefrom';
    let enddatekey = enddate ? 'enddatetime' : assetenddate ? 'assetstartdateto' : 'updateddateto';
  
    if((startdate && !enddate) || (updateddatefrom && !updateddateto) || (assetstartdate && !assetenddate)){
        from = convertToTzDate(sharedFilter[startdatekey]);
        to = null;
    }
    
    if((!startdate && enddate) || (!updateddatefrom && updateddateto) || (!assetstartdate && assetenddate)){
        from = null;
        to = convertToTzDate(sharedFilter[enddatekey]);
    }

    if((startdate && enddate) || (updateddatefrom && updateddateto) || (assetstartdate && assetenddate)) {
        from = convertToTzDate(sharedFilter[startdatekey]);
        to = convertToTzDate(sharedFilter[enddatekey]);
    }
    return { fromDate: from, toDate: to };
}

const getEspMilestoneArray = (filters) => {
    let milestones;
    filters.map(filter => milestones = filter.type);  
    return milestones.map(milestone => MILESTONE[ESP_MILESTONE_MAPPING[milestone]]);
  }
  
  const getAcquireMilestoneArray = (filters) => {
    let milestones;
    filters.map(filter => milestones = filter.type);
    return milestones.map(milestone => ACQ_MILESTONE.find(val => val.key.toLowerCase() === milestone.toLowerCase()).name);
  }
  
  const getMilestoneStatusArray = (filters) => {
    let status, statusArr = [];
    filters.map(filter => status = filter.value);
    status.forEach(val => [...STATUS_MAP].forEach(([key, value]) => {
      if(value.includes(val)) statusArr.push(key);
    }));
    return [...new Set(statusArr)];
  }
  
  const validateExact = (filters) => {
    if(!filters.hasOwnProperty('exact'))
      return false;
    else 
      return true;
  }
  
  const formatEspFilters = (filters, view) => {
    const { filtersMap, commonFilters } = getFiltersMap(view);
    let filterItem;
    let selectedFilters = {};

    // getnanstartdates is changed to offering status, make sure this chnage is backward compatible
    if(filters['offeringstatus'] == null) {
      filters['getnanstartdates'] = filters['getnanstartdates'] || "false";
    }
    
    Object.keys(filters).forEach(filter => {
      switch(filter){
        case 'keyword':
          filterItem = ESP_KEYWORD.find(val => val.name.toLowerCase() === filters['keyword'].map(filter => filter.type).join('').toLowerCase());
          selectedFilters['keywordType'] = filterItem.name;
          selectedFilters['keywordValue'] = filters['keyword'].map(filter => filter.value.join(' ')).toString();
          selectedFilters['exact'] = validateExact(filters);
          break;
        case 'idparam':
          filterItem =  ((filtersMap.id && filtersMap.id.filters) || []).find(val => val.name.toLowerCase() === filters['idparam'].map(filter => filter.type).join('').toLowerCase());
          selectedFilters['idType'] = filterItem.name;
          selectedFilters['idValue'] = filters['idparam'].map(filter => filter.value.join(' ')).toString();
          break;
        case 'brand':
          filterItem = filters['brand'].map(filter => ((filtersMap.brands && filtersMap.brands.filters) || []).find(val => val.key.toLowerCase() === filter.toLowerCase()));
          selectedFilters['brands'] = filterItem;
          break;
        case 'milestone':
          selectedFilters['milestone'] = getEspMilestoneArray(filters['milestone']);
          selectedFilters['milestoneStatus'] = getMilestoneStatusArray(filters['milestone']);
          break;
        case 'offeringtype':
          filterItem = filters['offeringtype'].map(filter => ((filtersMap.offeringType && filtersMap.offeringType.filters) || []).find(val => val.name.toLowerCase() === filter.toLowerCase()).name);
          selectedFilters['offering'] = filterItem;
          break;
        case 'platform':
          filterItem = filters['platform'].map(filter => ((commonFilters.platforms && commonFilters.platforms.filters) || []).find(val => val.name.toLowerCase() === filter.toLowerCase()).name);
          selectedFilters['platform'] = filterItem;
          break;
        case 'channel':
          filterItem = filters['channel'].map(filter => filter && filter.toUpperCase());
          selectedFilters['channel'] = filterItem;
          break;
        case 'territory':
          filterItem = filters['territory'].map(filter => ((commonFilters.territories && commonFilters.territories.filters) || []).find(val => val.name.toLowerCase() === filter.toLowerCase()));
          selectedFilters['territory'] = filterItem;
          break;
        case 'avod':
          filterItem = filters[filter].map(filter => ESP_AVOD.find(val => val.key.toLowerCase() === filter.toLowerCase()).text);
          selectedFilters[filter] = filterItem.join('');
          break;
        case 'adstatus':
          selectedFilters['adStatus'] =  filters['adstatus'].map(filter => ((filtersMap.adStatus && filtersMap.adStatus.filters) || []).find(val => val.name.toLowerCase() === filter.toLowerCase()).name);
          break;
        case 'fulfillmenttype':
          filterItem =  filters['fulfillmenttype'].map(filter => ((filtersMap.fulfillmentType && filtersMap.fulfillmentType.filters) || []).find(val => val.name.toLowerCase() === filter.toLowerCase()).name);
          selectedFilters['fulfillmenttype'] = filterItem.join('');
          break;
        case 'reasoncodes':
          filterItem =  ((filtersMap.reasonCodes && filtersMap.reasonCodes.filters) || []).find(val => val.name.toLowerCase() === filters['reasoncodes'].join('').toLowerCase());
          selectedFilters['reasonCodes'] = filterItem.name;
          break;
        case 'refulfilled':
          selectedFilters[filter] = filters[filter];
          break;
        case  'seasonName':
          // Alter it based on checked values
          selectedFilters[filter] = (filters[filter].filter(obj => obj.checked)).map(key =>  key.value);
          selectedFilters["seasonData"] = filters[filter].map((obj) => {
            return {
              "key": obj.value,
              "name": obj.value,
              "value": obj.value
            };
          });
          break;
        case 'milestonesearch':
          selectedFilters['milestoneSearch'] = true;
          break;  
        case 'offeringstatus':
          filterItem = filters[filter].map(filter => ESP_OFFERING_STATUS.find(val => val.text.toLowerCase() === filter.toLowerCase()).text);
          selectedFilters['offeringStatus'] = filterItem.join('');
          break; 
        case 'getnanstartdates':
          // TODO - figure out an extensible way to remove this hard coding
          selectedFilters['offeringStatus'] = filters[filter] === "true" ? 'Active + Expired' : 'Active Offerings';  
          break;      
        case 'startdatetime':
        case 'enddatetime':
        case 'updateddatefrom':
        case 'updateddateto':
        case 'assetstartdatefrom':
        case 'assetstartdateto':
          selectedFilters[filter] = Number(filters[filter]);
      }
    })
    return selectedFilters;
  }
  
  const formatAcquireFilters = (filters) => {
    let filtersMap = sessionStore.getItem("filtersMap");
    let acquireMap = (filtersMap && filtersMap.acquire) || {};
    let filterItem;
    let selectedFilters = {}
    
    Object.keys(filters).forEach(filter => {
      switch(filter){
        case 'keyword':
          filterItem = ((acquireMap.keyword && acquireMap.keyword.filters) || []).find(val => val.name.toLowerCase() === filters['keyword'].map(filter => filter.type).join('').toLowerCase());
          selectedFilters['keywordType'] = filterItem.name;
          selectedFilters['keywordValue'] = filters['keyword'][0].value.join(' ');
          selectedFilters['exact'] = validateExact(filters);
          break;
        case 'idparam':
          filterItem =  ((acquireMap.id && acquireMap.id.filters) || []).find(val => val.name.toLowerCase() === filters['idparam'].map(filter => filter.type).join('').toLowerCase());
          selectedFilters['idType'] = filterItem.name;
          selectedFilters['idValue'] = filters['idparam'].map(filter => filter.value.join(' ')).toString();
          break;
        case 'milestone':
          selectedFilters['milestone'] = getAcquireMilestoneArray(filters['milestone']);
          selectedFilters['milestoneStatus'] = getMilestoneStatusArray(filters['milestone']);
          break;
        case 'ingesttype':
          filterItem = filters['ingesttype'].map(filter => ((acquireMap.ingestType && acquireMap.ingestType.filters) || []).find(val => val.key.toLowerCase() === filter.toLowerCase()).name);
          selectedFilters['ingestType'] = filterItem.join('');
          break;
        case 'distributor':
          filterItem = filters['distributor'].map(filter => ((acquireMap.distributors && acquireMap.distributors.filters) || []).find(val => val.name.toLowerCase() === filter.toLowerCase()));
          selectedFilters['distributor'] = filterItem;
          break;
        case 'network':
          filterItem = filters['network'].map(filter => ((acquireMap.networkName && acquireMap.networkName.filters) || []).find(val => val.name.toLowerCase() === filter.toLowerCase()));
          selectedFilters['network'] = filterItem;
          break;
        case 'workflowtype':
          filterItem = filters['workflowtype'].map(filter => ((acquireMap.workflowType && acquireMap.workflowType.filters) || []).find(val => val.name.toLowerCase() === filter.toLowerCase()).name);
          selectedFilters['workflowType'] = filterItem;
          break;
        case 'milestonesearch':
          selectedFilters['milestoneSearch'] = true;
          break;
        case 'acquirestartdatetime':
        case 'acquireenddatetime':
        case 'acquireupdateddatefrom':
        case 'acquireupdateddateto':
          selectedFilters[filter] = Number(filters[filter]);
      }
    })
    return selectedFilters;
}
  
export function formatFilters(view, filters){
    if(view == 'esp' || view === 'avp'){
      return formatEspFilters(filters, view);
    }else{
      return formatAcquireFilters(filters);
    }
}
  