import { AppBar, Button, IconButton, Link, Toolbar } from "@material-ui/core";

import { LogoutIcon } from "../atoms/Icons";
import { HelpIcon } from "../atoms/Icons";
import { Menu as MenuIcon } from "@material-ui/icons";
import PropTypes from "prop-types";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useSecurity from '../security/useSecurity';
import { steel, neutral } from "../templates/palette.json";
import storage from "../../utils/storage";
import usePreference from "../preference/usePreference";

const sessionStore = storage('session');
const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundImage: `url(/assets/svg/menu-background-${theme.palette.type}.svg)`,
    // backgroundColor: theme.palette.primary.main,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    boxShadow: theme.palette.type === 'dark' ?
      '0px 0px 10px #121313' : '0px 4px 15px rgba(18, 19, 19, 0.1)',
    zIndex: theme.zIndex.drawer + 1,
    // transition: theme.transitions.create(["width", "margin"], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
  },
  brandIcon: {
    backgroundImage: `url(/assets/svg/brand-${theme.palette.type}.svg)`,
    backgroundRepeat: "no-repeat",
    flexGrow: 1,
    height: 20,
    width: 70,
  },
  menuButton: {
    marginRight: 0,
  },
  title: {
    flexGrow: 1,
  },
  toolbar: {
    padding: '0 16px',
    height: 48,
    minHeight: 0,
    boxShadow: theme.palette.type === 'dark' ? '0px 0px 10px #121313' : '0px 4px 15px rgba(18, 19, 19, 0.1)'
  },
  logoutButton: {
    color: theme.palette.type === 'dark' ? steel[300] : steel[600],
    backgroundColor: theme.palette.primary.main,
    cursor: 'none',
    boxShadow: 'none',
    padding: 0,
    lineHeight: '16px',
    fontSize: 12,
    marginRight: 10
  },
  menuIconButton: {
    fontSize: 24,
    color: theme.palette.type === 'dark' ? neutral[0] : steel[600]
  },
  logoutButtonIcon: {
    height: 16,
    width: 16
  },
  helpButtonIcon: {
    color: 'Steel 100: #D0D9E2',
    height: 16,
    width: 16,

  },
  helpBotton: {
    backgroundColor: theme.palette.primary.main,
    cursor: 'none',
    boxShadow: 'none',
    padding: 0,
    lineHeight: '16px',
    fontSize: 12,
    marginRight: '16px'
  },
  help: {
    color: theme.palette.type === 'dark' ? steel[300] : steel[600],
    backgroundColor: theme.palette.primary.main,
    textDecoration: 'none',
    "&:hover": {
      color: theme.palette.type === 'dark' ? steel[300] : steel[600],
      backgroundColor: theme.palette.primary.main,
      textDecoration: 'none',
    }

  },
}));

TopBar.propTypes = {
  drawerHandler: PropTypes.func.isRequired,
};

export default function TopBar({ drawerHandler, children }) {
  const classes = useStyles();
  const { authService, isAuthenticated } = useSecurity();
  const { getUsersPrefesMode } = usePreference();

  const logout = async () => {
    authService && authService.signOut('/') && sessionStore.removeItem("idToken");
  }

  // redirect to help page indicating darkmode flag
  const redirectHelp = async () => {
    const darkStorage = getUsersPrefesMode();
    /* encode */
    const helpUrl =`/help/${btoa(darkStorage)}`;
    window.open(helpUrl, '_blank');
  }

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar} disableGutters>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open menu"
          onClick={drawerHandler}
          className={classes.menuButton}
        >
          <MenuIcon className={classes.menuIconButton} />
        </IconButton>
        <Link className={classes.brandIcon} > </Link>
        <Button color="inherit" aria-label="helpicon" startIcon={<HelpIcon className={classes.helpButtonIcon} />} className={classes.helpButton}>
          <a onClick={redirectHelp} className={classes.help}>Help & Docs</a>
        </Button>
        <Button color="inherit" aria-label="logout" onClick={logout} startIcon={<LogoutIcon className={classes.logoutButtonIcon} />} className={classes.logoutButton}>
          Logout
        </Button>
      </Toolbar>
      <div></div>
    </AppBar>
  );
}
