import { useState } from 'react';
import storage from './storage';
import useAxios from './axiosImpl';
import apiMap from './api';
import _ from "lodash";
import { addMonths, getTime} from "date-fns";
import { getMessage } from '../constants/messages';
import { STATUS_MAP, MILESTONE_MAP, MILESTONE, ESP_MILESTONE, ASSET_MILESTONE, TEAM_MILESTONE_MAPPING, ACQ_MILESTONE, ESP_KEYWORD, ESP_AVOD, ESP_MILESTONE_MAPPING } from '../constants/search-constants';
import MappingForSearchIdMap from "../utils/db-map/mappingForSearchIdMap";
const sessionStore = storage('session');


export const getFiltersMap = (view  = "esp") => {
  const filtersMap = sessionStore.getItem('filtersMap');
  let map;
  switch (view) {
    case 'esp':
      map = filtersMap && filtersMap.scheduledOfferings || {};
      break;
    case 'avp':
      map = filtersMap && filtersMap.asset || {};
      break;
  }
  return { Map: map }
}

function compare(obj1, obj2) {
  return (obj1.name || '').toLowerCase() > (obj2.name || '').toLowerCase() ? 1 : -1;
}

function getMap(name, filtersMap, teamsColumns) {
  switch (name) {
    case 'esp':
      let espMap = (filtersMap && filtersMap.scheduledOfferings) || {};
      let platformChannelMap = filtersMap && filtersMap.common;
      let channelMap = platformChannelMap.channels && platformChannelMap.channels.filters;
      let territoryFilters = platformChannelMap.territories && platformChannelMap.territories.filters;
      return {
        milestone: filterTeamMilestone(ESP_MILESTONE, teamsColumns) || [],
        id: (espMap.id && espMap.id.filters) || [],
        brands: (espMap.brands && espMap.brands.filters) || [],
        offering: (espMap.offeringType && espMap.offeringType.filters.sort(compare)) || [],
        platform: (platformChannelMap.platforms && platformChannelMap.platforms.filters) || [],
        channel : getCombinedChannelMap(channelMap) || [],
        territory : getCombinedTerritoryMap(territoryFilters) || [],
        adStatus: (espMap.adStatus && espMap.adStatus.filters) || [],
        reasonCodes: (espMap.reasonCodes && espMap.reasonCodes.filters) || [],
        fulfillmentType: (espMap.fulfillmentType && espMap.fulfillmentType.filters) || [],
        offeringStatus: (espMap.offeringStatus && espMap.offeringStatus.filters) || [],
      }
    case 'acquire':
      let acquireMap = (filtersMap && filtersMap.acquire) || {};
      return {
        milestone: filterTeamMilestone(ACQ_MILESTONE, teamsColumns) || [],
        keyword: (acquireMap.keyword && acquireMap.keyword.filters) || [],
        id: (acquireMap.id && acquireMap.id.filters) || [],
        distributor: (acquireMap.distributors && acquireMap.distributors.filters.sort(compare)) || [],
        network: (acquireMap.networkName && acquireMap.networkName.filters.sort(compare)) || [],
        ingestType: (acquireMap.ingestType && acquireMap.ingestType.filters) || [],
        workflowType: (acquireMap.workflowType && acquireMap.workflowType.filters) || []
      }
    case 'avss':
      let avssMap = (filtersMap && filtersMap.asset) || {};
      platformChannelMap = filtersMap && filtersMap.common;
      channelMap = platformChannelMap.channels && platformChannelMap.channels.filters;
      let avsTerritoryFilters = platformChannelMap.territories && platformChannelMap.territories.filters;
      return {
        keyword: (avssMap.keyword && avssMap.keyword.filters) || [],
        milestone: filterTeamMilestone(ASSET_MILESTONE, teamsColumns) || [],
        id: (avssMap.id && avssMap.id.filters) || [],
        platform: (platformChannelMap.platforms && platformChannelMap.platforms.filters) || [],
        channel : getCombinedChannelMap(channelMap) || [],
        territory: getCombinedTerritoryMap(avsTerritoryFilters) || [],
        adStatus: (avssMap.adStatus && avssMap.adStatus.filters) || [],
        fulfillmentType: (avssMap.fulfillmentType && avssMap.fulfillmentType.filters) || []
      }
    default:
      break;
  }
  return {};
}

export function setFiltersMap(data) {
  const filterMap = createSearchIdMap(data);
  sessionStore.setItem("filtersMap", filterMap);
}
export function setColumnConfig(data) {
  sessionStore.setItem("columnConfig", data);
}

export function getMileStoneStatus(status) {
  let milstoneStatus = [];
  status && status.forEach((status) => {
      if (STATUS_MAP.has(status))
      milstoneStatus = [...milstoneStatus, ...STATUS_MAP.get(status)];
  })
  return milstoneStatus;
}

export function getMilestone(milestones) {
  let milestoneArr = [];
  milestones && milestones.forEach((milestone) => {
    if(MILESTONE_MAP.has(milestone)) {
      milestoneArr = [...milestoneArr, MILESTONE_MAP.get(milestone)];
    }
  });
  return milestoneArr;
}

export const validateState = (filterState, filters) => {
  if (filterState.platform && filterState.platform.length > 0) {
      if (filterState.channel && filterState.channel.length > 0) {
          let tempChannel = [];
          filterState.platform.forEach(sPlatform => {
              filterState.channel.forEach(sChannel => {
                  const el = filters.channel.find(
                      el => el.type.toLowerCase() == sPlatform.toLowerCase() && el.name.toLowerCase() == sChannel.toLowerCase()
                  );
                  el && tempChannel.push(el.name);
              });
          });
          if (filterState.channel.length > tempChannel.length) filterState.channel = tempChannel;
          if (filterState.territory && filterState.territory.length > 0) {
              let tempterritory = [];
              filterState.channel.forEach(schannel => {
                  filterState.territory.forEach(sterritory => {
                      const el = filters.territory.find(
                          el => el.type.toLowerCase() == schannel.toLowerCase() && el.name.toLowerCase() == sterritory.name.toLowerCase()
                      );
                      el && tempterritory.push(el.name);
                  });
              });
              if (filterState.territory.length > tempterritory.length) filterState.territory = tempterritory;
          }
      }
  }
};

function getCombinedChannelMap(channelMap){
  let combinedChannelMap = [];
  channelMap.forEach(item => {
    for(const data in item){
      let platformObject = item[data];
      for(const channelObject in platformObject){
          let temp = platformObject[channelObject];
          temp.type = data;
          combinedChannelMap.push(temp);
      }
    }
  });
  return combinedChannelMap;
}

function getCombinedTerritoryMap(territoryMap) {
    let combinedTerritoryMap = [];
    // territoryMap = dummy_territories;
    // console.log('territoryMap:', territoryMap)
    territoryMap &&
        territoryMap.forEach(item => {
            item &&
                item.channels &&
                item.channels.forEach(channel => {
                    let obj = Object.assign({}, item);
                    obj.type = channel;
                    combinedTerritoryMap.push(obj);
                });
        });
    return combinedTerritoryMap;
}

const toggleCategory = (value, option, list, selectAll)  => {
  const selectedCategory = list.find(obj => obj.name == option).categories || [];
  
  let selectedList = value.filter(option => {
      if(option.name && (option.name.toLowerCase() === selectAll.toLowerCase())) return false;
      return !(option.categories.some(category => selectedCategory.includes(category) && option.isCategory == true))
  })

  const categoriesSelected = selectedList.filter(option => option.categories.some(category => selectedCategory.includes(category))).length;
  let allCategories = list.filter(option => option.categories.some(category => selectedCategory.includes(category))).length;
  
  if(categoriesSelected === (--allCategories)) {
    const category = list.filter(option => option.categories.some(category => selectedCategory.includes(category) && option.isCategory == true))
    selectedList.push(...category);
  }

  if(selectedList.length === (--list.length)) {
    const category = list.filter(option => option.name === selectAll);
    selectedList.push(...category);
  }
  return selectedList;
}

const handleCategory = (value, category, list, selectAll) => {
  const selectedCategories = value.filter(option => option.isCategory).map(option => option.name);
  const isCategory = value.find(option => option.name && (option.name.toLowerCase() === category.toLowerCase()));
  
  if(isCategory) {
      let categoryList = list.filter(option => option.categories.some(category => selectedCategories.includes(category)))
      value = [...new Set([...value, ...categoryList])];

      if(value.length === (--list.length)) {
        const selectAllCategory = list.filter(option => option.name === selectAll);
        value.push(...selectAllCategory);
      }

      return value;
  }
  return value.filter(option => {
      if(option.name && (option.name.toLowerCase() === selectAll.toLowerCase())) return false;
      return !option.categories.includes(category)
  }); 
}

const handleSelectAll = (selectedList, selectAll, list) => {
  const isSelectAll = selectedList.find(option => option.name && (option.name.toLowerCase() === selectAll.toLowerCase()));
  return isSelectAll ? list : [];
}

export const groupTerritories = (event, selectedList, list, selectAll) => {
  let selectedOption = event.currentTarget.innerText;
  const categories = list.map(item => (item.isCategory === true) && item.name ).filter(Boolean);
  
  if(selectedOption === selectAll)
    return handleSelectAll(selectedList, selectAll, list);
  else if(categories.includes(selectedOption)) 
    return handleCategory(selectedList, selectedOption, list, selectAll);
  else
    return toggleCategory(selectedList, selectedOption, list, selectAll);
}

function filterTeamMilestone(milestones, teamsColumns) {
  if(!teamsColumns || teamsColumns.length === 0) return milestones;
  let map = [];
  milestones.map((milestone) => {
    let mappedMilestone = TEAM_MILESTONE_MAPPING[milestone.key]
    if(teamsColumns.indexOf(mappedMilestone) > -1) map.push(milestone.key);
  });
  let updatedMilestones = [];
  map.forEach((milestone) => {
    milestones.forEach((espMilestone) =>{
      if (espMilestone.key === milestone) updatedMilestones.push(espMilestone);
    })
  });
  return updatedMilestones;
}

export function useFiltersMap(initialFilters = {}, name) {
  const [map, setMap] = useState(initialFilters);
  const [error, setError] = useState(null);
  const { get } = useAxios();

  async function fetchFiltersMap(teamsColumns) {
    if (typeof name != "string") {
      setError("Name must be a string");
      return;
    }

    let filtersMap = sessionStore.getItem("filtersMap");
    let columnConfig = sessionStore.getItem("columnConfig");
    if (!filtersMap || !columnConfig) {
      try {
        let appconfig = await get({ url: apiMap.filtersMap });
        filtersMap = appconfig.filterMap;
        columnConfig = appconfig.columnConfig;
        if(!(filtersMap instanceof Object) || !(columnConfig instanceof Object)) return;
        filtersMap && setFiltersMap(filtersMap);
        columnConfig && setColumnConfig(columnConfig);
      } catch(err) {
        setError("Unable to fetch filters map");
        return;
      }
    }

    let filters = getMap(name, filtersMap, teamsColumns);

    if(name === 'esp') {
      filters && setMap({ ...map, milestone: filters.milestone, id: filters.id, brands: filters.brands, offering: filters.offering, platform: filters.platform, channel: filters.channel, territory: filters.territory, adStatus: filters.adStatus, reasonCodes: filters.reasonCodes, fulfillmentType: filters.fulfillmentType, offeringStatus: filters.offeringStatus });
    }
    if(name === 'acquire') {
      filters && setMap({
        ...map,
        milestone: filters.milestone,
        id: filters.id,
        keyword: filters.keyword,
        distributor: filters.distributor,
        network: filters.network,
        ingestType: filters.ingestType,
        workflowType: filters.workflowType 
      });
    }
    if(name === 'avss') {
      filters && setMap({ 
        ...map, 
        keyword: filters.keyword,
        milestone: filters.milestone, 
        id: filters.id, 
        platform: filters.platform, 
        channel: filters.channel,
        territory: filters.territory,
        adStatus: filters.adStatus,
        fulfillmentType: filters.fulfillmentType
      });
    }
  }

  return {
    filters: map,
    error,
    fetchFiltersMap
  }
};

export function checkReportCount(count, setToastState){
  if(!count) {
    setToastState({
      open: true,
      message: getMessage('filter.emptyReport'),
      autoHideDuration: 3000,
      ClickAwayListenerProps: { mouseEvent: false }
    });
    return true;
  }
  return false;
}

export function validateDateRestiction(from, to, {maxRangeInMonths = 2, skip = false}) {
  const response = {
    message: null,
    isValid: true
  };

  if(skip) {
    return response;
  }

  // if(from && to) {
  //   const expected = getTime(addMonths(from, maxRangeInMonths));
  //   const actual = getTime(to);
  //   if(actual > expected) {
  //     response.message = getMessage('filter.rangeExceeded', { range : maxRangeInMonths });
  //     response.isValid = false;
  //   }
  // }

  if(from == null && to == null) {
    response.message = getMessage('filter.bothMissing');
    response.isValid = false;
  }

  // if( from ? !to : to) {
  //   response.message = getMessage('filter.oneMissing');
  //   response.isValid = true;
  // }

  return response;
}

export const isMilestoneSearchEnabled = (params, setToastState) => {
  if(params.milestoneSearch && (!params.milestone.length || !params.milestoneStatus.length)) {
    setToastState({
      open: true,
      message: getMessage('filter.milestoneMissing'),
      autoHideDuration: 3000,
      ClickAwayListenerProps: { mouseEvent: false }
    });
    return true;
  }
  return false;
}

export const handleSeasonData = (payload, seasonData = [], showSeasonsDropdown = false) => {
  const customizedSeasonName = showSeasonsDropdown && seasonData.map((data) => {
    return payload.seasonName && Array.isArray(payload.seasonName) && payload.seasonName.indexOf(data.value) > -1 ? {
      "value": data.value,
      "checked": true
    } : {
      "value": data.value,
      "checked": false
    };
  });
  if (Array.isArray(customizedSeasonName) && customizedSeasonName.length > 0) {
    delete payload.seasonName;
    payload.seasonName = customizedSeasonName;
  }
  return payload;
}

export const handleMilestoneSearchParams = (payload) => {
  if(payload.milestonesearch && !payload.idparam) {
    if(payload.startdatetime) payload['updateddatefrom'] = payload.startdatetime;
    if(payload.enddatetime) payload['updateddateto'] = payload.enddatetime;
    if(payload.assetstartdatefrom) payload['updateddatefrom'] = payload.assetstartdatefrom;
    if(payload.assetstartdateto) payload['updateddateto'] = payload.assetstartdateto;
    if(payload.acquirestartdatetime) payload['acquireupdateddatefrom'] = payload.acquirestartdatetime;
    if(payload.acquireenddatetime) payload['acquireupdateddateto'] = payload.acquireenddatetime;

    if(!payload.idparam) {
      delete payload.startdatetime;
      delete payload.enddatetime;
      delete payload.assetstartdatefrom;
      delete payload.assetstartdateto;
      delete payload.acquirestartdatetime;
      delete payload.acquireenddatetime;
    }
  }
  return payload;
}
/**
 * startdatetime and enddatetime  not required for few conditions 
 * @param {*} payload 
 * @returns 
 */
 export const modifySharedFiltesForGrid=(payload) =>{
  if ((payload.hasOwnProperty('keyword') && JSON.parse(payload.keyword).name === 'id') || (payload.hasOwnProperty('idparam'))) {
    payload.startdatetime = 0;
    delete payload.enddatetime;
  }
  return payload;
}

export const handleSkipDate = (payload) => {
  const skipDateRestriction = payload.hasOwnProperty('idparam');
  if(skipDateRestriction) {
    if(payload.hasOwnProperty('enddatetime')) {
      payload.startdatetime = 0;
      delete payload.enddatetime;
    } else if(payload.hasOwnProperty('acquireenddatetime')) {
      payload.acquirestartdatetime = 0;
      delete payload.acquireenddatetime;
    } else if(payload.hasOwnProperty('assetstartdateto')) {
      payload.assetstartdatefrom = 0;
      delete payload.assetstartdateto;
    }
  }
  return payload;
}

export const handleDependentDropdown = (list, dependentDropdown) => {
  let dataList = [];
  if (dependentDropdown) {
    list && list.forEach(element => {
      let el = dependentDropdown.find(item => item.toLowerCase() == element.type.toLowerCase());
      el && dataList.push(element);
    });
    if (dataList.length === 0) {
      // selectAll && setSelectAll(false);
      return null;
    }
  }
  let uniqueList = dependentDropdown ? (dependentDropdown.length == 1 ? dataList : []) : list;
  if (dependentDropdown && dependentDropdown.length > 1) {
    dataList && dataList.forEach(element => {
      let el = uniqueList.find(item => item.name == element.name);
      !el && dependentDropdown.find(item => item.toLowerCase() == element.type.toLowerCase()) && uniqueList.push(element);
    });
  }
  return uniqueList;
}

const getTrimValue = (value) => {
  let arr = [];
  value && value.split(' ').map(val => {
    if(val) arr.push(val);
  });
  return arr;
}

export const validateKeywordIdLength = (payload, setToastState) => {
  if(payload['keyword']){
    const LIMIT = 20;
    let obj = JSON.parse(payload['keyword']);
    let value = getTrimValue(obj.value);
    if(value.length > LIMIT){
      value = value.slice(0, LIMIT);
      setToastState({open: true, message: `Number of keywords exceed the maximum range of keyword search. Returned results will display first ${LIMIT} keyword searches`,autoHideDuration: 3000, ClickAwayListenerProps: {'mouseEvent':'onClick'} });
    }
    obj.value = value.join(' ');
    payload['keyword'] = JSON.stringify(obj);
  }
  if(payload['idparam']){
    let obj = JSON.parse(payload['idparam']);
    let key = Object.keys(obj)[0];
    const LIMIT = key === 'All' ? 10 : 50;
    let value = getTrimValue(obj[key]);
    if(value.length > LIMIT){
      value = value.slice(0, LIMIT);
      setToastState({open: true, message: `Number of IDs exceed the maximum range of ID search. Returned results will display first ${LIMIT} ID searches`,autoHideDuration: 3000, ClickAwayListenerProps: {'mouseEvent':'onClick'} });
    }
    obj[key] = value.join(' ');
    payload['idparam'] = JSON.stringify(obj);
  }
  return payload;
}

export const setRbacFilters = (appConfigFilters, teamsFilters) => {
  Object.keys(teamsFilters).forEach(key => {
    appConfigFilters[key] = (teamsFilters[key]?.["name"]?.[0])
      ? appConfigFilters[key]?.filter(param => teamsFilters[key]?.["name"]
        ?.map((item) => item.toLocaleLowerCase()).includes(param.key.toLocaleLowerCase()))
      : appConfigFilters[key];
  });
}

export function getAllAppliedFilterObjectData(filterState) {
  let filterObj = {};
  if (filterState.keywordType !== '') {
    filterObj.keyword = filterState.keywordType;
  }
  if (filterState.idType !== '') {
    filterObj.id = filterState.idType;
  }
  if(filterState.adStatus && filterState.adStatus.length > 0){
    filterObj.adStatus = filterState.adStatus;
  }
  if(filterState.refulfilled){
    filterObj.refulfilled = filterState.refulfilled;
  }
  if(filterState.refulfilled && filterState.reasonCodes !== '' ){
    filterObj.reasonCodes = filterState.reasonCodes;
  }
  if(filterState.avod != ''){
    filterObj.avod = filterState.avod;
  }
  if(filterState.fulfillmentType !== ''){
    filterObj.fulfillmentType = filterState.fulfillmentType;
  }
  if(filterState.brands && filterState.brands.length > 0){
    filterObj.brands = filterState.brands;
  }
  if (filterState.milestone && filterState.milestone.length > 0) {
    filterObj.milestone = filterState.milestone;
    if (filterState.milestoneStatus && filterState.milestoneStatus.length > 0) {
      filterObj.milestoneStatus = filterState.milestoneStatus;
    }
  }
  if (filterState.offering && filterState.offering.length > 0) {
    filterObj.offeringType = filterState.offering;
  }
  if (filterState.platform && filterState.platform.length > 0) {
    filterObj.platform = filterState.platform;
      if (filterState.channel && filterState.channel.length > 0) {
        filterObj.channel = filterState.channel;
          if (filterState.territory && filterState.territory.length > 0) {
            filterObj.territory = filterState.territory;
          }
      } else {
          filterState.territory = [];
      }
  } else {
      filterState.channel = [];
      filterState.territory = [];
   }
  if(filterState.exact){
    filterObj.exact = filterState.exact;
  }
  if(filterState.allOfferings){
    filterObj.allOfferings = filterState.allOfferings;
  }
  if(filterState.milestoneSearch){
    filterObj.milestoneSearch = filterState.milestoneSearch;
  }
  if (filterState.offeringStatus) {
    filterObj.offeringStatus = filterState.offeringStatus;
  }
  
  return filterObj;
}

const createSearchIdMap = (filtersMap) => {
  const clonedObj = JSON.parse(JSON.stringify(filtersMap))
  let searchIdMap = new MappingForSearchIdMap(clonedObj);
  
  _.set(filtersMap, 'scheduledOfferings', searchIdMap.scheduledOfferingsMap);
  _.set(filtersMap, 'asset', searchIdMap.assetMap);

  return filtersMap;
}

// export async function getFiltersMap(name) {
//   if (typeof name != "string") throw TypeError("Name must be a string");
//   let filtersMap = sessionStore.getItem("filtersMap");
//   if (!filtersMap) {
//     filtersMap = await axios.get({ url: apiMap.filtersMap });
//     filtersMap && setFiltersMap(filtersMap);
//   }
//   return getMap(name, filtersMap);
// }