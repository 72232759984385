import React, {useContext, useEffect, useState} from "react";
import {
  Dialog,
  Fade,
} from "@material-ui/core";
import _ from "lodash";
import DetailsModalContent from "../organisms/DetailsModalContent";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import useAxios from "../../utils/axiosImpl";
import {PageContext} from "../../services/PageProvider";
import MappingForAcquireObject from "../../utils/data-map/mappingForAcquireObject";
import MappingForEspObject from "../../utils/data-map/mappingForEspObject";
import MappingForAssetObjects from "../../utils/data-map/mappingForAssetObjects";
import {setDefault} from "../../utils/data-map/default";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    alignSelf:'flex-start',
    marginTop: 65
  },
  backdrop: {
    backgroundColor: "rgba(0,0,0,0.2)"
  },
  container: {
    width: "100%"
  },
  root: {
    width: "100%",
    maxWidth: "100% !important"
  },
  conditionalRoot: {
    width: "100%",
    maxWidth: "1100px !important"
  }
}));

export default function DetailsModal(props) {
  const [dataState, setDataState] = useState(props.data);
  const [loadingState, setLoadingState] = useState(false);
  const [eventData, setEventData] = useState({});
  const [errorState, setErrorState] = useState();
  const classes = useStyles();
  const {open, handleOpen, fullScreen,detailId, includeOfferings} = props;
  const {get} = useAxios();
  let { pageState, setPageState} = useContext(PageContext);
  const workflowDirectory = getWorkflowDirectory();
   useEffect(() => {
    if (fullScreen || workflowDirectory =='espEvents') {
      const reloadTimer = 2 * (60000);

      setLoadingState(true);
      fetchALL();

      const interval = setInterval(() => {
      fetchALL();
      }, reloadTimer);
      return () => clearInterval(interval);
    }
    else{
        setDataState(props.data);
    }
  }, [fullScreen,props]);

  function render() {
    if (errorState) return <p>{errorState}</p>
    if (!open || _.isEmpty(dataState)) return null;

    const newProps = {...props, data: generateModalData(dataState), eventData:eventData, includeOfferings:props.includeOfferings};

    return (
      <Dialog
          open={open}
          onClose={handleOpen(false)}
          className={classes.modal}
          classes={{
            container: classes.container,
            paper: fullScreen ?  classes.root : classes.conditionalRoot
          }}
          closeAfterTransition
          fullScreen={fullScreen}
          maxWidth="md"
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          BackdropProps={{className: classes.backdrop}}
      >
        <Fade in={open}>
          <DetailsModalContent {...newProps} refreshState={props.refreshState}  fullScreen={fullScreen}  workflowDirectory = {workflowDirectory}/>
        </Fade>
      </Dialog>
    );
  }

  function getWorkflowDirectory() {
    pageState = props.workflow;

    switch (pageState) {
      case "acquire":
      case "acquireBasic":
      case "acquireAdvanced":
        return "acquireEvents";
      case "scheduledOfferingsBasic":
      case "scheduledOfferingsAdvanced":
        return "espEvents";
      case "assetViewAdvanced":
      case "assetViewBasic":
        return "assetViewEvents";
      default: {
        setErrorState("Workflow type not found");
        return "espEvents";
      }
    }
  }

  function generateModalData(data) {
        const acquireViewsArray = ["acquire", "acquireBasic", "acquireAdvanced"];
        const scheduledOfferingsArray = ["scheduledOfferingsAdvanced", "scheduledOfferingsBasic"];
        const assetViewArray = ["assetViewAdvanced", "assetViewBasic"];
        if (fullScreen) {
          setPageState(props.workflow);
        }
        if (fullScreen || workflowDirectory=='espEvents') {
          if (acquireViewsArray.indexOf(props.workflow) > -1) {
              const dataMap = new MappingForAcquireObject(data);
              const modalData = props.workflow === 'acquireBasic' ? dataMap.modalDataBasic : dataMap.modalDataAdvanced;
              return setDefault(modalData);
          } else if (scheduledOfferingsArray.indexOf(props.workflow) > -1) {
            const dataMap = new MappingForEspObject(data);
            const modalData = props.workflow === 'scheduledOfferingsBasic' ? dataMap.modalDataBasic : dataMap.modalDataAdvanced;
            return setDefault(modalData);
          } else if (assetViewArray.indexOf(props.workflow) > -1) {
            const dataMap = new MappingForAssetObjects(data);
            const modalData = props.workflow === 'assetViewBasic' ? dataMap.modalDataBasic : dataMap.modalDataAdvanced;
            return setDefault(modalData);
          }
        }
        return data;
  }
  const fetchALL=()=>{
    if(workflowDirectory =='espEvents'){
      fetchDetails()
    }else {
      fetchData()
    }
  }
  const fetchDetails =()=>{
    const params = {
      idparam: JSON.stringify({ "Offering Id": props.detailId })
    }
    if (props.includeOfferings) {
      params['includeOfferings'] = props.includeOfferings;
    }
    get({
      url: `/v1/${workflowDirectory}/details`,
      params
    })
      .then((response) => {
        response && setEventData(response);
        response && setDataState(response.records[0]);
      })
      .catch((error) => error && setErrorState(error.message))
      .finally(() => setLoadingState(false));
  }
  function fetchData() {
    const workflowDirectory = getWorkflowDirectory();
    get({
      url: `/v1/${workflowDirectory}/id`,
      params: {
        id: props.detailId,
        skipCaseMapping: true
      }
    })
    .then((response) => response && setDataState(response))
    .catch((error) => error && setErrorState(error.message))
    .finally(() => setLoadingState(false));
  }
 
  return render();
}

DetailsModal.defaultProps = {
  data: {},
  fullScreen: false,
  handleOpen: _.noop
};

DetailsModal.propTypes = {
  data: PropTypes.shape({
    generalInformation: PropTypes.arrayOf(PropTypes.array),
    process: PropTypes.shape({
      id: PropTypes.string,
      milestones: PropTypes.any,
    }),
    title: PropTypes.string,
  }),
  handleOpen: PropTypes.func,
  open: PropTypes.bool,
};
