import { Security } from "@okta/okta-react";
import {
  OktaAuth, toRelativeUrl
} from '@okta/okta-auth-js';
import { useHistory } from "react-router-dom";
import React, {
  Props,
  createContext,
  useContext,
  useEffect,
  useState
} from "react";
import { ConfigContext } from "./ConfigContext";
import { CurrentUserProvider } from "./SecurityContext";
import storage from "../../utils/storage";
import ProgressBar from "../../utils/style-utils";


const sessionStore = storage('session');

export const SecurityContext = createContext<[boolean | null, string[], any]>([null, null, null]);

export default function WrappedSecurity(props: Props<any>) {
  const [config, isConfigLoading] = useContext(ConfigContext);
  const [isSecurityLoaded, setIsSecurityLoaded] = useState(false);
  const [userScopes, setUserScopes] = useState([]);
  const history = useHistory();


  // update localStorage to keep the flag during page reloads
  useEffect(() => {
    localStorage.setItem("isSecurityLoaded", isSecurityLoaded ? 'true' : 'false');
  }, [isSecurityLoaded]);

  // Show loading spinner
  if (isConfigLoading) {
    return (<ProgressBar></ProgressBar>);
  } else {
    const fetchNewScopesForUser = async ({ isAuthenticated, ...rest }) => {
      console.log("fetchNewScopesForUser");
      // get new accessToken with more scopes      
      if (isAuthenticated) {
        const idToken = await oktaAuth.tokenManager.get("idToken");
        // get "group" claim
        //const userGroupList = ["*Admin (HBO)"];
        const userGroupList: string[] = idToken?.claims?.[config?.groupClaimName];
        if (!userGroupList) {
          throw new Error(`${config?.groupClaimName} not found in idToken`)
        }

        // get list of Scopes
        let scopes: string[] = config?.oidc?.scopes;

        // save the user's scopes for the <Can> component
        setUserScopes(scopes)

        // eslint-disable-next-line
        // const { code, state, tokens } = await authService._oktaAuth.token.getWithPopup({
        try {
          // eslint-disable-next-line
          const { code, state, tokens } = await oktaAuth.token.getWithoutPrompt({
            scopes,
            responseType: ["token"],
            pkce: false
          });
          oktaAuth.tokenManager.add("accessToken", tokens.accessToken);
          setIsSecurityLoaded(true);
        } catch (error) {
          console.log(" try block of fetchNewScopesForUser:", error);
          //  alert("Please enable '3rd Party Cookies' in your Browser Settings. Then reload the window.")
        } finally {
          oktaAuth.authStateManager.unsubscribe(fetchNewScopesForUser);
        }
      } else {
        // not Authenticated  
        //setUserScopes([]);
        setIsSecurityLoaded(false);
        oktaAuth.authStateManager.unsubscribe(fetchNewScopesForUser);
      }

    };

    const oktaAuth = new OktaAuth(config?.oidc);
    oktaAuth.authStateManager.subscribe(fetchNewScopesForUser);
    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
      history.replace(toRelativeUrl((originalUri || '/'), window.location.origin));
    };

    return (
      <SecurityContext.Provider value={[isSecurityLoaded, userScopes, config]}>
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <CurrentUserProvider>{props.children}</CurrentUserProvider>
        </Security>
      </SecurityContext.Provider>
    );
  }
}
