import local from "./local.config";

const _default = {
  oidc: {
    redirectUri: `${window.location.protocol}//${window.location.host}/implicit/callback`,
    scopes: ["openid", "profile", "email", "disco-email"],
    pkce: true,
    disableHttpsCheck: false,
    tokenManager: {
      expireEarlySeconds: 120,
    }
  },
  groupClaimName: "mscp.disco.groups"
};

const config = function () {
  if(window.location.href.indexOf('localhost') > -1) return local;
  return {};
}()

export default {
  ..._default,
  ...config
 };
