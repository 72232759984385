import _ from 'lodash';

const messages = {
  'filter.bothMissing': 'At least one date must be selected to return results.',
  'filter.rangeExceeded': 'The amount of data requested exceeds ${range} months of data, please try again.',
  'filter.oneMissing': 'The Downloaded Report supports two months of data per download.',
  'filter.countExceeded': 'Based on the date range selected, only 10K records can be downloaded in the export. Please adjust the date range to obtain remaining records.',
  'filter.milestoneMissing': 'Milestone Search supports search for one milestone and status combination. Please update your search criteria to select one milestone and status',
  'filter.emailReport': 'Your export request will be sent to your email shortly',
  'filter.emptyReport': 'Unable to export as no data is available'
}

const compiled = _.mapValues(messages, _.template);

export function getMessage(key, keyValue = {}) {
  if (!_.has(messages, key)) {
    console.error(`Message not found with key [${key}]`)
    return;
  }
  return compiled[key](keyValue);
}