import _ from "lodash";

class MappingForAssetModalDetailView {
    private readonly data: any;
    private readonly submilestones: object;

    constructor(data: object, submilestones: {}) {
        this.data = data;
        this.submilestones = submilestones;
    }

    get mediaRequestPublished(): object {
        return _.get(this, 'data.mediaRequestPublished');
    }

    get fetchVersion(): object {
        return _.get(this, 'data.fetchVersion');
    }

    get fetchTitle(): object {
        return _.get(this, 'data.fetchTitle');
    }

    get qmAvailable(): object {
        return _.get(this, 'data.qmAvailable');
    }

    get renderMezz(): object {
        return _.get(this, 'data.renderMezz');
    }

    get captionConvert(): object {
        return _.get(this, 'data.captionConvert');
    }

    get transferMedia(): object {
        return _.get(this, 'data.transferMedia');
    }

    get transferMeta(): object {
        return _.get(this, 'data.transferMeta');
    }

    get avodGating(): object {
        return _.get(this, 'data.avodGating');
    }

    get encodeRequest(): object {
        return _.get(this, "data.contentSubscriptionRequest");
    }

    get cam(): object {
        return _.get(this, 'data.contentAvailabilityMessage');
    }

    get derivativeCreation(): object {
        return _.get(this, 'data.derivativeCreation');
    }

    get manualQC(): object {
        return _.get(this, 'data.manualQC');
    }

    get encodeFulfilment(): object {
        return _.get(this, 'data.encodeFulfilment');
    }

    get tabData(): object {
        let encodeStatus = _.get(this, "submilestones.mediaRequestPublished.encodeRequest.value");

        return {
            mediaRequestPublished: {
                content: encodeStatus && encodeStatus.toLowerCase() === 'not applicable' ? [] : this.encodeRequest,
            },
            qmAvailable: {
                submilestones: [
                    {
                        title: _.get(this, "submilestones.qmAvailable.fetchVersion.name"),
                        status: _.get(this, "submilestones.qmAvailable.fetchVersion.value"),
                        content: this.fetchVersion,
                    },
                    {
                        title: _.get(this, "submilestones.qmAvailable.fetchTitle.name"),
                        status: _.get(this, "submilestones.qmAvailable.fetchTitle.value"),
                        content: this.fetchTitle,
                    }
                ],
                content: this.qmAvailable
            },
            mezzFulfilment: {
                submilestones: [
                    {
                        title: _.get(this, "submilestones.mezzFulfilment.avodGating.name"),
                        status: _.get(this, "submilestones.mezzFulfilment.avodGating.value"),
                        content: this.avodGating,
                    },
                    {
                        title: _.get(this, "submilestones.mezzFulfilment.renderMezz.name"),
                        status: _.get(this, "submilestones.mezzFulfilment.renderMezz.value"),
                        content: this.renderMezz,
                    },
                    {
                        title: _.get(this, "submilestones.mezzFulfilment.captionConvert.name"),
                        status: _.get(this, "submilestones.mezzFulfilment.captionConvert.value"),
                        content: this.captionConvert,
                    },
                    {
                        title: _.get(this, "submilestones.mezzFulfilment.transferMedia.name"),
                        status: _.get(this, "submilestones.mezzFulfilment.transferMedia.value"),
                        content: this.transferMedia,
                    },
                    {
                        title: _.get(this, "submilestones.mezzFulfilment.transferMeta.name"),
                        status: _.get(this, "submilestones.mezzFulfilment.transferMeta.value"),
                        content: this.transferMeta,
                    },
                ]
            },
            cam: {
                content: this.cam,
                options: {
                    showMoreInfo: {
                        eventType: ["complete"],
                    },
                }
            },
            derivativeCreation: {
                content: this.derivativeCreation,
            },
            manualQC: {
                content: this.manualQC,
            },
            encodeFulfilment: {
                content: this.encodeFulfilment,
            },
        }
    }
}

export default MappingForAssetModalDetailView;
