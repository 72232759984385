import './styles.css';

import React, { useState, useContext } from "react";
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import disco, { neutral, purple, steel } from "./palette.json";
import OpenSans from 'typeface-open-sans';

import PageProvider from "../../services/PageProvider.js";
import {AutoRefreshContext} from "../../components/AutoRefreshContext";

export const TemplateContext = React.createContext(null);

export default function TemplateProvider({ children }) {
    const {autoRefresh} = useContext(AutoRefreshContext);
    const [darkState, setDarkState] = useState(false);
    const paletteType = darkState ? "dark" : "light";
    darkState ? document.getElementsByTagName("body")[0].classList.add("dark-mode") : 
    document.getElementsByTagName("body")[0].classList.remove("dark-mode");
    const theme = createMuiTheme({
      palette: {
        type: paletteType,
        primary: {
          main: darkState ? steel[900] : neutral[100],
          // contrastText: darkState ? neutral[0] : steel[600],
        },
        secondary: {
          main: purple[300],
        },
        action: {
          active: darkState ? neutral[0] : steel[400],
        },
        text: {
          primary: darkState ? neutral[100] : steel[500],
        },
        divider: darkState ? steel[600] : neutral[400],
      },
      typography: {
        "fontFamily": "inherit",
        "fontSize": 12,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500,
        button: {
          textTransform: "initial",
        },
      },
      overrides: {
        MuiCssBaseline: {
          '@global': {
            '@font-face': [OpenSans],
          },
        },
        MuiTextField: {
          root: {
            "& label.Mui-focused": {
              color: darkState ? neutral[0] : steel[400],
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: darkState ? neutral[0] : steel[400],
            },
          },
        },
        MuiPaper: {
          root: {
            backgroundColor: darkState ? steel[900] : neutral[0]
          }
        },
        MuiButtonBase: {
          root: {
            "&:focus": {
              outline: "initial"
            }
          }
        },
        MuiInputBase: {
          root: {
            focused: {
              "&$before": {
                color: darkState ? steel[400] : steel[400]
              },
            },
          },
          input: {
            background: darkState ? '#1D272F' : '#FAFAFA',
            border: 'none',
            borderBottom: darkState ? '1px solid #445A6F' : '1px solid #718DA8',
            '&:focus': {
              outline: 'none',
              border: 'none',
              borderBottom: '1px solid #718DA8'
            }
          }
        },
        MuiInput: {
          underline: {
            '&:before': {
              borderBottom: darkState ? steel[600] : steel[400]
            },
            // '&:hover:not(.Mui-disabled):before': {
            //   borderBottom: '1px solid #718DA8'
            // }
          }
        },
        MuiList: {
          padding: {
            paddingTop: 0,
            paddingBottom: 0
          }
        },
        MuiListItem: {
          gutters: {
            paddingLeft: 5
          },
          root: {
            '&.Mui-selected': {
              backgroundColor: darkState ? steel[800] : neutral[100],
              '&:hover': {
                backgroundColor: darkState ? steel[800] : neutral[100]
              }
            }
          }
        },
        MuiPaper: {
          rounded: {
            borderRadius: 0
          }
        },
        MuiButton: {
          root: {
            '&:focus': {
              outline: 'none'
            }
          },
          startIcon: {
            marginLeft: 0,
            marginRight: 3
          },
          contained: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            '&:hover' : {
              backgroundColor: 'transparent',
              boxShadow: 'none'
            },
            '&:active' : {
              boxShadow: 'none'
            }
          },
          outlined: {
            border: '1px solid #718DA8'
          }
        },
        MuiSelect: {
          select: {
            paddingLeft: 4,
            '&.Mui-disabled': {
              color: darkState ? '#595959': '#d9d9d9'
            }
          },
          selectMenu: {
            backgroundColor: darkState ? '#1D272F' : '#FAFAFA',
            color: darkState ? steel[300] : steel[500]
          },
          icon: {
            color: darkState ? steel[500] : steel[300]
          }
        },
        MuiTypography: {
          displayBlock: {
            fontSize: 10
          }
        },
        MuiBreadcrumbs: {
          separator: {
            marginLeft: 0,
            marginRight: 0,
            padding: '5px 0 0 2px',
            color: darkState ? steel[300] : steel[600]
          },
          ol: {
            padding: '5px 12px'
          }
        },
        MuiDivider: {
          root: {
            backgroundColor: darkState ? steel[700] : neutral[300]
          },
          flexItem: {
            background: darkState ? steel[800] : neutral[300]
          }
        },
        MuiDrawer: {
          paperAnchorDockedLeft: {
            borderRight: 'none'
          }
        },
        MuiAutocomplete: {
          listbox: {
            padding: 0
          }
        },
        MuiGrid: {
          container: {
            height: 38
          }
        },
        MuiTooltip: {
          tooltip: {
            backgroundColor: darkState ? steel[600]: '#D0D9E2',
            color: darkState ? neutral[0]: steel[600],
            fontSize: 10,
          },
          arrow: {
            color: darkState ? steel[600]: '#D0D9E2',
          },
          popper: {
            willChange: 'auto !important'
          },
          tooltipPlacementLeft: {
            '@media (min-width: 600px)': {
              margin: '0 8px'
            }
          }
        },
        MuiContainer: {
          root: {
            height: '100%'
          }
        },
        MuiToolbar: {
          regular: {
            '@media (min-width: 600px)': {
              minHeight: 48
            }
          }
        },
        MuiSnackbar: {
          anchorOriginBottomLeft: {
            '@media (min-width: 600px)': {
              bottom: 0,
              left: 0,
              minWidth: '100%'
            },
            bottom: 0
          }
        },
        MuiSnackbarContent: {
          root: {
            backgroundColor: darkState ? 'rgba(0,0,0,.8)' : 'rgba(0,0,0,.8)',
            fontSize: 14,
            fontWeight: 400,
            borderRadius: 0,
            color: neutral[0],
            transition: 'none !important',
            '@media (min-width: 600px)': {
              minWidth: '100%'
            }
          }
        },
        MuiTableCell: {
          root: {
            borderBottom: darkState ? '1px solid #30404F' : '1px solid #EAEAEA',
            verticalAlign: 'baseline'
          },
          sizeSmall: {
            padding: '10px 0 10px 5px'
          }
        },
      MuiTab: {
        root: {
          '&$selected': {
            backgroundColor: darkState ? steel[900] : neutral[0],
          }
        }
      },
      MuiSwitch: {
        thumb: {
          width: 15,
          height: 15,
          marginTop: 1,
          color: darkState ? neutral[100] : '#9580FF'
        },
        track: {
          backgroundColor: darkState ? steel[600] : (autoRefresh ? "#EAEAEA" : ""),
          height: 19,
          borderRadius: 10,
          width: '95%',
          opacity: 'unset'
        },
        root: {
          padding: 9
        }
      }
      },
      props: {
        MuiCheckbox: {
          disableRipple: true,
          color: "primary"
        },
        MuiButton: {
          disableRipple: true,
          fontSize: 'small',
          variant: "contained"
        },
        MuiIcon: {
          fontSize: 'small'
        }
      },
      background: darkState ? steel[700] : neutral[300],
      drawerWidth: 100,
      disco
    });

    return (
        <TemplateContext.Provider value={{darkState, setDarkState}}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </TemplateContext.Provider>
    );
}