import React from "react";
import moment from "moment-timezone/builds/moment-timezone-with-data";
import _ from "lodash";
import {formatDateForView} from '../utils/date';
import { toProperCase } from './string-utils';
import  './string-extensions';
import CustomTooltip from '../components/organisms/CustomTooltip';
import { getFiltersMap } from './filter-utils';
import {getComponents} from './data-map/default';

const MAXSTATUSLENGTH = 35;
const ESPColumnsExpand = [
  "mediarequestpublished",
  "qmavailable",
  "mezzfulfilment",
  "endpointfulfillment",
  "receipt",
  "upload",
];

const AssetColumnsExpand = [
  "assetrowdetails"
]

export function formatDateToMMDDYYYY(date) {
  if (!moment(date).isValid()) {
    return null;
  }
  return moment.tz(date, "America/New_York").format("MM/DD/YYYY hh:mm:ss A");
}

export function preventRowExpand(e, column, columnIndex, row, rowIndex, view, setSubmilestonePosition = _.noop) {
  let columnsToExpand = view == 'asset' ? AssetColumnsExpand : ESPColumnsExpand;
  return (!columnsToExpand.includes(e.target.getAttribute("data"))) ? e.stopPropagation() : setSubmilestonePosition(e.target);
}

export function addEllipsis(cellContent, extraClass, tooltipContent) {
  cellContent = cellContent ? cellContent : "NA";
  tooltipContent = tooltipContent || cellContent;
  extraClass = extraClass != undefined ? extraClass : "";
  return (
    <div
      onMouseOver={(event) => toolTipPosition(event)}
      class={`hasTooltip ${extraClass}`}
    >
      <div class="addEllipsis">{cellContent}</div>
      <div>
        <span class="msHover">{tooltipContent}</span>
      </div>
    </div>
  );
}
export function formatBrand(brandValue) {
  if (brandValue === "NA") return;
  let brand = [];
  for (let i = 0; i < brandValue.length; i++) {
    brand[i] = brandValue[i].replace("urn:warnermedia:brand:", "");
    brand[i] = brand[i]
      .toLowerCase()
      .split("-")
      .map((data) => data.charAt(0).toUpperCase() + data.substring(1))
      .join(" ");
  }
  return brand.join(",");
}
export function formatCId(idvalue) {
  let result = "";

  if (!idvalue || idvalue === "NA") return result;

  idvalue = Array.isArray(idvalue) ? idvalue : [idvalue];
  idvalue.forEach((val) => {
    const { name, value} = val;

    result = result + `<strong>${name}</strong> - ${value}\r\n`;
  });

  return result;
}

export function milestoneClass(maskedStatus) {
  let milestoneClass = "";

  switch (maskedStatus) {
    case "incomplete":
      milestoneClass = "status-dot-yellow";
      break;
    case "error":
      milestoneClass = "status-dot-red";
      break;
    case "complete":
    case "backfill":
    case "accepted":
      milestoneClass = "status-dot-green";
      break;
    case "not_started":
      milestoneClass = "status-dot-empty";
      break;
    case "inprogress":
      milestoneClass = "status-refresh";
      break;
    default:
      milestoneClass = "status-dot-empty";
      break;
  }
  return milestoneClass;
}

export function getMileStoneStatusClass(status, mapForVideo) {
    let maskedStatus = status ? getMaskedStatus(status.toLowerCase(), mapForVideo) : "";
    let milestoneClass = '';

    switch(maskedStatus) {
        case 'incomplete':
            milestoneClass = 'status-dot-yellow';
            break;
        case 'error':
            milestoneClass = 'status-dot-red';
            break;
        case 'complete':
        case 'backfill':
            milestoneClass = 'status-dot-green';
            break;
        case 'not_applicable':
        case 'cancelled':
            milestoneClass = 'status-dot-gray';
            break;
        case 'not_started':
            milestoneClass = 'status-dot-empty';
            break;
        case 'inprogress':
            milestoneClass = 'status-refresh';
            break;
        default:
            milestoneClass = 'status-dot-empty';
            break;
    }
    return milestoneClass;
}

export function toolTipPosition(e) {
  let thisCurrentTarget = e.currentTarget;
  let thisTarget = e.target;
  let tooltipWrap = document.querySelector(".hasTooltip:hover span");
  let milestoneCol = thisCurrentTarget.classList.contains("milestone");
  let hoverCheck = thisCurrentTarget.classList.contains("Series")
    ? thisCurrentTarget.children[0].innerText === "NA"
    : true;
  if (
    thisTarget.offsetWidth >= thisTarget.scrollWidth &&
    !milestoneCol &&
    hoverCheck
  ) {
    tooltipWrap.setAttribute("style", "opacity: 0");
  } else {
    let leftadjustment = 0;
    let linkProps = thisCurrentTarget.getBoundingClientRect();
    let tooltipProps = tooltipWrap.getBoundingClientRect();
    let padding = 10;
    let textCellTopAdjustment = 0;
    if (milestoneCol || thisCurrentTarget.classList.contains("series")) {
      leftadjustment = linkProps.width / 2 - tooltipProps.width / 2;
    }
    let topPos =
      linkProps.top - (tooltipProps.height + padding + textCellTopAdjustment);
    let leftPos = thisCurrentTarget.classList.contains("firstCol")
      ? linkProps.left
      : linkProps.left + leftadjustment;
    if (
      tooltipWrap.getAttribute("class") &&
      tooltipWrap.getAttribute("class").toLocaleLowerCase() != "submshover"
    ) {
      tooltipWrap.setAttribute("style", `top:${topPos}px;left:${leftPos}px;`);
    } else {
      tooltipWrap.setAttribute(
        "style",
        "top:" +
          (topPos + 44) +
          "px;" +
          "left:" +
          (leftPos - padding - 3 - tooltipWrap.offsetWidth / 2) +
          "px;"
      );
    }
  }
}
export function columnMenuPosition(e){
  let thisCurrentTarget = e.currentTarget.firstChild;
  let props = thisCurrentTarget.getBoundingClientRect();
  let customizeColumn = document.querySelector(".customizeColumn");
  customizeColumn.setAttribute("style", `top:${props.top+17}px;left:${props.left}px;display:block`);

}
export function addInfoIcon(column, txt, page) {
    let headerAlignClass = page=="ESP"?"header-align" : "";
    let isAcqLastCol = ["MAM Proxy Creation"].includes(column.text) ? "header-align" : "";
    return (
        <span className={`${headerAlignClass} ${isAcqLastCol}`}>
          <span className={_.get(column, 'headerTextClass', '')}>{column.text} </span>
          <CustomTooltip text={txt} info={true} />
        </span>
    );
}

export function infoIcon(){
  return (
    <svg width="10px" height="10px" viewBox="0 0 16 16" class="bi bi-info-circle headerInfo" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
      <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z"></path>
      <circle cx="8" cy="4.5" r="1"></circle>
    </svg>
  )
}

export function addDetailViewIcon() {
  return (
    <span>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="16" height="16" rx="4" fill="#445A6F" />
        <path d="M11.5 3.5H4.5C3.94772 3.5 3.5 3.94772 3.5 4.5V11.5C3.5 12.0523 3.94772 12.5 4.5 12.5H11.5C12.0523 12.5 12.5 12.0523 12.5 11.5V4.5C12.5 3.94772 12.0523 3.5 11.5 3.5Z" stroke="#D0D9E2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M3.5 6.5H12.5" stroke="#D0D9E2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6.5 12.5V6.5" stroke="#D0D9E2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </span>
    );
}
export function addCustomizeColumnIcon() {
  return (
    <span>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="16" height="16" rx="4" fill="#445A6F" />
        <path d="M8 3.5V12.5M8 3.5H11.5C11.7652 3.5 12.0196 3.60536 12.2071 3.79289C12.3946 3.98043 12.5 4.23478 12.5 4.5V11.5C12.5 11.7652 12.3946 12.0196 12.2071 12.2071C12.0196 12.3946 11.7652 12.5 11.5 12.5H8V3.5ZM8 3.5H4.5C4.23478 3.5 3.98043 3.60536 3.79289 3.79289C3.60536 3.98043 3.5 4.23478 3.5 4.5V11.5C3.5 11.7652 3.60536 12.0196 3.79289 12.2071C3.98043 12.3946 4.23478 12.5 4.5 12.5H8V3.5Z" stroke="#D0D9E2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>    </span>
  );
}
export function addCustomizeColumnCloseIcon(closeMenu,closeBtn,closeFill) {
  return (
    <span>
      <svg onClick={closeMenu} className={closeBtn} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="8" fill="#1D272F" className={closeFill} />
        <path d="M10.4001 5.59985L5.6001 10.3999" stroke="#57748E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M5.6001 5.59985L10.4001 10.3999" stroke="#57748E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>   </span>
  );
}



export function addCustomizeColumnIconAvod(status, header, view = "esp"){
  const adStatus = getFiltersMap(view).Map.adStatus;
  const value = adStatus && adStatus.filters && Array.isArray(adStatus.filters) && adStatus.filters.find(filter => filter.value.toLowerCase() === status);

  let color = '#445A6F'; // default color for status: Master Approved & Duration Approved
  let textColor = '#FFFFFF';
  if(status === 'ads_updated') {
    color = '#8CB844'; // Green A
    textColor = '#FFFFFF';
  } else if (status === 'quick_turn') {
    color = '#B91A2D'; // Red A
    textColor = '#FFFFFF';
  } else if (status === 'awaiting_ads' || status === 'yet to be fulfilled' || status === 'previously_fulfilled') {
    color = '#FFDC62'; // Yellow A
    textColor = '#7F7F7F';
  } else if (status === 'ads_required') {
    color = '#F37E8C'; // Pink A
    textColor = '#1B2329';
  } else if (status && status === 'na' || status === 'ads_not_required') {
    return '';
  }
  let avodIcon = <span>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect class={header && 'avod-icon'} width="16" height="16" rx="4" fill={color} />
        <path d="M9.81641 12L9.29883 10.3008H6.69629L6.17871 12H4.54785L7.06738 4.83203H8.91797L11.4473 12H9.81641ZM8.9375 9.03125C8.45898 7.49154 8.1888 6.62077 8.12695 6.41895C8.06836 6.21712 8.02604 6.05762 8 5.94043C7.89258 6.3571 7.58496 7.38737 7.07715 9.03125H8.9375Z" fill={textColor} class={header && 'avod-text'}/>
      </svg>
  </span>
  return status ? (!value ? "" : <CustomTooltip tooltipContent={value && value.name} cellContent={avodIcon} showTooltip />) : avodIcon;
}

export const isMilestoneSearchActiveIcon = (column, isMilestoneSeleted, page) => {
  const tableCol = column.text;
  const color = isMilestoneSeleted.includes(tableCol) ? '#1A94FF' : '#718DA8';
  const milestoneClass = page == 'Acquire' ? '' : 'milestone-search';
  return (
    <div class={`${milestoneClass}`}>
      <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.5 9L11 0H0L5.5 9Z" fill={color} fill-opacity="0.77"/>
      </svg>
    </div>
  )
}

let EspDashboardData = {
  "_id":"RADS690115_preview_PROM321770",
  "dsco":{
     "sourceSystem":"esp",
     "eventName":"OfferingDelivered",
     "version":1,
     "sourceLabel":"event-consumer",
     "readAt":1597140839072,
     "isValid":true,
     "mediaRequest_exist":true,
     "encodingkey":"d62201bf-18a8-4493-8fd7-788f3c321770:OTT",
     "qckey":"d62201bf-18a8-4493-8fd7-788f3c321770:OTT",
     "camkey":"PROM321770:OTT",
     "camErrorKey":"PROM321770",
     "eventids":[
        "159714084209355dd3fef"
     ]
  },
  "isDeleted":false,
  "milestones":{
     "preProcessing":{
        "name":"preProcessing",
        "updatedDate":"2020-08-11T10:14:02.093Z",
        "createdDate":"2020-08-11T10:14:02.093Z",
        "statusDetails":[

        ],
        "status":"Incomplete",
        "submilestone":{
           "contentAvailability":{
              "updatedDate":"2020-08-11T10:14:02.093Z",
              "createdDate":"2020-08-11T10:14:02.093Z",
              "status":"InComplete",
              "statusDetails":[

              ]
           },
           "offeringReceived":{
              "updatedDate":"2020-08-11T10:14:02.093Z",
              "createdDate":"2020-08-11T10:14:02.093Z",
              "status":"Complete"
           },
           "contentSubscription":{
              "updatedDate":"2020-04-12T15:41:28.874-04:00",
              "createdDate":"2020-08-11T10:14:02.093Z",
              "status":"Complete"
           }
        }
     },
     "encoding":{
        "name":"encoding",
        "updatedDate":"2020-08-11T10:14:02.093Z",
        "createdDate":"2020-08-11T10:14:02.093Z",
        "statusDetails":[

        ],
        "messageType":"",
        "status":"not-started"
     },
     "qc":{
        "name":"qc",
        "updatedDate":"2020-08-11T10:14:02.093Z",
        "createdDate":"2020-08-11T10:14:02.093Z",
        "statusDetails":[

        ],
        "messageType":"",
        "status":"not-started"
     },
     "deliveryFullfillment":{
        "name":"deliveryFullfillment",
        "updatedDate":"2020-08-11T10:14:02.093Z",
        "createdDate":"2020-08-11T10:14:02.093Z",
        "statusDetails":[

        ],
        "status":"Incomplete"
     },
     "delivery":{
        "name":"delivery",
        "updatedDate":"2020-08-11T10:14:02.093Z",
        "createdDate":"2020-08-11T10:14:02.093Z",
        "statusDetails":[
           [
              "Tile 2840x1646 error delivery to s3",
              "Tile 3840x1200 incomplete"
           ],
           [
              "PRO17 error delivery to s3",
              "PRO11 is incomplete"
           ],
           [

           ]
        ],
        "status":"incomplete",
        "submilestone":{
           "images":{
              "createdDate":"2020-08-11T10:14:02.093Z",
              "status":"error",
              "statusDetails":[
                 "Tile 2840x1646 error delivery to s3",
                 "Tile 3840x1200 incomplete"
              ],
              "updatedDate":"2020-05-12T15:41:28.874-04:00"
           },
           "video":{
              "createdDate":"2020-08-11T10:14:02.093Z",
              "status":"error",
              "statusDetails":[
                 "PRO17 error delivery to s3",
                 "PRO11 is incomplete"
              ],
              "updatedDate":"2020-01-26T04:00:00.000Z"
           },
           "meta":{
              "createdDate":"2020-08-11T10:14:02.093Z",
              "status":"complete",
              "statusDetails":[

              ],
              "updatedDate":"2020-05-12T15:41:28.874-04:00"
           }
        }
     }
  },
  "offering":{
     "espId":"urn:esp:turner:programoffering:00006d90dfeb75d8eac8467cd1b0302b",
     "scheduleId":"RADS690115",
     "type":"EPISODE_PREVIEW",
     "scheduledAsset":{
        "id":"10829",
        "type":"edit",
        "namespace":"turner"
     },
     "channel":null,
     "startDateS":"2020-08-03T06:00:00.000Z",
     "startDate":1596434400000,
     "endDateS":"2021-05-01T03:59:59.000Z",
     "endDate":1619841599000,
     "categories":[
        "Top Navigation/Movies",
        "Movies"
     ],
     "program":null,
     "relatedOfferings":{
        "series":{
           "id":"urn:woodmark:nls:seriesoffering:E584D0020B2F4CE3B0C48A3B0C42018F"
        },
        "season":{
           "id":"urn:woodmark:nls:seasonoffering:4562645E06FA4D34881828C6F36CEFF3"
        },
        "extras":[
           {
              "id":"urn:woodmark:nls:offering:2342645E06FA4D34881828C6F36CEEER"
           },
           {
              "id":"urn:woodmark:nls:offering:FFF2645E06FA4D34881828C6F36CETTT"
           }
        ],
        "parent":{
           "id":"urn:woodmark:nls:episodeoffering:4562645E06FA4D34881828C6F36CEFF3"
        }
     }
  },
  "platform":"OTT",
  "program":{
     "id":"PROM321770",
     "type":"”program_version”",
     "namespace":"hbous",
     "titles":[
        {
           "language":"en-US",
           "short":"Preview  - RADS690115",
           "full":"DC Super Playhouse"
        }
     ],
     "brands":[
        "urn:warnermedia:brand:dc-comics"
     ]
  }
}
let AcquireDashboardData = {
  "_id": "5f2ed7ecdbc82a15698fc864",
  "workflowId": "Testing9512QA-f8261082-099f-4412-b0c0-3aaeb68936",
  "acquireEventIds": [
      "15969054519855ee5868b",
      "159690547352815d85a11",
      "15969055117208734ebdb",
      "15969055288477dc93072",
      "1596905573977e28d73df",
      "15969056056421b9046bc",
      "1596905629002f79f47dc",
      "1596905659960f16754d6",
      "1596905685611df32a2ad",
      "15969058899672356cd91",
      "1596905905716ef523d5d",
      "15969059242479726e802",
      "159690593894161dcb4a2",
      "1596905959075bd11bda7",
      "15969059773441f03e567",
      "15969060021320d6809d7",
      "1596906023355f1fd9c40",
      "159690604171172c08942",
      "1596906064102edf20c99"
  ],
  "cancelledState": "",
  "createdDate": 1596881789000,
  "distributor": "CURIOSITY_STREAM",
  "isCancelled": false,
  "master": {
      "cid": "AND795X",
      "qmMasterStatus": "incomplete",
      "frameRate": "23.976",
      "aspectRatio": "1.78"
  },
  "milestones": {
      "upload": {
          "name": "UPLOAD",
          "ingestType": "MMC",
          "status": "Completed",
          "statusDetails": [

          ],
          "mmcUri": "s3://wm-msc-ingest-curiositystream-prod-ue1/CURIOSITYSTREAM1855/TestingQA_episode_curiosityStream_manifest.xml",
          "updatedDate": "2020-08-08T10:21:51.000Z",
          "createdDate": "2020-08-08T10:16:29.000Z"
      },
      "receipt": {
          "name": "RECEIPT",
          "status": "Completed",
          "statusDetails": [],
          "updatedDate": "2020-08-08T10:28:09.000Z",
          "createdDate": "2020-08-08T10:22:08.000Z",
          "subMilestone": {
              "autoQc": {
                  "status": "Completed",
                  "statusDetails": [],
                  "updatedDate": "2020-08-08T10:28:09.000Z",
                  "createdDate": "2020-08-08T10:24:19.000Z"
              },
              "normalization": {
                  "status": "Completed",
                  "statusDetails": [

                  ],
                  "updatedDate": "2020-08-08T10:23:48.000Z",
                  "createdDate": "2020-08-08T10:22:53.000Z"
              }
          }
      },
      "manualQc": {
          "name": "ManualQC",
          "status": "Completed",
          "statusDetails": ["Notes added by QC tech"],
          "updatedDate": "2020-08-08T10:28:58.000Z",
          "createdDate": "2020-08-08T10:28:25.000Z",
          "result": "PASS"
      },
      "qmCreation": {
          "name": "QMCreation",
          "status": "Completed",
          "statusDetails": [

          ],
          "updatedDate": "2020-08-08T10:30:01.000Z",
          "createdDate": "2020-08-08T10:29:18.000Z"
      },
      "proxyCreation": {
          "name": "ProxyCreation",
          "status": "Completed",
          "statusDetails": [

          ],
          "updatedDate": "2020-08-08T10:31:03.000Z",
          "createdDate": "2020-08-08T10:30:23.000Z"
      }
  },
  "networkName": "HBO MAX",
  "packageFiles": [
      "s3://turner-csc-wip-distribution-masters-prod-us-ue1/Incoming_VU_Assets/Warner_Media_OTT_2072270_IT_CHAPTER_TWO_16x9_E2369506_FEATURE_5_1_2_0_LTRT_ENGLISH_SPANISH_LATIN_9768513-5efb375146e0fb000816456b.ssc",
      "s3://turner-csc-wip-distribution-masters-prod-us-ue1/Assets/2072270/Warner_Media_OTT_2072270_IT_CHAPTER_TWO_16x9_E2369506_FEATURE_5_1_2_0_LTRT_ENGLISH_SPANISH_LATIN_9768513_cc0-5efb375146e0fb000816456b.scc"
  ],
  "qcAssignee": {
      "qcType": "",
      "assignee": {
          "username": "",
          "displayName": "",
          "email": ""
      },
      "notes": ""
  },
  "title": {
      "id": "72962812",
      "externalId": "EP650222",
      "externalIdType": "Tribune",
      "name": "Origins",
      "type": "Episode",
      "language": "ENGLISH",
      "releaseYear": 2018,
      "seriesName": "UATTesting-2d9e140f-d5f6-43a9-838c-d8a610aae2be",
      "seasonNumber": "1",
      "episodeNumber": "1"
  }
};

var status = ["complete", "incomplete", "error"];
function randomString(length) {
  var chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var result = "";
  for (var i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

function randomTime(length) {
  var chars = "0123456789";
  var result = "";
  for (var i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}
function randomTime1(length) {
  var chars = "012345";
  var result = "";
  for (var i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}
export function generateESPData(numOfSampleData) {
  var sampleDataArray = [];
  var preProSub = ["contentAvailability", "offeringReceived", "contentSubscription"];
  var type = ["SERIES", "EPISODE", "PROMOEXTRA", "EPISODE_PREVIEW", "FEATURE"];
  var deliverySub = ["images", "video", "meta"];
  for (var i = 0; i < numOfSampleData; i++) {
      EspDashboardData._id = "PMRS4B" + randomTime(7) + "_" + type[Math.floor(Math.random() * type.length)] + "_" + randomTime(7);
      EspDashboardData.offering.startDate = parseInt(("1590" + randomTime(9) + ".0"));
      EspDashboardData.offering.endDate = parseInt((new Date().getTime() / 1000000000)) + randomTime(8);
     // EspDashboardData.preview.titles[0].short = randomString(17);
      EspDashboardData.milestones.preProcessing.status = status[Math.floor(Math.random() * status.length)];
      EspDashboardData.milestones.preProcessing.updatedDate = "2020-06-25T0" + randomTime1(1) + ":" + randomTime1(2) + ":" + randomTime1(2) + ".000Z"
      EspDashboardData.milestones.delivery.status = status[Math.floor(Math.random() * status.length)];
      EspDashboardData.milestones.preProcessing.submilestone.contentAvailability.status = status[Math.floor(Math.random() * status.length)];
      EspDashboardData.milestones.preProcessing.submilestone.offeringReceived.status = status[Math.floor(Math.random() * status.length)];
      EspDashboardData.milestones.preProcessing.submilestone.contentSubscription.status = status[Math.floor(Math.random() * status.length)];
      EspDashboardData.offering.scheduleId = "SERS" + randomTime(3) + "DTC-PMRS4D" + randomTime(6);
      EspDashboardData.offering.type = type[Math.floor(Math.random() * type.length)];
      for (var a = 0; a < 3; a++)
          EspDashboardData.milestones.preProcessing.statusDetails[a] = "Desc Preproc " + randomTime(1);
      for (var b = 0; b < 2; b++) {
          if(EspDashboardData.milestones.preProcessing.submilestone[preProSub[b]].statusDetails)
          for (var c = 0; c < 3; c++)
              EspDashboardData.milestones.preProcessing.submilestone[preProSub[b]].statusDetails[c] = "Description " + randomTime(1);
      }
      for (var c = 0; c < 3; c++)
          EspDashboardData.milestones.delivery.statusDetails[c] = "Desc Preproc " + randomTime(1);
      for (var d = 0; d < 2; d++) {
          for (var e = 0; e < 3; e++)
              EspDashboardData.milestones.delivery.submilestone[deliverySub[d]].statusDetails[e] = "Desc " + randomTime(1);
      }
      sampleDataArray.push(JSON.parse(JSON.stringify(EspDashboardData)));
  }
  return sampleDataArray;

}
export function generateAcquireData(numOfSampleData) {
  var sampleDataArray = [];
  for (var i = 0; i < numOfSampleData; i++) {
      AcquireDashboardData.title.seriesName = randomString(7) + "-" + randomString(6) + "-" + randomString(5) + "-" + randomString(4) + "-" + randomString(4);
      AcquireDashboardData.title.id = randomTime1(8);
      AcquireDashboardData.createdDate = parseInt(("1590" + randomTime(9) + ".0"));
      AcquireDashboardData.milestones.upload.status = status[Math.floor(Math.random() * status.length)];
      AcquireDashboardData.milestones.receipt.status = status[Math.floor(Math.random() * status.length)];
      AcquireDashboardData.milestones.manualQc.status = status[Math.floor(Math.random() * status.length)];
      AcquireDashboardData.milestones.qmCreation.status = status[Math.floor(Math.random() * status.length)];
      AcquireDashboardData.milestones.proxyCreation.status = status[Math.floor(Math.random() * status.length)];
      AcquireDashboardData.milestones.receipt.subMilestone.autoQc.status = status[Math.floor(Math.random() * status.length)];
      AcquireDashboardData.milestones.receipt.subMilestone.autoQc.updatedDate = "2020-06-25T0" + randomTime1(1) + ":" + randomTime1(2) + ":" + randomTime1(2) + ".000Z";
      for (var a = 0; a < 3; a++)
      AcquireDashboardData.milestones.receipt.subMilestone.autoQc.statusDetails[a] = "Desc AutoQc " + randomTime(1);
      AcquireDashboardData.milestones.receipt.subMilestone.normalization.status = status[Math.floor(Math.random() * status.length)];
      AcquireDashboardData.milestones.receipt.subMilestone.normalization.updatedDate = "2020-06-25T0" + randomTime1(1) + ":" + randomTime1(2) + ":" + randomTime1(2) + ".000Z";

      for (var b = 0; b < 3; b++)
          AcquireDashboardData.milestones.receipt.subMilestone.normalization.statusDetails[b] = "Desc AutoQc " + randomTime(1);
      sampleDataArray.push(JSON.parse(JSON.stringify(AcquireDashboardData)));
  }


  return sampleDataArray;
}

function getRevisionDetails(component = {}, name) {
  let revisionDetails = [];
    _.forIn(component, (value, key) => {
      let rev;
      if(name === 'contentAvailabilityMessage')
        rev = _.get(value, 'attr.rev') || _.get(value, '[0].attr.rev');
      else
        rev = _.get(value, 'revision');

      if(rev >= 0)
        revisionDetails.push(toProperCase(key) + ": " + rev);
    });
  return revisionDetails;
}

function getTooltip(row, name) {
  name = name === 'encodingmezzreceived' ? 'contentAvailabilityMessage' : 'deliveryFullfillment';
  let status = _.get(row, `milestones.${name}.status`, '');
  let date = _.get(row, `milestones.${name}.updatedDate`, '');
  let description = _.get(row, `milestones.${name}.statusDetails`, '');
  let commonText = customToolTip(status, date, description, true);

  let revisionTitle = "Rev: ";
  let revision = '';
  revision = getRevisionDetails(getComponents(row, name), name);
  revision = (revision || []).join(' - ');

  return revision ? commonText + revisionTitle + revision : commonText;
}

/**
 * This function will enhance the tool with the component workflow by adding
 * playlist and sideCars irrespetive of the milestones stages and truncate the
 * source string after limit MAX_COMPONENT_CHAR_SIZE and display in multiline string
 * @param {*} row
 * @param {*} name
 * @returns
 */

 function getComponentToolTip(row, name) {
  const MAX_COMPONENT_CHAR_SIZE = 250;
  let toolTipText = customToolTip(
    _.get(row, `milestones.${name}.status`, ""),
    _.get(row, `milestones.${name}.updatedDate`, ""),
    _.get(row, `milestones.${name}.statusDetails`, ""),
    true
  );
  let playlist = _.join(_.filter(_.get(row, 'offering.subscriptionFullfillment.playlist', "")), ';');
  let sideCars = _.join(_.filter(_.get(row, 'offering.subscriptionFullfillment.sideCars', "")), ';');
  let components = playlist + ";" + sideCars;

  // checking for characters other than ';'
  if (components.length > 1) {
    const linesCnt = Math.round(components.length / MAXSTATUSLENGTH);

    if (isFinite(linesCnt)) {
      components = components.multiline(linesCnt);
    }
    toolTipText =
      toolTipText +
      "<br/> Component(s): <br/>" +
      components.truncate(MAX_COMPONENT_CHAR_SIZE) +
      "<br/>";
  }

  return toolTipText;
}

function customToolTip(status, date, description, mapForVideo) {
  date = formatDateForView(date);
  let toolTip = { errorDescription: "" };
  let deliveryStatus =
    status && status[0].toUpperCase() + "" + status.substring(1).toLowerCase();
  if (status && description && Array.isArray(description) && description.length > 0) {
    description = description.join();
    let statusDetails = "" + description || "";
    statusDetails =
      statusDetails.length > MAXSTATUSLENGTH
        ? statusDetails.substr(0, MAXSTATUSLENGTH) + " ..."
        : statusDetails;
    toolTip.errorDescription = statusDetails
      ? "Description: " + statusDetails + "<br/>"
      : "";
  }
  toolTip.deliveryStatus = "Status: " + deliveryStatus + "<br/>";
  let combinedDeliveryStatus =
    "Status: " + deliveryStatus + " [" + date + "]" + "<br/>";
  toolTip.duration = "";
  if (date && date.toLowerCase() !== "na") {
    let updatedDateMilliSec = new Date(date).getTime();
    let timeInMilliSec = new Date().getTime();
    toolTip.duration =
      "Milestone Duration: " +
      "<br/>" +
      millisecToReadable(timeInMilliSec - updatedDateMilliSec) +
      "<br/>";
  }

  let maskedStatus = status ?  getMaskedStatus(status.toLowerCase(), mapForVideo) : "";

  maskedStatus.toLowerCase();

  switch (maskedStatus) {
    case "complete":
      return combinedDeliveryStatus + toolTip.errorDescription;
    case "not-started":
    case "not_applicable":
      return toolTip.deliveryStatus + toolTip.errorDescription;
    case "incomplete":
      return (
        combinedDeliveryStatus + toolTip.errorDescription + toolTip.duration
      );
    case "error":
    case "failure":
      return (
        combinedDeliveryStatus + toolTip.errorDescription + toolTip.duration
      );
    case "backfill":
      return combinedDeliveryStatus;
    default:
      return (
        combinedDeliveryStatus + toolTip.errorDescription + toolTip.duration
      );
  }
}

function millisecToReadable(millisec) {
  let second = Math.abs(millisec) / 1000;
  let levels = [
    [Math.floor(second / 31536000), "Years"],
    [Math.floor((second % 31536000) / 86400), "Days"],
    [Math.floor(((second % 31536000) % 86400) / 3600), "Hrs"],
    [Math.floor((((second % 31536000) % 86400) % 3600) / 60), "Min"],
    [Math.floor((((second % 31536000) % 86400) % 3600) % 60), "Sec"],
  ];
  let returntext = "";

  for (let i = 0, max = levels.length; i < max; i++) {
    if (levels[i][0] === 0) continue;
    returntext +=
      " " +
      levels[i][0] +
      " " +
      (levels[i][0] === 1
        ? levels[i][1].substr(0, levels[i][1].length - 1)
        : levels[i][1]);
  }
  return returntext.trim();
}
export function getMaskedStatus(status, mapForVideo) {
  let maskedStatus = "";
  if (status === "fulfilled" && mapForVideo) return "incomplete";
  switch (status) {
    case "not_ready":
    case "warning":
    case "incomplete":
    case "fulfilled":
    case "hold":
    case "onhold":
      maskedStatus = "incomplete";
      break;
    case "failure":
    case "error":
      maskedStatus = "error";
      break;
    case "skipped":
    case "complete":
    case "completed":
    case "received":
    case "accepted":
      maskedStatus = "complete";
      break;
    case "not-started":
    case "not started":
      maskedStatus = "not_started";
      break;
    case "in-progress":
    case "started":
      maskedStatus = "inprogress";
      break;
    case "not applicable":
      maskedStatus = "not_applicable";
      break;
    case "backfill":
      maskedStatus = "backfill"
      break;
    case 'cancelled':
      maskedStatus = 'cancelled';
      break;
    default:
      maskedStatus = "not_started";
      break;
  }
  return maskedStatus;
}

export function tooltipForAcquire(content, key) {
    if(!content)return;
    let ToolTip = "";
    let details =  content.details ;
    let status =  content.status ? (content.status[0].toUpperCase() + '' + content.status.substring(1).toLowerCase()) : "NA";
    let date  =  content.lastUpdatedDate ? content.lastUpdatedDate : "NA";
    let time  =  content.lastUpdatedTime ? content.lastUpdatedTime : "NA";

    let combinedStatus = "Status: " + status + '<br />' ;
    let duration  = "";
    if(date && time && date.toLowerCase() != "na" && time.toLowerCase() != "na")
    {
        combinedStatus = combinedStatus.replace('<br />', '') + " ["+date+" "+time+"]"  + '<br />';
        let updatedDateMilliSec = new Date(date +" "+ time).getTime();
        let timeInMilliSec = new Date().getTime() ;
        duration = "Milestone Duration: "+ '<br />' + millisecToReadable(timeInMilliSec - updatedDateMilliSec)+ '<br />';
    }
    details = details && details.length > 0 ? (details.length > MAXSTATUSLENGTH ? details.substr(0, MAXSTATUSLENGTH) + " ..." : details) : "NA";
    ToolTip = combinedStatus + duration;

    switch (key ? key.toLowerCase() : '') {
        case 'manualqc':
            let qcTech =  content.displayName ? content.displayName : "NA"
            let result =  content.notes ? (content.notes.length > MAXSTATUSLENGTH ? content.notes.substr(0, MAXSTATUSLENGTH) + " ..." : content.notes) : "";
            ToolTip = ToolTip +  "QC Tech: " + qcTech;
            ToolTip =  (details && details !== 'NA' ) ? ToolTip +  "<br />Details: " + details : ToolTip;
            ToolTip = (result && result !== 'NA') ? (ToolTip + "<br />Results: " + result) : ToolTip;
            return ToolTip;

        case 'qmcreation' :
            let cid =  content.cid ? content.cid : "NA";
            ToolTip = ToolTip  + "CID: " + cid + '<br />';
            ToolTip = (details && details !== 'NA' ) ?  ToolTip +  "Details: " + details : ToolTip;
            return ToolTip;
        case 'receipt' :
            let fileType = "";
            let files =  content.files && content.files.toString().length > 0 ? content.files : "NA";
            if (files != "NA") {
                files.forEach(element => {
                    let file = element.substr(element.indexOf('.'))
                    fileType = fileType + file + " ";

                })
            }

            fileType = fileType.trim().replace(' ', ',');
            ToolTip = fileType.length > 0 ? ToolTip + "File Type: " + fileType + '<br />' : ToolTip ;
            ToolTip = (details && details !== 'NA' ) ? ToolTip  + "Details: " + details : ToolTip
            return ToolTip;
        default:
            ToolTip = (details && details !== 'NA' ) ? ToolTip  + "Details: " + details : ToolTip
            return ToolTip;
    }

}

export function checkKeyInOnject(object, key, value = ''){
  let keyExists, valueExists;
  let obj = _.get(object, key, false);

  keyExists = obj && Object.keys(obj).length > 0 ?  true : false;
  valueExists = (value && keyExists && obj === value) ? true : false

  return (value ? valueExists : keyExists);
}

function highlightSelectedRow(rowIndex) {
    const css = `
      .react-bootstrap-table tr:nth-child(${rowIndex + 1}) {
        background: rgba(0,0,0,.075);
       }
      .dark-mode .react-bootstrap-table tr:nth-child(${rowIndex + 1}) {
        background: #2A3640;
       }`;
    const head = document.head || document.getElementsByTagName('head')[0];
    const style = document.createElement('style');

    head.appendChild(style);

    style.id = 'selectedRow';
    style.type = 'text/css';

    style.appendChild(document.createTextNode(css));
}

function unHighlightSelectedRow() {
    const selectedRow = document.getElementById('selectedRow');

    if(selectedRow) {
        selectedRow.parentNode.removeChild(selectedRow);
    }
}

export {
    getTooltip,
    customToolTip,
    getComponentToolTip,
    randomString,
    randomTime,
    highlightSelectedRow,
    unHighlightSelectedRow,
};
