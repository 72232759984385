import React, { useEffect, useState } from "react";
import staticConfig from "../../config/config";

interface MyContext {
  [name: string]: any;
}

export const ConfigContext = React.createContext<[MyContext, boolean | null]>([
  {},
  null
]);

export const ConfigContextProvider = props => {
  const [config, setConfig] = useState({});
  const [isConfigLoading, setIsConfigLoading] = useState(true);

  useEffect(() => {
    const getConfig = async () => {
      const response = await fetch("/settings.json");
      const vars = await response.json();

      const oidc = { ...vars.oidcCmn, ...staticConfig.oidc };
      const {oidcCmn, ...otherConfig} = vars;
      const  allConfigs = { ...staticConfig, oidc, ...otherConfig };
      // Below logic to handle redirection from warnermedia to warnermediagroup.com for all envs.
      if (window.location.host === (allConfigs && allConfigs.redirect && allConfigs.redirect.oldUri)) {
        window.location.replace(allConfigs.redirect.newUri);
        return;
      }
      setConfig(allConfigs);
      setIsConfigLoading(false);
    };
    getConfig();
  }, [setConfig, setIsConfigLoading]);

  return (
    <ConfigContext.Provider value={[config, isConfigLoading]}>
      {props.children}
    </ConfigContext.Provider>
  );
};
