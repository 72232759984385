import React from "react";
import {AcquireIcon, UserIcon, AssetViewIcon} from "../components/atoms/Icons";
import { NavItemProps } from "../components/atoms/NavItem";

interface NavItems extends NavItemProps {
  isVisible: boolean
}

const navItems: { [name: string]: NavItems } = {
  scheduledOfferingsAdvanced:{
    text: 'Scheduled Offerings Advanced',
    icon: <UserIcon />,
    to: 'scheduledOfferingsAdvanced',
    isVisible: true,
  },
  scheduledOfferingsBasic: {
    text: 'Scheduled Offerings',
    icon: <UserIcon />,
    to: 'scheduledOfferingsBasic',
    isVisible: true,
  },
  acquire: {
    text: 'Acquire Advanced',
    icon: <AcquireIcon />,
    to: 'acquire',
    isVisible: true,
  },
  acquireBasic: {
    text: 'Acquire',
    icon: <AcquireIcon />,
    to: 'acquireBasic',
    isVisible: true,
  },
  assetViewAdvanced: {
    text: 'Component View Advanced',
    icon: <AssetViewIcon />,
    to: 'assetViewAdvanced',
    isVisible: true,
  },
  assetViewBasic: {
    text: 'Asset View',
    icon: <AssetViewIcon />,
    to: 'assetViewBasic',
    isVisible: true,
  },
};

export default navItems;
