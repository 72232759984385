import {
  Button as MuiButton,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  Table,
  TableBody,
  TableHead,
  withStyles,
  makeStyles,
  TableContainer
} from "@material-ui/core";
import React, { useContext } from "react";
import { formatDateToHHMMSS, formatDateToMMDDYYYY } from "../../utils/date";

import { DetailsModalContext } from "../../services/DetailsModalProvider";
import { PageContext } from "../../services/PageProvider";
import PropTypes from "prop-types";
import _ from "lodash";
import { steel, neutral } from "../../components/templates/palette.json";
import Typography from "@material-ui/core/Typography";

const HEADER_MAP = {
  codecType: "Codec Type",
  componentType: "Component Type/Profile Type",
  profileType: "Profile Type",
  cid: "CID",
  date: "Date",
  eventType: "Event Type",
  status: "Status",
  statusDetails: "Details",
  time: "Time",
  additionalTimestamps: "Additional Timestamps",
  type: "Event Type",
  uploadType: "Upload Type",
  // csid:"CS ID",
  fulfillCount:"Fulfill#",
  errorCodes:"Error Codes",
  adMarkerCount: "Ad Marker Count",
  idfEssenceManifestVersion: "IDF Version",
  fulfillmentType : "Fulfillment Type",
  componentInfo: "Component Type & Language",
  camUUID: "CAM_UUID",
  ids: "Evt/Corr ID/CSID"
};
const ORDER = [
  "status",
  "date",
  "time",
  "additionalTimestamps",
  "eventType",
  "type",
  "profileType",
  "componentType",
  "codecType",
  "cid",
  "fulfillCount",
  "fulfillmentType",
  "componentInfo",
  "adMarkerCount",
  "idfEssenceManifestVersion",
  "errorCodes",
  // "csid",
  "camUUID",
  "ids",
  "statusDetails",
];
const HeaderCell = withStyles((theme) => ({
  root: {
    fontWeight: "bold",
    fontFamily: ` "Roboto", "Noto", sans-serif `,
    color: theme.palette.type === 'dark' ? neutral[0] : steel[600],
    background: theme.palette.type === 'dark' ? '#1D272F !important' : neutral[100]
  },
}))(MuiTableCell);
const TableRow = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.type === 'dark' ? steel[800] : neutral[100],
    color: steel[600]
  },
}))(MuiTableRow);
const TableCell = withStyles((theme) => {
  const borderColor =
    theme.palette.type === "dark"
      ? theme.disco.neutral[700]
      : theme.disco.neutral[0];

  return {
    root: {
      padding: '5px 0 0 5px',
      'word-break': 'break-word',
      //borderBottom: `1px solid ${borderColor}`,
      color: theme.palette.type === 'dark' ? steel[100] : steel[600],
      fontSize: "10px",
      fontFamily: `"Roboto", "Noto", sans-serif`,
      verticalAlign: "top"
    },
    moreInfo: {
      padding: "0px",
      margin: "2px",
      display: 'inline-block'
    }
  };
})(MuiTableCell);
const ButtonMoreInfo = withStyles((theme) => {
  return {
    root: {
      padding: "0px",
      margin: "2px",
      backgroundColor: 'gray'
    }
  };
})(MuiButton);

const useStyles = makeStyles(theme => ({
  statusDetailsWrapper: {
    wordBreak: 'break-word',
    margin: 'auto'
  },
  infoButton: {
    background: '#718DA8',
    fontSize: 10,
    padding: '2px 0px 1px 0px',
    bottom: 1,
    color: '#FFFFFF',
    //marginLeft: 5,
    width: 'fit-content',
    height: '100%',
    '&:hover': {
      backgroundColor: '#718DA8',
      background: '#718DA8'
    }
  },
  moreInfoCell: {
    display: 'flex',
    // justifyContent: "space-around"
  },
  moreInfoContainer: {
     height: '12px',
     paddingLeft: '2px'
  },
  tableContainerSub:{
    maxHeight: '70px',
    overflowY: 'auto'
  },
  tableContainer:{
    maxHeight: 'calc(100% - 41px)',
    overflowY: 'overlay'
  },
  derivativeContainer:{
    maxHeight: 'calc(100% - 81px)',
    overflowY: 'overlay'
  },
  detailsCell:{
    padding: '5px 10px 0 5px',
  },
  errorsCell:{
    padding: '5px 10px 0 5px',
  },
  centerAlign: {
    'text-align': 'center'
  },
  qmContainer:{
    maxHeight: 'calc(80% - 81px)',
    overflowY: 'overlay'
  },
  textBold: {
    fontWeight: '900'
  }
}));

export default function DetailsTable(props) {
  const { setMoreInfoState } = useContext(DetailsModalContext);
  const { additionalTimestamps} = useContext(PageContext);
  const columns = setColumnsKeys();
  const classes= useStyles();
  function renderRows() {
    const { content } = props;
    let rows;
   
    if (!Array.isArray(content)) return null;
    rows = content.map((element) => {
      const row = renderRow(element);

      return row ? <TableRow>{row}</TableRow> : null;
    }).filter(e => e);

    return rows;
  }

  function render() {
    if (columns.length <= 0) {
      return <Typography variant="body2" component="p">No data to display for selected milestone</Typography>;
    }

    const rows = renderRows();

    if (rows.length <= 0) {
      return <Typography variant="body2" component="p">No data to display for selected milestone</Typography>;
    }

    return (
        <>
          <Table aria-label={props.title} size="small" >
            <TableHead>
              <TableRow>{renderHeader()}</TableRow>
            </TableHead>
          </Table>

          <TableContainer className={ props.submil ?
              classes.tableContainerSub : (props.title && props.title.includes('Derivative') ? classes.derivativeContainer 
              :(props.title && props.title.includes('QM') ? classes.qmContainer : classes.tableContainer))}>
            <Table>
              <TableBody>{renderRows()}</TableBody>
            </Table>
          </TableContainer>
          <br />
        </>
    );
  }

  function handleMoreInfoClick(eventId, eventType, status) {
    return function () {
      setMoreInfoState({
        eventId,
        show: true,
        status,
        title: `${props.title} / ${eventType} Detailed Information`,
      });
    };
  }

  function renderRow(element) {
    if(_.isEmpty(element.status) || element.status === 'NA') return null;

    return columns.map((key) => {
      const entries = Object.entries(props.options.showMoreInfo);
      const moreInfo = entries.map(([key2, status]) => {
        return key === key2 ? (
          <div className={classes.moreInfoContainer}><MuiButton
            onClick={handleMoreInfoClick(
              element.eventid,
              element.eventType,
              element.status
            )}
            className={classes.infoButton}
          >
            More Info
          </MuiButton></div>
        ) :  null;
      });

      switch (key) {
        case "date":
          return (
            <TableCell>{formatDateToMMDDYYYY(element.timestamp)}</TableCell>
          );
        case "time":
          return <TableCell>{formatDateToHHMMSS(element.timestamp)}</TableCell>;
        case "additionalTimestamps":
          let readAt = element.readAt ? 
          <p><span>{`DISCO consumed timestamp:`}</span> {`${formatDateToHHMMSS(element.readAt)}`} </p> : 
            "";
          let processedAt = element.processedAt ? 
           <p><span>{`DISCO processed timestamp:`}</span> {`${formatDateToHHMMSS(element.processedAt)}`} </p> : "";
          return <TableCell> {readAt} {processedAt} </TableCell>
        case "statusDetails":
          const details = _.get(element, "statusDetails", ["NA"]);
          let content = typeof details === "string" ? details : "NA";
          if(Array.isArray(details) && !_.isEmpty(content)) {
            content = details.map((element,index) => {
              return <p className={classes.statusDetailsWrapper}>{index < details.length -1 ?  `${element};`: `${element}`  }</p>
            });
          }
          return <TableCell className = {classes.detailsCell}>{content}</TableCell>;
          case "errorCodes":
            const errCodes = _.get(element, "errorCodes", ["NA"]);
            let errContent = typeof errCodes === "string" ? errCodes : "NA";

            if(Array.isArray(errCodes) && !_.isEmpty(errContent)) {
              errContent = errCodes.map((element,index) => {
                return <p className={classes.statusDetailsWrapper}>{index < errCodes.length -1 ?  `${element};`: `${element}` }</p>
              });
            }

            return <TableCell className = {classes.errorsCell}>{errContent}</TableCell>;
            case "eventType":
              return (
                <TableCell className={moreInfo[0] ? classes.moreInfoCell : ''}>
                  {element[key]}
                  {moreInfo}
                </TableCell>
          );
          case "ids":
          let correlationId = element.correlationId ? 
            <p><span className={classes.textBold}>{`correlationID:`}</span> {`${element.correlationId}`} </p> : 
            "";
          let eventid = element.eventid ? 
            <p><span className={classes.textBold}>{"eventID:"}</span> {`${element.eventid}`}</p> :
            "";
            let csid = element.csid && element.csid !== 'NA' && element.csid !== " " ? 
            <p><span className={classes.textBold}>{"CSID:"}</span> {`${element.csid}`}</p> :
            " ";
          return <TableCell className = {classes.detailsCell}>{eventid} {correlationId} {csid} </TableCell>
        case "status":
          return <TableCell>{(typeof element[key] === 'string' || element[key] instanceof String) ? element[key].toUpperCase() : element[key]}</TableCell>;
        case "profileType":
          return <TableCell>{element[key] || "NA"}</TableCell>
          // case "csid":
          //   return <TableCell>{element[key] || "NA"}</TableCell>
          case "fulfillCount":
            return <TableCell>{element[key] || "NA"}</TableCell>
          case "adMarkerCount":
          case "idfEssenceManifestVersion":
            return <TableCell className={classes.centerAlign}>{element[key] || "NA"}</TableCell>
          case "fulfillmentType":
            return <TableCell>{element[key] || "NA"}</TableCell>
          case "componentInfo":
            const componentDetails = _.get(element, "componentInfo", ["NA"]);
          let componentContent = typeof componentDetails === "string" ? componentDetails : "NA";

          if(Array.isArray(componentDetails) && !_.isEmpty(componentContent)) {
            componentContent = componentDetails.map((element) => (
                <p className={classes.statusDetailsWrapper}>{element}</p>
            ));
          }

          return <TableCell className = {classes.detailsCell}>{componentContent}</TableCell>;
        default:
          return <TableCell>{element[key]}</TableCell>;
      }
    });
  }

  function renderHeader() {
      return columns.map(key => {
          if (key === 'type' && props.title === 'Upload') {
              return <HeaderCell key={`header-${key}`}>Upload Type</HeaderCell>;
          } else {
              return <HeaderCell key={`header-${key}`}>{HEADER_MAP[key]}</HeaderCell>;
          }
      });
  }

  function setColumnsKeys() {
    if(!Array.isArray(props.content)){
        return [];
    }

    let cols = new Set(["statusDetails"]);
    props.content.forEach((element) => {
      if((element.eventid || element.correlationId || element.csid) && additionalTimestamps){
        cols.add("ids");
      }
      if((element.readAt || element.processedAt) && additionalTimestamps){
        cols.add("additionalTimestamps");
      }
      for (const key in element) {
        if (key === "timestamp") cols.add("date").add("time");
        else cols.add(key);
      }
    });

    return Array.from(cols)
      .filter(function (item) {
        return ORDER.includes(item);
      })
      .sort(function (a, b) {
        return ORDER.indexOf(a) - ORDER.indexOf(b);
      });
  }

  return render();
}

DetailsTable.defaultProps = {
  options: {
    showMoreInfo: {},
  },
};

DetailsTable.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      codecType: PropTypes.string,
      componentType: PropTypes.string,
      profileType: PropTypes.string,
      eventType: PropTypes.string,
      status: PropTypes.string.isRequired,
      statusDetails: PropTypes.arrayOf(PropTypes.string),
      timestamp: PropTypes.string.isRequired,
      additionalTimestamps: PropTypes.string.isRequired,
      type: PropTypes.string,
      ids: PropTypes.string
    })
  ),
  options: PropTypes.shape({
    showMoreInfo: {
      eventType: PropTypes.arrayOf(PropTypes.string),
    },
  }),
  title: PropTypes.string.isRequired,
};
